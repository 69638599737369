import React from "react";
import Colors from "../../../../assets/Colors";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import ButtonComp from "../../../Reusable/ButtonComp";

const RatesCards = ({ title, desc, onClick, buttonText }) => {
  const {
    xlfontsize,
    xsfontsize,
    smFontSize,
    isdesktop,
    lgfontsize,
    mdFontSize,
  } = useDesktopOrLaptop();
  return (
    <div
      style={{
        borderWidth: "1px",
        borderColor: Colors.borderSecondary,
        padding: "12px 18px",
        borderRadius: "10px",
        marginBottom: "20px",
      }}
      className="w-full flex flex-col "
    >
      <div
        style={{ marginBottom: "20px" }}
        className="flex items-center justify-between"
      >
        <div
          style={{
            fontSize: mdFontSize,
            color: Colors.fontDark,
            fontWeight: "500",
          }}
        >
          {title}
        </div>
        <ButtonComp
          title={buttonText}
          extraStyles={{
            color: Colors.bluePrimary,
            fontSize: smFontSize,
            fontWeight: "400"
          }}
          onClick={onClick}
        />
      </div>
      <div style={{ fontSize: smFontSize, color: Colors.fontGreyLight }}>
        {desc}
      </div>
    </div>
  );
};

export default RatesCards;
