import React from "react";
import { Link } from "react-router-dom";
import Colors from "../../assets/Colors";
import SidePanelButton from "../SidePanelButton";
import logo from "../../assets/pngs/dark.png";
import logoMini from "../../assets/pngs/BurqMiniLogo.png";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

function SettingsSidebar({
  setSidepanelactive,
  setIsBurgerMenuVisible,
  sidepanelactive,
  isBurgerMenuVisible,
}) {
  const { isdesktop, lgfontsize } = useDesktopOrLaptop();
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        width: "260px",
        height: "100%",
        borderWidth: "1px",
        zIndex: 10,
        overflow: "auto",
      }}
      className="trans"
    >
      <div
        style={{
          paddingLeft: isdesktop
            ? isBurgerMenuVisible
              ? "22px"
              : "18px"
            : "0px",

          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "55px",
          // borderBottomWidth: "1px",
          // borderColor: Colors.borderPrimary,
        }}
        className="trans"
      >
        <Link
          id="homeId"
          to="/"
          onClick={() => {
            // setSidepanelactive("Home");
          }}
        >
          <div
            className="flex items-center justify-center"
            style={
              isBurgerMenuVisible
                ? {
                    width: isdesktop ? "68px" : "130px",
                  }
                : { width: isdesktop ? "40px" : "130px" }
            }
          >
            <img
              style={{ width: "100%", objectFit: "contain" }}
              src={isBurgerMenuVisible ? logo : logoMini}
              alt=""
            />
          </div>
        </Link>
      </div>
      <div
        style={{
          fontFamily: "Poppins",
          margin: "0px 0px 18px 36px",
          fontSize: lgfontsize,
          fontWeight: "600",
          color: Colors.fontBlack,
        }}
      >
        Settings
      </div>

      <SidePanelButton
        id="BusinessInfo"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Business Info"
      />
      <SidePanelButton
        id="Integrations"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Integrations"
      />
      <SidePanelButton
        id="Delivery configuration"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Delivery configuration"
      />
      {/* <SidePanelButton
        id="Locations"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Locations"
      />
      <SidePanelButton
        id="Admin"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Admin"
      /> */}
      <SidePanelButton
        id="Payment Settings"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Payment Settings"
      />
    </div>
  );
}

export default SettingsSidebar;
