import axios from "axios";
import apiUrls from "../../utils/constants/apiUrls";
import { generateQueryParams } from "./utils";

export const createConnectAccount = (payload) => {
  return axios.post(apiUrls.CREATE_CONNECT_ACCOUNT, payload);
};

export const getConnectedAccountBalance = (params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.CONNECT_ACCOUNT_BALANCE,
    params
  );
  return axios.get(urlWithQueryParams);
};
