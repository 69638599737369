import React, { useState } from "react";
import styles from "./styles.module.css"; // Assume the CSS module file is named TimeInput.module.css
import classNames from "classnames";

import TimeZones from "./TimeZones";

import { FaRegSquare, FaSquareCheck } from "react-icons/fa6";

import OneInputsContainer from "./OneInputsContainer";

const daysArray = {
  monday: { start_time: "", end_time: "", week_day: "monday" },
  tuesday: { start_time: "", end_time: "", week_day: "tuesday" },
  wednesday: { start_time: "", end_time: "", week_day: "wednesday" },
  thursday: { start_time: "", end_time: "", week_day: "thursday" },
  friday: { start_time: "", end_time: "", week_day: "friday" },
  saturday: { start_time: "", end_time: "", week_day: "saturday" },
  sunday: { start_time: "", end_time: "", week_day: "sunday" },
};

const onlyDaysArr = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

const dayOfWeekOrder = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
};

const OperationHours = ({
  errors,
  timeZone,
  setFieldValue,
  operation_times,
  working_hours_enabled,
  touched,
}) => {
  const existsDayOfWeek = (array, day) => {
    return array.some((timeRange) => timeRange.week_day === day);
  };

  const removeDayOfWeek = (array, day) => {
    return array.filter((timeRange) => timeRange.week_day !== day);
  };

  return (
    <div className="">
      <TimeZones timeZone={timeZone} setFieldValue={setFieldValue} />
      <div className={styles.workinHoursTitle}>Working Hours</div>
      <div className={styles.helperText}>
        Enabling Working Hours allows you to set specific days and times for
        sending quotes to our merchants. Otherwise, you'll be considered
        available 24/7 throughout the week.
      </div>

      <div
        onClick={() => {
          if (!working_hours_enabled) {
            setFieldValue("operation_times", [
              { start_time: "", end_time: "", week_day: "monday" },
            ]);
            setFieldValue("working_hours_enabled", true);
          } else {
            setFieldValue("operation_times", []);
            setFieldValue("working_hours_enabled", false);
          }
        }}
        className="flex items-center cursor-pointer"
      >
        <div>
          {operation_times?.length > 0 ? (
            <FaSquareCheck size={22} color="#0062FF" />
          ) : (
            <FaRegSquare size={22} color="#777777" />
          )}
        </div>
        <div className={styles.checkText}>Set Working Hours</div>
      </div>
      <div className="flex items-center mt-8">
        {onlyDaysArr.map((item, index) => {
          return (
            <div
              style={{ marginLeft: index === 0 && "0px" }}
              onClick={() => {
                const _response = [...operation_times];

                if (existsDayOfWeek(_response, item)) {
                  if (_response.length === 1) {
                    setFieldValue("working_hours_enabled", false);
                  }
                  setFieldValue(
                    "operation_times",
                    removeDayOfWeek(_response, item)
                  );
                } else {
                  if (_response.length === 0) {
                    setFieldValue("working_hours_enabled", true);
                  }
                  _response.push(daysArray[item]);
                  _response?.sort((a, b) => {
                    return (
                      dayOfWeekOrder[a.week_day] - dayOfWeekOrder[b.week_day]
                    );
                  });
                  setFieldValue("operation_times", _response);
                }
              }}
              className={classNames(styles.days, {
                [styles.activeDay]: existsDayOfWeek(operation_times, item),
              })}
              key={item}
            >
              {item.slice(0, 3)}
            </div>
          );
        })}
      </div>

      {operation_times
        ?.sort((a, b) => {
          return dayOfWeekOrder[a.week_day] - dayOfWeekOrder[b.week_day];
        })
        .map((item, index) => {
          return (
            <OneInputsContainer
              key={index}
              index={index}
              item={item}
              daysArray={daysArray}
              dayOfWeekOrder={dayOfWeekOrder}
              err={errors && (errors[index] ?? undefined)}
              touched={touched && (touched[index] ?? undefined)}
              setResponse={(values) => {
                setFieldValue("operation_times", values);
              }}
              response={operation_times}
            />
          );
        })}
    </div>
  );
};

export default OperationHours;
