import { createStore } from "aniuta";
import { useState } from "react";

const useClickStore = createStore({
  name: "clickStore",
  Store: () => {
    const [sidepanelactive, setSidepanelactive] = useState("home");
    const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(false);
    const [goToPayments, setGoToPayments] = useState(false);
    const [isCalendarVisible, setIsCalendarVisible] = useState(true);

    

    const useOnClickOutside = (ref, handler) => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    };

    return {
      useOnClickOutside,
      isBurgerMenuVisible,
      setIsBurgerMenuVisible,
      sidepanelactive,
      setSidepanelactive,
      setGoToPayments,
      goToPayments,
      isCalendarVisible,
      setIsCalendarVisible,
    };
  },
});

export default useClickStore;
