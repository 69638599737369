import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import Colors from "../../../assets/Colors";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { businessSchema } from "../../../validations/schema";
import ButtonComp from "../../Reusable/ButtonComp";
import SelectComp from "../../Reusable/SelectComp";
import logo from "../../../assets/pngs/BurqMiniLogo.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAxios } from "../../../store";
import { toast } from "react-toastify";
import InputErrorComp from "../../Reusable/InputErrorComp";
import { PARTNER_INDUSTRY_OPTIONS } from "../utils";
import CommonToggle from "../../CommonToggle";
import OperationHours from "./OperationHours";

const initialValues = {
  logo: "",
  business_name: "",
  business_address: "",
  contact_number: "",
  website: "",
  industries: [],
  support_ondemand: false,
  support_scheduled: false,
  notice_window: 0,
  alert_window: 0,
  is_test_user: false,
  pickup_eta_wait_time: 0,
  dropoff_eta_wait_time: 0,
  // operation_start_time: "",
  // operation_end_time: "",
  timezone: "UTC",
  operation_times: [],
  working_hours_enabled: false,
  notification_email: "",
};

const BusinessInfo = () => {
  const { xlfontsize, xsfontsize, smFontSize, isdesktop } =
    useDesktopOrLaptop();

  const { authInfo, uploadPartnerLogo, updatePartnerInformation } = useAxios();

  // const initialValues = {
  //   logo: authInfo?.logo ?? "",
  //   business_name: authInfo?.business_name ?? "",
  //   business_address: authInfo?.business_address ?? "",
  //   contact_number: authInfo?.contact_number ?? "",
  //   website: authInfo?.website ?? "",
  //   industries: [],
  //   support_ondemand: authInfo?.support_ondemand ?? false,
  //   support_scheduled: authInfo?.support_scheduled ?? false,
  //   notice_window: authInfo?.notice_window ?? 0,
  // };

  const [partnerPayload, setPartnerPayload] = useState(initialValues);

  const photoInputRef = useRef();

  const setPartner = (payload) => {
    const newPartnerPayload = { ...partnerPayload };
    for (const key in newPartnerPayload) {
      if (payload[key]) {
        newPartnerPayload[key] = payload[key];
      }
    }
    setPartnerPayload(newPartnerPayload);
  };

  useEffect(() => {
    if (authInfo) {
      setPartner(authInfo);
    }
  }, [authInfo]);

  const photoUploadHandler = async (event, setFieldValue) => {
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      setFieldValue("logo", res);
    };
    const file = event?.target?.files[0];
    uploadPartnerLogo(file, callback);
  };

  const industriesChangeHandler = (payload, setFieldValue) =>
    setFieldValue(
      "industries",
      payload.map((data) => data.value)
    );

  const onSubmit = async (values) => {
    const allowFields = [
      // "logo",
      // "business_name",
      // "is_test_user",
      // "pickup_eta_wait_time",
      // "dropoff_eta_wait_time",
      // "industries",
      "business_address",
      "contact_number",
      "website",
      "support_ondemand",
      "support_scheduled",
      "notice_window",
      "alert_window",
      // "operation_start_time",
      // "operation_end_time",
      "timezone",
      "operation_times",
      "working_hours_enabled",
      "notification_email",
    ];
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      toast.success("Partner information save successfully");
    };
    const payload = {};
    for (const key in values) {
      if (allowFields.includes(key)) {
        payload[key] = values[key];
      }
    }

    console.log(payload);
    // return;

    updatePartnerInformation(payload, callback);
  };

  const styles = useMemo(() => {
    return {
      input: {
        fontFamily: "Poppins",
        fontSize: "14px",
        border: "0px",
        padding: "3px 10px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: "100%",
        fontWeight: "400",
      },
      inputLable: {
        fontFamily: "Poppins",
        fontWeight: "500",
        color: Colors.fontDark,
        fontSize: xsfontsize,
        marginTop: "30px",
        marginBottom: "6px",
        marginLeft: "4px",
      },
      inputForEdit: {
        width: "100%",
        borderWidth: "1px",
        border: `solid ${Colors.borderPrimary}`,
        padding: "6px 4px",
        borderRadius: "10px",
        display: "flex",
      },
      errorMessage: {
        fontFamily: "Poppins",
        fontSize: "11px",
        fontWeight: "400",
        color: Colors.redDark,
      },
      inputErrorCompStyles: {
        position: "absolute",
      },
    };
  }, []);

  const customstyles = {
    selectControl: {
      width: "100%",
      borderWidth: "1px",
      border: `solid ${Colors.inputFieldBorder}`,
      borderRadius: "10px",
      boxShadow: "none",
    },
    selectInput: {
      fontFamily: "Poppins",
      fontSize: smFontSize,
      border: "0px",
      borderRadius: "8px",
      outlineWidth: "0px",
      fontWeight: "500",
      margin: "5px",
    },
  };

  const industriesSelectStyles = (errors) => {
    return {
      control: (styles) => ({
        ...styles,
        ...customstyles.selectControl,
        ...{
          border: errors.industries
            ? `solid ${Colors.redDark}`
            : `solid ${Colors.inputFieldBorder}`,
        },
        ":hover": {
          ...styles[":hover"],
          border: errors.industries
            ? `solid ${Colors.redDark}`
            : `solid ${Colors.inputFieldBorder}`,
        },
      }),
      input: (styles) => ({
        ...styles,
        ...customstyles.selectInput,
      }),
      options: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled
              ? isSelected
                ? Colors.bluePrimary
                : Colors.textColor
              : undefined,
          },
          borderRadius: "10px",
        };
      },
    };
  };

  return (
    <div style={{ padding: "20px 16px 0px 16px" }} className="w-full">
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: Colors.white,
          borderRadius: "14px",
          padding: "30px 24px",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: xlfontsize,
          }}
        >
          Business info settings
        </div>
        <Formik
          initialValues={partnerPayload}
          onSubmit={onSubmit}
          validationSchema={businessSchema}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            touched,
            setFieldError,
          }) => (
            <Form>
              <>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                    marginTop: "40px",
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Logo picture</div>
                  <div style={{ width: "60%" }}>
                    <div className="flex items-center" style={{}}>
                      <div
                        className="flex items-center justify-center"
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "200px",
                          overflow: "hidden",
                          backgroundColor: Colors.bgGrey,
                          marginRight: "20px",
                        }}
                      >
                        <img
                          src={
                            values.logo ? values.logo + `?${Date.now()}` : logo
                          }
                          style={{
                            height: "70px",
                            width: "70px",
                            borderRadius: "200px",
                            overflow: "hidden",
                            objectFit: "cover",
                          }}
                          alt=""
                        />
                      </div>
                      <input
                        ref={photoInputRef}
                        type="file"
                        onChange={(e) => photoUploadHandler(e, setFieldValue)}
                        style={{ display: "none" }}
                      />

                      {/* 
                      //! commented bc of we are not allowing user to change it anymore. DO NOT DELETE IT
                      <ButtonComp
                        title={"Upload new picture"}
                        primary
                        extraStyles={{ padding: "5px 20px" }}
                        onClick={() => {
                          photoInputRef.current.click();
                        }}
                      /> */}
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Business name</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor: errors.business_name && Colors.redDark,
                        },
                      }}
                    >
                      <input
                        //! commented bc of we are not allowing user to change it anymore. DO NOT DELETE IT
                        // onChange={handleChange}
                        value={values.business_name}
                        name="business_name"
                        placeholder="Business name"
                        type="text"
                        style={{
                          ...styles.input,
                          ...{ cursor: "not-allowed" },
                        }}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.business_name}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Business address</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor:
                            errors.business_address && Colors.redDark,
                        },
                      }}
                    >
                      <input
                        onChange={handleChange}
                        value={values.business_address}
                        name="business_address"
                        placeholder="Business Address"
                        type="text"
                        style={styles.input}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.business_address}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Notification Email</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor:
                            errors.notification_email && Colors.redDark,
                        },
                      }}
                    >
                      <input
                        onChange={(e) => {
                          if (e.target.value === "") {
                            setFieldValue("notification_email", undefined);
                          } else {
                            setFieldValue("notification_email", e.target.value);
                          }
                        }}
                        value={values.notification_email}
                        name="notification_email"
                        placeholder="Notification Address"
                        type="text"
                        style={styles.input}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.notification_email}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Contact info</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor: errors.contact_number && Colors.redDark,
                          padding: "0px",
                        },
                      }}
                    >
                      <PhoneInput
                        prefix="+"
                        countryCodeEditable={false}
                        autoFormat
                        country={"us"}
                        onlyCountries={["us"]}
                        onChange={(value, country, e, formattedValue) => {
                          setFieldValue("contact_number", formattedValue);
                        }}
                        value={values.contact_number}
                        buttonStyle={{
                          borderWidth: "0px",
                          backgroundColor: Colors.borderPrimary,
                          borderRadius: "10px",
                        }}
                        disableDropdown
                        inputStyle={{
                          border: "0px solid transparent",
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        placeholder="+1 (###) ###-####"
                        style={{
                          height: isdesktop ? "40px" : "54px",
                        }}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.contact_number}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Website link</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor: errors.website && Colors.redDark,
                        },
                      }}
                    >
                      <input
                        onChange={handleChange}
                        value={values.website}
                        name="website"
                        placeholder="https://example.com"
                        type="text"
                        style={styles.input}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.website}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}> Industries</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <SelectComp
                      selectedValue={PARTNER_INDUSTRY_OPTIONS.filter((item) =>
                        values.industries.includes(item.value)
                      )}
                      //! commented bc of we are not allowing user to change it anymore. DO NOT DELETE IT
                      // optionsList={PARTNER_INDUSTRY_OPTIONS}
                      onSelect={(payload) => {
                        //! commented bc of we are not allowing user to change it anymore. DO NOT DELETE IT
                        // industriesChangeHandler(payload, setFieldValue);
                      }}
                      styleProps={industriesSelectStyles(errors)}
                      placeholder="Select"
                      useExternalState={true}
                      isMulti
                    />
                    <InputErrorComp
                      error={errors.industries}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Support Ondemand</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <CommonToggle
                      id="headerToggle"
                      smsHandler={() => {
                        setFieldValue(
                          "support_ondemand",
                          !values.support_ondemand
                        );
                      }}
                      checked={values.support_ondemand}
                      translateX={24}
                      circleStyle={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: "white",
                      }}
                      style={{
                        width: "50px",
                        height: "24px",
                        padding: "3px",
                        backgroundColor: values.support_ondemand
                          ? "#0062FF"
                          : "#979797",
                      }}
                    />
                  </div>
                </div>

                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-start"
                >
                  <div style={{ width: "40%" }}>Operation Hours</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <OperationHours
                      touched={touched?.operation_times}
                      errors={errors.operation_times}
                      setFieldValue={setFieldValue}
                      timeZone={values.timezone}
                      operation_times={values.operation_times}
                      working_hours_enabled={values.working_hours_enabled}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Live User</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <CommonToggle
                      id="headerToggle"
                      smsHandler={() => {
                        // setFieldValue("is_test_user", !values.is_test_user);
                      }}
                      checked={!values.is_test_user}
                      translateX={24}
                      circleStyle={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: "white",
                      }}
                      style={{
                        width: "50px",
                        height: "24px",
                        padding: "3px",
                        backgroundColor: !values.is_test_user
                          ? "#0062FF"
                          : "#979797",
                        cursor: "not-allowed",
                      }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Support Scheduled</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <CommonToggle
                      id="headerToggle"
                      smsHandler={() => {
                        setFieldValue(
                          "support_scheduled",
                          !values.support_scheduled
                        );
                      }}
                      checked={values.support_scheduled}
                      translateX={24}
                      circleStyle={{
                        width: "18px",
                        height: "18px",
                        backgroundColor: "white",
                      }}
                      style={{
                        width: "50px",
                        height: "24px",
                        padding: "3px",
                        backgroundColor: values.support_scheduled
                          ? "#0062FF"
                          : "#979797",
                      }}
                    />
                  </div>
                </div>

                {values.support_scheduled && (
                  <div
                    style={{
                      padding: "26px 0px",
                      borderTopWidth: "1px",
                      borderColor: Colors.borderPrimary,
                    }}
                    className="w-full flex items-center"
                  >
                    <div style={{ width: "40%" }}>Notice Window (Minutes)</div>
                    <div style={{ width: "60%", position: "relative" }}>
                      <div
                        style={{
                          ...styles.inputForEdit,
                          ...{
                            borderColor: errors.notice_window && Colors.redDark,
                          },
                        }}
                      >
                        <input
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = parseInt(
                              inputValue.replace(/\D/g, ""),
                              10
                            );
                            setFieldValue("notice_window", sanitizedValue);
                            if (inputValue) {
                              setFieldValue("notice_window", sanitizedValue);
                            } else {
                              setFieldValue("notice_window", 0);
                            }
                          }}
                          value={values.notice_window}
                          name="notice_window"
                          placeholder="Notice Window"
                          type="text"
                          style={styles.input}
                        />
                      </div>
                      <InputErrorComp
                        error={errors.notice_window}
                        styleProps={styles.inputErrorCompStyles}
                      />
                    </div>
                  </div>
                )}

                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Alert Window (Minutes)</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor: errors.alert_window && Colors.redDark,
                        },
                      }}
                    >
                      <input
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const sanitizedValue = parseInt(
                            inputValue.replace(/\D/g, ""),
                            10
                          );
                          setFieldValue("alert_window", sanitizedValue);
                          if (inputValue) {
                            setFieldValue("alert_window", sanitizedValue);
                          } else {
                            setFieldValue("alert_window", 0);
                          }
                        }}
                        value={values.alert_window}
                        name="alert_window"
                        placeholder="Alert Window"
                        type="text"
                        style={styles.input}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.alert_window}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>

                <div
                  style={{
                    padding: "26px 0px",
                    borderTopWidth: "1px",
                    borderColor: Colors.borderPrimary,
                  }}
                  className="w-full flex items-center"
                >
                  <div style={{ width: "40%" }}>Pickup ETA (Minutes)</div>
                  <div style={{ width: "60%", position: "relative" }}>
                    <div
                      style={{
                        ...styles.inputForEdit,
                        ...{
                          borderColor:
                            errors.pickup_eta_wait_time && Colors.redDark,
                          cursor: "not-allowed",
                          opacity: 0.6,
                        },
                      }}
                    >
                      <input
                        disabled
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const sanitizedValue = parseInt(
                            inputValue.replace(/\D/g, ""),
                            10
                          );

                          if (!sanitizedValue) {
                            setFieldValue("dropoff_eta_wait_time", 0);
                          }
                          setFieldValue("pickup_eta_wait_time", sanitizedValue);
                          if (inputValue) {
                            setFieldValue(
                              "pickup_eta_wait_time",
                              sanitizedValue
                            );
                          } else {
                            setFieldValue("pickup_eta_wait_time", 0);
                          }
                        }}
                        value={values.pickup_eta_wait_time}
                        name="pickup_eta_wait_time"
                        placeholder="Pickup ETA"
                        type="text"
                        style={{
                          ...styles.input,
                          ...{ cursor: "not-allowed" },
                        }}
                      />
                    </div>
                    <InputErrorComp
                      error={errors.pickup_eta_wait_time}
                      styleProps={styles.inputErrorCompStyles}
                    />
                  </div>
                </div>

                {!!values.pickup_eta_wait_time && (
                  <div
                    style={{
                      padding: "26px 0px",
                      borderTopWidth: "1px",
                      borderColor: Colors.borderPrimary,
                    }}
                    className="w-full flex items-center"
                  >
                    <div style={{ width: "40%" }}>Dropoff ETA (Minutes)</div>
                    <div style={{ width: "60%", position: "relative" }}>
                      <div
                        style={{
                          ...styles.inputForEdit,
                          ...{
                            borderColor:
                              errors.dropoff_eta_wait_time && Colors.redDark,
                            cursor: "not-allowed",
                            opacity: 0.6,
                          },
                        }}
                      >
                        <input
                          disabled
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            const sanitizedValue = parseInt(
                              inputValue.replace(/\D/g, ""),
                              10
                            );
                            setFieldValue(
                              "dropoff_eta_wait_time",
                              sanitizedValue
                            );
                            if (inputValue) {
                              setFieldValue(
                                "dropoff_eta_wait_time",
                                sanitizedValue
                              );
                            } else {
                              setFieldValue("dropoff_eta_wait_time", 0);
                            }
                          }}
                          value={values.dropoff_eta_wait_time}
                          name="dropoff_eta_wait_time"
                          placeholder="Dropoff ETA"
                          type="text"
                          style={{
                            ...styles.input,
                            ...{ cursor: "not-allowed" },
                          }}
                        />
                      </div>
                      <InputErrorComp
                        error={errors.dropoff_eta_wait_time}
                        styleProps={styles.inputErrorCompStyles}
                      />
                    </div>
                  </div>
                )}

                <div
                  style={{ marginTop: "20px" }}
                  className="w-full flex items-center justify-end"
                >
                  <Link to="/">
                    <ButtonComp
                      title={"Cancel"}
                      extraStyles={{ color: Colors.white, marginRight: "20px" }}
                      secondary
                    />
                  </Link>
                  <ButtonComp
                    title={"Save changes"}
                    extraStyles={{ color: Colors.white }}
                    type="submit"
                    // onClick={handleSubmit}
                    primary
                  />
                </div>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessInfo;
