import React from "react";
import Colors from "../../assets/Colors";
import { Loading } from "../../assets/svgs/AllSvgs";

const LoaderComp = ({ loading, size = 6 , color}) => {
  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center">
          {" "}
          <Loading
            color={color ? color: Colors.bluePrimary}
            className={`${size === 6 ? "h-6 w-6" : "h-14 w-14"}  animate-spin`}
          />{" "}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default LoaderComp;

/**
 * 
 * color={Colors.bluePrimary}
    className="h-14 w-14 animate-spin"



*/
