import React, { useMemo } from "react";
import SelectComp from "../../../../../Reusable/SelectComp";
import Colors from "../../../../../../assets/Colors";
import { InputComp } from "../../../../../Reusable/InputComp";
import { useDesktopOrLaptop } from "../../../../../../services/useDesktopOrLaptop";
import { useFormikContext } from "formik";
import InputErrorComp from "../../../../../Reusable/InputErrorComp";
import { getFieldValueIcon } from "../utils";

const ResultForm = ({
  ruleExpressionFieldsList,
  ruleResultFieldsList,
  operationsList,
}) => {
  const { values, errors, touched, handleChange, setFieldValue } =
    useFormikContext();
  const { smFontSize } = useDesktopOrLaptop();

  const styles = useMemo(() => {
    return {
      expressionTopContainer: {
        width: "40%",
      },
      expressionContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      },
      expressionLeftContainer: {
        width: "40%",
      },
      expressionOperatorContainer: {
        width: "20%",
        margin: "0 20px",
      },
      expressionRightContainer: {
        width: "40%",
      },
    };
  }, []);

  const customstyles = {
    selectControl: {
      width: "100%",
      borderWidth: "1px",
      border: `solid ${Colors.inputFieldBorder}`,
      borderRadius: "10px",
      boxShadow: "none",
    },
    selectInput: {
      fontFamily: "Poppins",
      fontSize: smFontSize,
      border: "0px",
      borderRadius: "8px",
      outlineWidth: "0px",
      fontWeight: "500",
      margin: "5px",
    },
  };

  const selectStyles = (isErrors = false) => {
    return {
      control: (styles) => ({
        ...styles,
        ...customstyles.selectControl,
        ...{
          border: isErrors
            ? `solid ${Colors.redDark}`
            : `solid ${Colors.inputFieldBorder}`,
        },
        ":hover": {
          ...styles[":hover"],
          border: isErrors
            ? `solid ${Colors.redDark}`
            : `solid ${Colors.inputFieldBorder}`,
        },
      }),
      input: (styles) => ({
        ...styles,
        ...customstyles.selectInput,
      }),
      options: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
          ":active": {
            ...styles[":active"],
            backgroundColor: !isDisabled
              ? isSelected
                ? Colors.bluePrimary
                : Colors.textColor
              : undefined,
          },
          borderRadius: "10px",
        };
      },
    };
  };

  return (
    <div>
      <div style={styles.expressionTopContainer}>
        <SelectComp
          selectedValue={ruleResultFieldsList.find(
            (item) => item.value === values.result.field
          )}
          onSelect={(payload) =>
            setFieldValue("result.field", payload?.value || null)
          }
          optionsList={ruleResultFieldsList}
          styleProps={selectStyles(
            touched?.result?.field && errors?.result?.field
          )}
          useExternalState={true}
        />
        {touched?.result?.field && (
          <InputErrorComp error={errors?.result?.field} />
        )}
      </div>
      <div style={{ margin: "10px 0" }}>Equals to</div>
      <div style={styles.expressionContainer}>
        <div style={styles.expressionLeftContainer}>
          <SelectComp
            selectedValue={ruleExpressionFieldsList.find(
              (item) => item.value === values.result.value.variable
            )}
            onSelect={(payload) =>
              setFieldValue("result.value.variable", payload?.value || null)
            }
            optionsList={ruleExpressionFieldsList}
            styleProps={selectStyles(
              touched?.result?.value?.variable &&
                errors?.result?.value?.variable
            )}
            useExternalState={true}
          />
          {touched?.result?.value?.variable && (
            <InputErrorComp error={errors?.result?.value?.variable} />
          )}
        </div>
        <div style={styles.expressionOperatorContainer}>
          <SelectComp
            selectedValue={operationsList.find(
              (item) => item.value === values.result.value.operation
            )}
            onSelect={(payload) =>
              setFieldValue("result.value.operation", payload?.value || null)
            }
            optionsList={operationsList}
            styleProps={selectStyles(
              touched?.result?.value?.operation &&
                errors?.result?.value?.operation
            )}
            isDisabled={!values.result.value.variable}
            resetSelection={!values.result.value.variable}
            useExternalState={true}
          />
          {touched?.result?.value?.operation && (
            <InputErrorComp error={errors?.result?.value?.operation} />
          )}
        </div>
        <div style={styles.expressionRightContainer}>
          <InputComp
            name={`result.value.field_value`}
            onChange={handleChange}
            value={values.result.value.field_value}
            disabled={!values.result.field}
            borderStyles={
              errors?.result?.value?.field_value &&
              touched?.result?.value?.field_value
                ? { borderColor: Colors.redDark }
                : {}
            }
            leftIcon={getFieldValueIcon(
              ruleResultFieldsList,
              values?.result?.field
            )}
          />
          {touched?.result?.value?.field_value && (
            <InputErrorComp error={errors?.result?.value?.field_value} />
          )}
        </div>
        {/* <div style={{ marginLeft: "10px" }}>
          <AiOutlineClose />
        </div> */}
      </div>
    </div>
  );
};

export default ResultForm;
