import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useConfiguration } from "../../../../store";
import { zoneSchema } from "../../../../validations/schema";
import ButtonComp from "../../../Reusable/ButtonComp";
import DistanceBased from "./DistanceBased";
import MainZonePage from "./MainZonePage";
import { useAxios } from "../../../../store";
import { toast } from "react-toastify";
import { cloneDeep } from "lodash";
import CustomPricingRule from "./CustomPricingRule";

const initialValues = {
  zone_name: "",
  zip_code: "",
  zone_status: false,
  rate_name: "",
  rate_description: "",
  limit_min_distance: "",
  limit_max_distance: "",
  price_base_delivery: "",
  price_base_mile: "",
  delivery_max_rate: false,
  price_per_mile: "",
  vehicle: [],
  provided_features: [],
  rule: [],
};

const typeFields = [
  { field: "zone_name", type: "string" },
  { field: "zip_code", type: "object" },
  { field: "zone_status", type: "boolean" },
  { field: "rate_name", type: "string" },
  { field: "rate_description", type: "string" },
  { field: "limit_min_distance", type: "number" },
  { field: "limit_max_distance", type: "number" },
  { field: "price_base_delivery", type: "number" },
  { field: "price_base_mile", type: "number" },
  { field: "price_per_mile", type: "number" },
  { field: "delivery_max_rate", type: "boolean" },
  { field: "vehicle", type: "object" },
  { field: "provided_features", type: "object" },
  { field: "rule", type: "object" },
];

const AddEditZone = ({ setActivePage, zone }) => {
  const { isEdit, setIsEdit, active, setActive } = useConfiguration();
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);

  const [zonePayload, setZonePayload] = useState(initialValues);

  const { saveZone, updateZone } = useAxios();

  const onSubmit = async (values) => {
    setIsSaveButtonDisabled(true);
    const payload = { ...values };

    payload.zip_code = payload.zip_code.split(",");

    for (const key in payload) {
      const foundType = typeFields.find((type) => type.field === key);
      if (foundType) {
        if (foundType.type === "number" && payload[key] === "") {
          delete payload[key];
        }
        if (foundType.type === "number" && payload[key]) {
          payload[key] = parseFloat(payload[key]);
        }
      }
    }

    payload.price_per_mile = Math.round(payload.price_per_mile * 100);
    if (payload.price_base_delivery || payload.price_base_delivery === 0) {
      payload.price_base_delivery = Math.round(
        payload.price_base_delivery * 100
      );
    } else {
      payload.price_base_delivery = null;
    }

    if (payload.price_base_mile == null || payload.price_base_mile === "") {
      payload.price_base_mile = null;
    }

    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      toast.success(`Zone save successfully`);
      setActivePage("main");
      setIsSaveButtonDisabled(false);
    };

    if (isEdit) {
      const zoneId = payload.id;
      delete payload.id;
      updateZone(zoneId, payload, callback);
    } else {
      saveZone(payload, callback);
    }
  };

  const setZoneOnUpdate = (payload) => {
    let newPayload = cloneDeep(payload);
    for (const key in initialValues) {
      if (key === "rule" && !newPayload[key]) {
        newPayload[key] = [];
      }
      if (key === "zip_code") {
        newPayload[key] = newPayload[key].toString();
      }
      if (key === "price_base_delivery" || key === "price_per_mile") {
        newPayload[key] = newPayload[key]
          ? (newPayload[key] / 100).toFixed(2)
          : newPayload[key];
      }
      if (newPayload[key] === null) {
        newPayload[key] = "";
      }
    }

    setZonePayload(newPayload);
  };

  useEffect(() => {
    if (zone) {
      setZoneOnUpdate(zone);
    }
    return () => {
      setZonePayload(initialValues);
    };
  }, [zone, active]);

  return (
    <div className="flex flex-col">
      <Formik
        initialValues={zonePayload}
        onSubmit={onSubmit}
        validationSchema={zoneSchema}
        enableReinitialize
      >
        {({
          handleChange,
          handleSubmit,
          isSubmitting,
          values,
          errors,
          setFieldValue,
          setFieldTouched,
          touched,
          setFieldError,
          validateForm,
        }) => (
          <>
            {active === "main" ? (
              <MainZonePage
                setIsEdit={setIsEdit}
                setActive={setActive}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                setFieldError={setFieldError}
              />
            ) : active === "distance" ? (
              <DistanceBased
                setActive={setActive}
                isEdit={isEdit}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                touched={touched}
                setFieldError={setFieldError}
              />
            ) : (
              (active === "list_rules" || active === "create_rule") && (
                <CustomPricingRule
                  values={values}
                  setFieldValue={setFieldValue}
                  active={active}
                  setActive={setActive}
                />
              )
            )}
            {active !== "create_rule" && (
              <div className="w-full flex items-center justify-end">
                <ButtonComp
                  primary={!isSaveButtonDisabled}
                  secondary={isSaveButtonDisabled}
                  onClick={(e) => {
                    if (errors.zip_code || errors.zone_name)
                      toast.error("Please correct the zone details!");
                    else if (errors.rate_name || errors.price_per_mile)
                      toast.error("Please add price based rate details!");
                    handleSubmit(e);
                  }}
                  title={"Save Changes"}
                  extraStyles={{ paddingTop: "6px", paddingBottom: "6px" }}
                  disabled={isSaveButtonDisabled}
                />
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default AddEditZone;
