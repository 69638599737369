import React from "react";
import classNames from "classnames";
import { toast } from "react-toastify";

import styles from "./styles.module.css";
// import ImagesContainerOnSidebar from "../../../../../DeliveryDetailsOnSidePopup/ImagesContainerOnSidebar";

import { FaPlus, FaRegTrashCan } from "react-icons/fa6";
import { CiTrash } from "react-icons/ci";
import useAxios from "../../store/useAxios";

const ImagesUpload = ({
  images,
  setImages,
  errorMessage,
  setErrorMessage,
  onDeleteImage = true,
  inputId,
  max,
}) => {
  const { itemForSeeMoreModal, setItemForSeeMoreModal } = useAxios();
  const handleFileChange = (event) => {
    if (event.target.files[0].size > 10485760) {
      // 1MB in bytes
      toast.error("File size should be 10MB or less.");
      return;
    }
    const files = Array.from(event.target.files).slice(0, max);
    // setErrorMessage("");

    setImages([...images, ...files].slice(0, max));
  };

  const handleBrowseClick = () => {
    document.getElementById("incidentFileInput" + inputId).click();
  };

  const showPlusIcons = () => {
    if (itemForSeeMoreModal?.proof_of_delivery?.length > 0 && inputId === "pod")
      return false;
    if (itemForSeeMoreModal?.signature && inputId === "signature") return false;
    if (images && images?.length < max) return true;
  };

  return (
    <div className={styles.container}>
      <input
        id={`incidentFileInput` + inputId}
        style={{ display: "none" }}
        type="file"
        accept=".jpeg, .jpg, .png, .svg"
        max={3}
        // multiple
        onChange={handleFileChange}
        className={styles.inputFile}
      />

      <div className="flex items-center w-full justify-start overflow-x-auto relative">
        {images?.map((url, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                // setImageUrl(url);
              }}
              className={classNames(
                "cursor-pointer mr-7",
                styles.imagesContainer
              )}
            >
              {onDeleteImage && (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setImages((prevFiles) =>
                      prevFiles.filter((_, i) => i !== index)
                    );
                  }}
                  className={styles.deleteImage}
                >
                  {/* <DeleteIcon /> */}
                  <FaRegTrashCan size={14} />
                </div>
              )}

              <img
                className={classNames(
                  "object-cover overflow-hidden rounded-xl",
                  styles.images
                )}
                alt=""
                src={[URL.createObjectURL(url)]}
              />
            </div>
          );
        })}

        {itemForSeeMoreModal?.proof_of_delivery?.length > 0 && inputId === "pod"
          ? itemForSeeMoreModal?.proof_of_delivery.map((url, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    // setImageUrl(url);
                  }}
                  className={classNames(
                    "cursor-pointer mr-7",
                    styles.imagesContainer
                  )}
                >
                  <img
                    className={classNames(
                      "object-cover overflow-hidden rounded-xl",
                      styles.images
                    )}
                    alt=""
                    src={url}
                  />
                </div>
              );
            })
          : itemForSeeMoreModal?.signature &&
            inputId === "signature" && (
              <div
                onClick={() => {
                  // setImageUrl(url);
                }}
                className={classNames(
                  "cursor-pointer mr-7",
                  styles.imagesContainer
                )}
              >
                <img
                  className={classNames(
                    "object-cover overflow-hidden rounded-xl",
                    styles.images
                  )}
                  alt=""
                  src={itemForSeeMoreModal?.signature}
                />
              </div>
            )}

        {showPlusIcons() && (
          <div
            onClick={handleBrowseClick}
            className={classNames(styles.addImageCont, "mt-0")}
          >
            <div className={styles.iconCont}>
              <FaPlus size={30} color="#0062ff" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ImagesUpload;
