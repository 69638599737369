import React, { useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import TimeInput from "../../../../shared/reusable/TimeInput";
import { CiCirclePlus } from "react-icons/ci";

const OneInputsContainer = ({
  index,
  item,
  response,
  daysArray,
  dayOfWeekOrder,
  err,
  setResponse,
  touched,
}) => {
  let previousDay = "monday";

  const [error, setError] = useState("");

  const handleTimeChange = (e) => {
    const { name, value } = e.target;
    const _response = [...response];
    const _item = { ...item };

    if (_response[index - 1] && value) {
      console.log("_response[index - 1]", value);
      if (_response[index - 1].week_day === _item.week_day) {
        if (_response[index - 1].end_time > value) {
          if (name === "openingTime") {
            _item.start_time = "";
          }
          if (name === "closingTime") {
            _item.end_time = "";
          }
          _response[index] = _item;
          setResponse(_response);
          console.log("overlapp happened");
          setError("Overlapp hours ");
          return;
        } else {
          setError("");
        }
      }
    }

    if (
      name === "closingTime" &&
      _item.start_time &&
      value < _item.start_time
    ) {
      _item.end_time = "";
      setError("closing time should not be less");
    } else if (
      name === "closingTime" &&
      _item.start_time &&
      value >= _item.start_time
    ) {
      _item.end_time = value;
      setError("");
    } else if (name === "openingTime") {
      _item.start_time = value;
      if (_item.end_time) {
        _item.end_time = "";
      }
      setError("");
    } else if (name === "closingTime" && !_item.start_time) {
      _item.end_time = value;
      setError("");
    }
    _response[index] = _item;
    setResponse(_response);
    // console.log("object", _response);
  };

  if (previousDay !== item.week_day) {
    previousDay = item.week_day;
  }
  return (
    <div className="my-3" key={index}>
      <div className={styles.form}>
        {index === 0 && <div className={styles.dayTitle}>{item?.week_day}</div>}
        {index > 0 && response[index - 1]?.week_day !== previousDay ? (
          <div className={styles.dayTitle}>{item?.week_day}</div>
        ) : (
          index !== 0 && <div className={styles.dayTitle}></div>
        )}
        <div className={classNames(styles.timeInput, "ml-6")}>
          <TimeInput
            name={"openingTime"}
            type={"time"}
            value={item.start_time}
            onChange={handleTimeChange}
            label="Opening Time:"
          />
        </div>

        {/* <input type="time" placeholder="00:00 AM" /> */}

        <div className={classNames(styles.timeInput, "ml-6")}>
          <TimeInput
            name={"closingTime"}
            type={"time"}
            onChange={handleTimeChange}
            value={item?.end_time}
            label="Closing Time:"
          />
        </div>
        {/* ! ADD SAME DAY WILL RETURN */}
        {index === 0 && (
          <div
            className="ml-3 cursor-pointer"
            onClick={() => {
              const _response = [...response];

              _response.push(daysArray[item.week_day]);
              _response?.sort((a, b) => {
                return dayOfWeekOrder[a.week_day] - dayOfWeekOrder[b.week_day];
              });
              setResponse(_response);
            }}
          >
            <CiCirclePlus size={30} color="#777" />
          </div>
        )}

        {index > 0 && response[index - 1]?.week_day !== previousDay ? (
          <div
            className="ml-3 cursor-pointer w-7 flex-shrink-0"
            onClick={() => {
              const _response = [...response];

              _response.push(daysArray[item.week_day]);
              _response?.sort((a, b) => {
                return dayOfWeekOrder[a.week_day] - dayOfWeekOrder[b.week_day];
              });
              setResponse(_response);
            }}
          >
            <CiCirclePlus size={30} color="#777" />
          </div>
        ) : (
          index !== 0 && (
            <div
              className="ml-3 cursor-pointer w-7 flex-shrink-0"
              onClick={() => {
                const _response = [...response];

                _response.push(daysArray[item.week_day]);
                _response?.sort((a, b) => {
                  return (
                    dayOfWeekOrder[a.week_day] - dayOfWeekOrder[b.week_day]
                  );
                });
                setResponse(_response);
              }}
            >
              {/* <CiCirclePlus size={30} color="#777" /> */}
            </div>
          )
        )}
        {/* <div
          className="ml-3 cursor-pointer"
          onClick={() => {
            const _response = [...response];

            _response.push(daysArray[item.week_day]);
            _response?.sort((a, b) => {
              return dayOfWeekOrder[a.week_day] - dayOfWeekOrder[b.week_day];
            });
            setResponse(_response);
          }}
        >
          <CiCirclePlus size={30} color="#777" />
        </div> */}
      </div>
      {(err?.start_time || err?.end_time || error) &&
        (touched?.start_time || touched?.end_time || error) && (
          <div className={styles.error}>
            {error || err?.start_time || err?.end_time}
          </div>
        )}
    </div>
  );
};

export default OneInputsContainer;
