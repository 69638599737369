import React, { useEffect, useMemo, useState } from "react";
import Colors from "../../../../assets/Colors";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import car from "../../../../assets/pngs/vehicles/car.png";
import truck from "../../../../assets/pngs/vehicles/truck.png";
import van from "../../../../assets/pngs/vehicles/van.png";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import { InputComp } from "../../../Reusable/InputComp";
import InputErrorComp from "../../../Reusable/InputErrorComp";
import RatesCards from "./RatesCards";

const FEATURES = [
  { name: "Contactless", id: 0, checked: false },
  { name: "Signature", id: 1, checked: false },
  { name: "Proof of Delivery", id: 2, checked: false },
  { name: "Alcohol Delivery", id: 3, checked: false },
  { name: "CBD Delivery", id: 4, checked: false },
  { name: "Batching", id: 5, checked: false },
];

const DistanceBased = ({
  setActive,
  handleChange,
  handleSubmit,
  isSubmitting,
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  touched,
  setFieldError,
}) => {
  const [features, setFeatures] = useState([]);

  useEffect(() => {
    if (values.provided_features.length) {
      const newList = FEATURES.map((ferature) => {
        if (values.provided_features.includes(ferature.name)) {
          ferature.checked = true;
        }
        return ferature;
      });
      setFeatures(newList);
    } else {
      setFeatures(FEATURES);
    }
  }, []);

  useEffect(() => {
    setFeaturesValue();
  }, [features]);

  const setVehicle = (vehiclesList = [], name) => {
    let selectedVehicles = [...vehiclesList];
    if (vehiclesList.includes(name)) {
      selectedVehicles = selectedVehicles.filter((vehicle) => vehicle !== name);
    } else {
      selectedVehicles.push(name);
    }
    setFieldValue("vehicle", selectedVehicles);
  };

  const setFeaturesValue = () => {
    setFieldValue(
      "provided_features",
      features
        .filter((feature) => feature.checked)
        .map((feature) => feature.name)
    );
  };

  const { xsfontsize, smFontSize, isdesktop, mdFontSize } =
    useDesktopOrLaptop();
  const styles = useMemo(() => {
    return {
      input: {
        fontFamily: "Poppins",
        fontSize: "14px",
        border: "0px",
        padding: "3px 10px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: "100%",
        fontWeight: "400",
      },
      inputLable: {
        fontFamily: "Poppins",
        fontWeight: "500",
        color: Colors.fontDark,
        fontSize: xsfontsize,
        marginTop: "30px",
        marginBottom: "6px",
        marginLeft: "4px",
      },
      inputForEdit: {
        width: "100%",
        borderWidth: "1px",
        border: `solid ${Colors.borderPrimary}`,
        padding: "6px 4px",
        borderRadius: "10px",
        display: "flex",
      },
      title: {
        fontSize: mdFontSize,
        color: Colors.fontDark,
        fontWeight: "500",
        marginBottom: "10px",
      },
      desc: {
        fontSize: smFontSize,
        color: Colors.fontGreyLight,
        fontWeight: "400",
      },
      vehicleButton: {
        padding: "14px",
        borderWidth: "1px",
        borderRadius: "10px",
        marginRight: "20px",
        borderColor: "transparent",
      },
      vehicleImage: {
        height: isdesktop ? "32px" : "36px",
        width: isdesktop ? "50px" : "56px",
        objectFit: "contain",
      },
      vehicleText: {
        color: Colors.bluePrimary,
        fontSize: smFontSize,
        fontWeight: "500",
      },
    };
  }, []);

  const allowTwoDecimalsOnly = (setFieldValue, key, value) => {
    const t = value;
    const newValue = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    setFieldValue(key, newValue);
}
  return (
    <>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div style={styles.title}>General</div>
          <div style={styles.desc}>Choose how you want to charge</div>
        </div>
        <div style={{ width: "76%" }}>
          <div style={styles.title}>Rate Name</div>
          <div
            style={{
              ...styles.inputForEdit,
              ...{
                borderColor:
                  errors.rate_name && touched.rate_name && Colors.redDark,
              },
            }}
          >
            <input
              onChange={handleChange}
              value={values.rate_name}
              name="rate_name"
              placeholder="Enter Rate Name"
              type="text"
              style={styles.input}
            />
          </div>
          {touched.rate_name && <InputErrorComp error={errors.rate_name} />}
          <div style={{ ...styles.title, ...{ marginTop: "20px" } }}>
            Rate description
          </div>
          <div
            style={{
              ...styles.inputForEdit,
              ...{
                borderColor:
                  errors.rate_description &&
                  touched.rate_description &&
                  Colors.redDark,
              },
            }}
          >
            <input
              onChange={handleChange}
              value={values.rate_description}
              name="rate_description"
              placeholder="Enter rate description"
              type="text"
              style={styles.input}
            />
          </div>
          {touched.rate_description && (
            <InputErrorComp error={errors.rate_description} />
          )}
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          marginTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div style={styles.title}>Limit Delivery Radius</div>
          <div style={styles.desc}>
            Only show this rate when the order is within limits. If you don’t
            want a maximum price or distance, leave those fields blank
          </div>
        </div>
        <div style={{ width: "76%" }}>
          <div style={styles.title}>Minimum distance</div>
          <InputComp
            borderStyles={{
              borderColor:
                errors.limit_min_distance &&
                touched.limit_min_distance &&
                Colors.redDark,
            }}
            rightIcon={"miles"}
            onChange={handleChange}
            value={values.limit_min_distance}
            name="limit_min_distance"
            placeholder="Enter distance"
            type="text"
            style={styles.input}
          />
          {touched.limit_min_distance && (
            <InputErrorComp error={errors.limit_min_distance} />
          )}
          <div style={{ ...styles.title, ...{ marginTop: "20px" } }}>
            Maximum distance
          </div>
          <InputComp
            borderStyles={{
              borderColor:
                errors.limit_max_distance &&
                touched.limit_max_distance &&
                Colors.redDark,
            }}
            rightIcon={"miles"}
            onChange={handleChange}
            value={values.limit_max_distance}
            name="limit_max_distance"
            placeholder="Enter distance"
            type="text"
            style={styles.input}
          />
          {touched.limit_max_distance && (
            <InputErrorComp error={errors.limit_max_distance} />
          )}
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          marginTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div style={styles.title}>Pricing</div>
          <div style={styles.desc}>
            Configure fixed pricing for provided mileage
          </div>
        </div>
        <div style={{ width: "76%" }}>
          <div style={styles.title}>Base delivery price</div>
          <InputComp
            borderStyles={{
              borderColor:
                errors.price_base_delivery &&
                touched.price_base_delivery &&
                Colors.redDark,
            }}
            leftIcon={"$"}
            onChange={(e)=>allowTwoDecimalsOnly(setFieldValue, 'price_base_delivery', e.target.value)}
            value={values.price_base_delivery}
            name="price_base_delivery"
            placeholder="Enter base delivery price"
            type="text"
            style={styles.input}
          />
          {touched.price_base_delivery && (
            <InputErrorComp error={errors.price_base_delivery} />
          )}

          <div style={{ ...styles.desc, ...{ marginTop: "6px" } }}>
            Provides fixed price for base mileage provided. Upon exceeding base
            milage, price per miles rate will be added
          </div>

          <div style={{ ...styles.title, ...{ marginTop: "20px" } }}>
            Base mileage
          </div>
          <InputComp
            borderStyles={{
              borderColor:
                errors.price_base_mile &&
                touched.price_base_mile &&
                Colors.redDark,
            }}
            leftIcon={"Mi"}
            onChange={handleChange}
            value={values.price_base_mile}
            name="price_base_mile"
            placeholder="Enter base delivery mileage"
            type="text"
            style={styles.input}
          />
          {touched.price_base_mile && (
            <InputErrorComp error={errors.price_base_mile} />
          )}
          <div style={{ ...styles.desc, ...{ marginTop: "6px" } }}>
            Mileage that base delivery price applies to
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          marginTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div style={styles.title}>Rate price limit</div>
          <div style={styles.desc}>
            Edit the rate name, and choose to make an exception for delivery
            rate or distance
          </div>
        </div>
        <div style={{ width: "76%" }}>
          {/* <div style={styles.title}>Delivery rate maximum status</div> */}
          {/* <div className="flex items-center">
            <CommonToggle
              id="headerToggle"
              smsHandler={() => {
                setFieldValue("delivery_max_rate", !values.delivery_max_rate);
              }}
              checked={values.delivery_max_rate}
              translateX={15}
              circleStyle={{
                width: "16px",
                height: "16px",
                backgroundColor: "white",
              }}
              style={{
                width: "37px",
                height: "20px",
                padding: "3px",
                backgroundColor: values.delivery_max_rate
                  ? Colors.green
                  : "#979797",
              }}
            />
            <div style={{ ...styles.desc, ...{ marginLeft: "10px" } }}>
              Enable maximum delivery rate
            </div>
          </div> */}

          <div style={{ ...styles.title, ...{ marginTop: "0px" } }}>
            Delivery price per miles
          </div>
          <InputComp
            borderStyles={{
              borderColor:
                errors.price_per_mile &&
                touched.price_per_mile &&
                Colors.redDark,
            }}
            leftIcon={"$"}
            onChange={(e)=>allowTwoDecimalsOnly(setFieldValue, 'price_per_mile', e.target.value)}
            value={values.price_per_mile}
            name="price_per_mile"
            placeholder="Enter base delivery price"
            type="text"
            style={styles.input}
          />
          {touched.price_per_mile && (
            <InputErrorComp error={errors.price_per_mile} />
          )}
          <div style={{ ...styles.desc, ...{ marginTop: "6px" } }}>
            Customer will not be charged more than USD 0 for delivery
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          paddingTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div
            style={{
              fontSize: mdFontSize,
              color: Colors.fontDark,
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Custom pricing rule
          </div>
          <div
            style={{
              fontSize: smFontSize,
              color: Colors.fontGreyLight,
              fontWeight: "400",
            }}
          >
            Create the custom rules for pricing that will be used to calculate quote for the delivery
          </div>
        </div>
        <div style={{ width: "76%" }}>
          <RatesCards
            title={"Custom pricing rules"}
            desc={
              "Rates based on the custom rules defined by the partner delivery provider"
            }
            onClick={() => {
              setActive("list_rules");
            }}
            buttonText={"Add custom price rule"}
          /> 
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          marginTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div style={styles.title}>Available vehicle</div>
        </div>
        <div style={{ width: "76%" }}>
          <div style={styles.title}>Select available vehicle</div>

          <div className="flex items-center">
            <button
              onClick={() => setVehicle(values.vehicle, "van")}
              type="button"
              className="flex flex-col items-center"
              style={{
                ...styles.vehicleButton,
                ...{
                  backgroundColor: Colors.vanBg,
                  borderColor:
                    values.vehicle.includes("van") && Colors.bluePrimary,
                },
              }}
            >
              <img style={styles.vehicleImage} src={van} alt="" />
              <div style={styles.vehicleText}>Van</div>
            </button>
            <button
              onClick={() => setVehicle(values.vehicle, "truck")}
              type="button"
              className="flex flex-col items-center"
              style={{
                ...styles.vehicleButton,
                ...{
                  backgroundColor: Colors.carBg,
                  borderColor:
                    values.vehicle.includes("truck") && Colors.bluePrimary,
                },
              }}
            >
              <img style={styles.vehicleImage} src={truck} alt="" />
              <div style={styles.vehicleText}>Truck</div>
            </button>

            <button
              onClick={() => setVehicle(values.vehicle, "car")}
              type="button"
              className="flex flex-col items-center"
              style={{
                ...styles.vehicleButton,
                ...{
                  backgroundColor: Colors.truckBg,
                  borderColor:
                    values.vehicle.includes("car") && Colors.bluePrimary,
                },
              }}
            >
              <img style={styles.vehicleImage} src={car} alt="" />
              <div style={styles.vehicleText}>Car</div>
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          marginTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div style={styles.title}>Features provided section</div>
          <div style={styles.desc}>
            Edit the rate name, and choose to make an exception for delivery
            rate or distance
          </div>
        </div>
        <div style={{ width: "76%" }}>
          <div style={styles.title}>Delivery rate maximum status</div>
          {features?.map((feat, index) => {
            return (
              <div
                key={feat.id}
                className={"flex items-center"}
                onClick={() => {
                  let _features = [...features];
                  _features[index].checked = !_features[index].checked;
                  setFeatures(_features);
                }}
              >
                <div style={{ height: "25px", width: "25px" }}>
                  {feat.checked ? (
                    <BiCheckboxChecked
                      size={isdesktop ? 26 : 28}
                      color={Colors.bluePrimary}
                    />
                  ) : (
                    <BiCheckbox
                      color={Colors.fontGreyLight}
                      size={isdesktop ? 26 : 28}
                    />
                  )}
                </div>
                <div
                  style={{
                    fontSize: smFontSize,
                    color: feat.checked
                      ? Colors.fontDark
                      : Colors.fontGreyLight,
                  }}
                >
                  {feat.name}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DistanceBased;
