import React from "react";
import Colors from "../../assets/Colors";
import { backgroundColorMap, imageSrcMap } from "./utils";

function TabCards({
  total,
  title,
  setTabsActive,
  tabsActive,
  newSum,
  setPage,
}) {
  return (
    <button
      id={`${title}TabIdBtn`}
      type="button"
      onClick={() => {
        setTabsActive(title);
        setPage(0);
      }}
      className="flex items-center justify-between"
      style={{
        width: `calc((100% - 40px)/3)`,
        backgroundColor: Colors.white,
        borderRadius: "8px",
        padding: "20px 30px 20px 20px",
        border: `solid 2px ${
          tabsActive === title ? Colors.bluePrimary : Colors.borderPrimary
        }`,
      }}
    >
      <div className="flex items-center justify-start">
        <div
          style={{
            padding: "24px",
            borderRadius: "8px",
            backgroundColor: backgroundColorMap[title],
            marginRight: "20px",
          }}
        >
          {title && (
            <img style={{ height: "26px" }} src={imageSrcMap[title]} alt="" />
          )}
        </div>
        <div className="flex flex-col items-start">
          <div
            className="capitalize"
            style={{
              fontFamily: "Poppins",
              fontWeight: "600",
              fontSize: "24px",
              color: Colors.fontDark,
              lineHeight: "36px ",
            }}
          >
            {title}
          </div>

          <div
            id={`${title}TabTotalId`}
            style={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: Colors.fontGreyLight,
              lineHeight: "24px",
              marginTop: "4px",
            }}
          >
            Total: {total}
          </div>
        </div>
      </div>
      {newSum && (
        <div
          className="capitalize"
          style={{
            backgroundColor: Colors.redDark,
            padding: "6px 16px",
            borderRadius: "8px",
            color: Colors.white,
            fontFamily: "Poppins",
            fontSize: "14px",
          }}
        >
          {`${newSum} new `}
        </div>
      )}
    </button>
  );
}

export default TabCards;
