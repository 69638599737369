import React from "react";
import Colors from "../assets/Colors";

function SingleAddressForModal({
  index,
  address,
  name,
  bussinessNmae,
  phoneNumber,
  note,
  length,
}) {
  return (
    <div>
      <div style={styles.singleAddress}>
        {index !== length && (
          <div
            style={{
              width: "1px",
              height: "100%",
              backgroundColor: Colors.fontDark,
              position: "absolute",
              top: "0px",
              left: "51px",
            }}
          ></div>
        )}

        <div
          style={{
            ...styles.circle,
            ...{
              backgroundColor:
                index !== 0 ? Colors.greenDark : Colors.yellowDark,
            },
          }}
        ></div>
        <div style={styles.pickupDropoff}>
          {index === 0 ? "Pickup:" : `Dropoff`}
        </div>
        <div style={styles.addresses}>{address}</div>
        <div
          style={{ paddingRight: "61px", marginTop: "20px" }}
          className="flex w-full items-center justify-between"
        >
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            Name
          </div>
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            Bussines Name
          </div>
          <div
            style={{
              ...styles.pickupDropoff,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            Phone Number
          </div>
        </div>
        <div
          style={{ paddingRight: "61px", marginTop: "0px" }}
          className="flex w-full items-center justify-between"
        >
          <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            {name}
          </div>
          <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            {bussinessNmae}
          </div>
          <div
            style={{
              ...styles.addresses,
              ...{ textAlign: "start", width: "33%" },
            }}
          >
            +{phoneNumber}
          </div>
        </div>
        <div style={{ ...styles.pickupDropoff, ...{ marginTop: "20px" } }}>
          Note:
        </div>

        <div
          style={{
            height: "80px",
            width: "90%",
            backgroundColor: Colors.bgGrey,
            overflowY: "scroll",
            borderRadius: "8px",
            padding: "10px",

            marginBottom: "10px",
          }}
        >
          <div style={{ ...styles.addresses, ...{ fontSize: "14px" } }}>
            {note}
          </div>
        </div>
        
      </div>
    </div>
  );
}
const styles = {
  circle: {
    position: "absolute",
    height: "14px",
    width: "14px",
    borderRadius: "100%",
    top: "0px",
    left: "44px",
  },
  pickupDropoff: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: Colors.fontGreyLight,
    fontWeight: "500",
    marginBottom: "2px",
    marginTop: "0px",
  },
  singleAddress: {
    position: "relative",
    minHeight: "33%",
    overflow: "hidden",
    padding: "0px 0px 0px 90px",
  },
  addresses: {
    fontFamily: "Poppins",
    fontSize: "16px",
    color: Colors.fontDark,
    fontWeight: "500",
  },
};
export default SingleAddressForModal;
