import React from "react";
import { useMediaQuery } from "react-responsive";

export const useDesktopOrLaptop = () => {
  const isdesktop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const xsfontsize = isdesktop ? "12px" : "18px";
  const smFontSize = isdesktop ? "14px" : "22px";
  const mdFontSize = isdesktop ? "16px" : "25px";
  const lgfontsize = isdesktop ? "18px" : "29px";
  const xlfontsize = isdesktop ? "20px" : "32px";

  return {
    isdesktop,
    mdFontSize,
    lgfontsize,
    xlfontsize,
    xsfontsize,
    smFontSize,
  };
};
