import React, { useEffect, useMemo } from "react";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import Colors from "../../assets/Colors";
import { useAxios, useConnectAccount } from "../../store";
import dayjs from "dayjs";

const PaymentDetails = () => {
  const { isdesktop, xlfontsize, xsfontsize, smFontSize } =
    useDesktopOrLaptop();
  const { isSandbox } = useAxios();
  const { paymentDetails, getPaymentDetails } = useConnectAccount();

  useEffect(() => {
    const params = {
      test_mode: isSandbox,
      start_date: dayjs().startOf("month").format("YYYY-MM-DD"),
      end_date: dayjs().format("YYYY-MM-DD"),
    };
    getPaymentDetails(params);
  }, [isSandbox]);

  const styles = useMemo(() => {
    return {
      mainContainer: {
        backgroundColor: Colors.white,
      },
      paymentDetailsContainer: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        width: "60%",
        marginTop: "20px",
      },
      amountTitleText: {
        fontWeight: "600",
        fontSize: smFontSize,
      },
      amountValueText: {
        fontWeight: "400",
        fontSize: smFontSize,
      },
      amountInfoText: {
        fontWeight: "400",
        fontSize: xsfontsize,
      },
    };
  }, []);

  return (
    <div style={{ padding: "20px 16px 0px 16px" }} className="w-full">
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: Colors.white,
          borderRadius: "14px",
          padding: "30px 24px",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: xlfontsize,
          }}
        >
          Payment Details
        </div>
        {/* <button
          onClick={() => {
            // history("/v1/settings");
            console.log("view stripe account action");
          }}
          id="view stripe account button"
          type="button"
          style={{
            fontSize: isdesktop ? "12px" : "28px",
            fontFamily: "Poppins",
            fontWeight: "600",
            color: Colors.bluePrimary,
          }}
        >
          View Stripe account
        </button> */}
        <div style={styles.paymentDetailsContainer}>
          <div>
            <div style={styles.amountTitleText}>This Month</div>
            <div style={styles.amountValueText}>
              $
              {paymentDetails.total_amount
                ? (paymentDetails.total_amount / 100).toFixed(2)
                : "0.00"}{" "}
              USD
            </div>
          </div>
          <div>
            <div style={styles.amountTitleText}>Your balance</div>
            <div style={styles.amountValueText}>
              $
              {paymentDetails.payable_amount
                ? (paymentDetails.payable_amount / 100).toFixed(2)
                : "0.00"}{" "}
              USD
            </div>
            <div style={styles.amountInfoText}>
              $
              {paymentDetails.payed_amount
                ? (paymentDetails.payed_amount / 100).toFixed(2)
                : "0.00"}{" "}
              USD transfered
            </div>
          </div>
          {/* <button
            onClick={() => {
              // history("/v1/settings");
              console.log("button action");
            }}
            id="providePaymentBtnId"
            type="button"
            style={{
              fontSize: isdesktop ? "14px" : "28px",
              fontFamily: "Poppins",
              fontWeight: "600",
              display: "flex",
              padding: "10px 50px",
              backgroundColor: "#0062FF",
              borderRadius: "8px",
              color: "white",
              alignItems: "center",
              outline: "none",
            }}
          >
            Pay out now
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
