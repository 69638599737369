import React, { useMemo } from "react";
import vromo from "../../../assets/pngs/vromo.png";
import cartwheel from "../../../assets/pngs/cartwheel.png";
import Colors from "../../../assets/Colors";
import captain from "../../../assets/pngs/captain.png";
import onfleet from "../../../assets/pngs/onfleet.png";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import CommonToggle from "../../CommonToggle";
import { useModal } from "../../../store";
import useAxios from "../../../store/useAxios";
import { toast } from "react-toastify";
import {
  formatedProviderName,
  getIntegrationInitialPayload,
  PROVIDERS_FOR_INTEGRATION,
} from "../utils";

const IntegrationsCard = ({ providerName }) => {
  const { mdFontSize } = useDesktopOrLaptop();
  const { updateIntegation } = useAxios();
  const { setModalItem } = useModal();
  const { integrationList, integrationLoading, setIntegrationLoading } =
    useAxios();

  const providerIntegrationData = useMemo(
    () =>
      integrationList.find(
        (integration) => integration.provider_name === providerName
      ),
    [providerName, integrationList]
  );

  const loadProviderCopy = () => {
    const initialPayload = { ...getIntegrationInitialPayload(providerName) };
    if (providerIntegrationData) {
      for (const key in initialPayload) {
        if (providerIntegrationData.hasOwnProperty(key)) {
          initialPayload[key] = providerIntegrationData[key];
        }
      }
    }
    return initialPayload;
  };

  const toggleHandler = () => {
    const providerInfoCopy = loadProviderCopy();
    providerInfoCopy.provider_enabled = !providerInfoCopy.provider_enabled;
    if (
      providerInfoCopy.provider_enabled &&
      !providerInfoCopy.provider_api_key
    ) {
      setModalItem({ ...providerInfoCopy });
      return;
    }

    const callback = (err, res) => {
      setIntegrationLoading(false);
      if (err) {
        toast.error(err);
        return;
      }
      toast.success(`Integration save successfully`);
    };

    setIntegrationLoading(true);
    updateIntegation(
      { provider_enabled: providerInfoCopy.provider_enabled },
      callback
    );
  };

  const getProviderIcon = () => {
    switch (providerName) {
      case PROVIDERS_FOR_INTEGRATION.CARTWHEEL:
        return cartwheel;
      case PROVIDERS_FOR_INTEGRATION.CAPTAIN_AI:
        return captain;
      case PROVIDERS_FOR_INTEGRATION.ONFLEET:
        return onfleet;
      default:
        return vromo;
    }
  };

  return (
    <div style={{ width: "25%", padding: "10px" }}>
      <div
        style={{
          width: "100%",
          borderRadius: "12px",
          backgroundColor: Colors.white,
        }}
      >
        <div
          style={{ padding: "24px 24px 4px 24px" }}
          className="w-full flex items-center cursor-pointer"
          onClick={(e) => {
            if (
              providerName !== PROVIDERS_FOR_INTEGRATION.CAPTAIN_AI
              //  &&
              // providerName !== PROVIDERS_FOR_INTEGRATION.ONFLEET
            )
              setModalItem(loadProviderCopy());
          }}
        >
          <div
            className="w-full flex items-center"
            style={{
              height: "55px",
              width: "55px",
              borderRadius: "100px",
              overflow: "hidden",
              padding: "8px",
              backgroundColor: Colors.bgGrey,
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
                objectFit: "cover",
                borderRadius: "1000px",
                overflow: "hidden",
              }}
              src={getProviderIcon()}
              alt=""
            />
          </div>
          <div style={{ marginLeft: "20px", fontWeight: "500" }}>
            {formatedProviderName[providerName]}
          </div>
        </div>
        <div
          style={{
            width: `calc(100% - 56px)`,
            margin: "14px 28px",
            height: "1px",
            backgroundColor: Colors.bgGrey,
          }}
        />
        <div
          className="w-full flex items-center justify-between"
          style={{ padding: "4px 24px 24px 24px", fontSize: mdFontSize }}
        >
          <div>Integrate {formatedProviderName[providerName]}</div>
          <CommonToggle
            id="headerToggle"
            smsHandler={toggleHandler}
            checked={providerIntegrationData?.provider_enabled}
            translateX={15}
            isDisabled={
              integrationLoading ||
              providerName === PROVIDERS_FOR_INTEGRATION.CAPTAIN_AI
            }
            circleStyle={{
              width: "16px",
              height: "16px",
              backgroundColor: "white",
            }}
            style={{
              width: "37px",
              height: "20px",
              padding: "3px",
              backgroundColor: providerIntegrationData?.provider_enabled
                ? Colors.green
                : "#979797",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IntegrationsCard;
