import axios from "axios";
import apiUrls from "../../utils/constants/apiUrls";
import { generateQueryParams } from "./utils";

export const getPartnerDeliveries = (params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.PARTNER_DELIVERIES,
    params
  );
  return axios.get(urlWithQueryParams);
};
