import axios from "axios";
import apiUrls from "../../utils/constants/apiUrls";
import { generateQueryParams } from "./utils";
import { apiUrl } from "../../runtimeConsts";

export const deliveryStatusChange = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    apiUrls.DELIVERY_STATUSCHANGE(id),
    params
  );
  return axios.get(urlWithQueryParams);
};

export const putUpdateDelivery = (id, params) =>
  axios.put(`${apiUrl}partner/partner_deliveries/${id}`, params);
