export const generateQueryParams = (url, queryParams) => {
    let urlWithParams = `${url}?`;
    Object.keys(queryParams).forEach((key, index) => {
        const paramsElement = queryParams[key];
        if (paramsElement || paramsElement === false || paramsElement === 0) {
            let param = '';
            if (typeof paramsElement === 'object') {
                paramsElement.forEach((value, index) => {
                    param += _getAdjustedParameter(index, paramsElement, `${key}=${value}`);
                });
            } else {
                param = `${key}=${paramsElement}`;
            }
            urlWithParams += _getAdjustedParameter(index, queryParams, param);
        }
    });

    return urlWithParams;
};

const _getAdjustedParameter = (index, params, value) => {
    return Object.keys(params).length === index + 1 ? value : `${value}&`;
};
