import React from "react";

const PagingWithNextAndPrevButtons = ({
  setPage,
  page,
  result,
  active,
  allPages,
  getData,
}) => {
  const styles = {
    button: {
      borderColor: "#928D8C",
      borderRadius: "4px",
      borderWidth: "1px",
      padding: "4px 12px",
      outline: "none",
      marginLeft: "10px",
      color: "#171725",
      fontFamily: "Poppins",
      fontWeight: "500",
    },
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "10px 0px",
        marginTop: "-16px",
      }}
    >
      <div></div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          id={`pageCountId${page + 1}`}
          style={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "16px",
            marginLeft: "10px",
          }}
        >
          Page: {page + 1}
        </div>
        <button
          id="previousButtonId"
          onClick={() => {
            if (page > 0) {
              setPage(page - 1);
            }
          }}
          style={{ ...styles.button, ...{ color: page === 0 && "#928D8C" } }}
        >
          Previous
        </button>

        <button
          id="nextButtonId"
          onClick={() => {
            if (result > page + 1) {
              setPage(page + 1);
            }
          }}
          style={{
            ...styles.button,
            ...{
              color: result <= page + 1 && "#928D8C",
            },
          }}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default PagingWithNextAndPrevButtons;
