import React, { useCallback, useMemo } from "react";
import { useDesktopOrLaptop } from "../../../../../../services/useDesktopOrLaptop";
import ButtonComp from "../../../../../Reusable/ButtonComp";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ListRow from "./ListRow";
import { cloneDeep } from "lodash";

const RulesList = ({
  rules,
  setFieldValue,
  setActiveStep,
  setSelectedRuleIndex,
}) => {
  const { xlfontsize } = useDesktopOrLaptop();

  const styles = useMemo(() => {
    return {
      rowContainer: {
        display: "flex",
        // justifyContent: "space-between",
        alignItems: "center",
        padding: 10,
        borderRadius: "8px",
        cursor: "pointer",
      },
      header: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 20,
      },
    };
  }, []);

  const moveRule = useCallback((dragIndex, hoverIndex) => {
    let newRules = cloneDeep(rules);
    let dragRule = newRules[dragIndex];
    newRules.splice(dragIndex, 1);
    newRules.splice(hoverIndex, 0, dragRule);
    setFieldValue("rule", newRules);
  }, []);

  const renderListItem = useCallback((rule, index) => {
    return (
      <ListRow
        setActiveStep={setActiveStep}
        setSelectedRuleIndex={setSelectedRuleIndex}
        rule={rule}
        index={index}
        moveRule={moveRule}
      />
    );
  }, []);

  return (
    <div>
      <div style={styles.header}>
        <div
          style={{
            fontWeight: "600",
            fontSize: xlfontsize,
            marginBottom: "15px",
          }}
        >
          Rules
        </div>
        <div>
          <ButtonComp
            primary
            onClick={(e) => {
              setSelectedRuleIndex(null);
              setActiveStep("create_rule");
            }}
            title={"Create rule"}
            extraStyles={{ paddingTop: "6px", paddingBottom: "6px" }}
          />
        </div>
      </div>
      <DndProvider backend={HTML5Backend}>
        {rules.map((rule, index) => renderListItem(rule, index))}
      </DndProvider>
      {!rules.length && <div>Please create a rule.</div>}
    </div>
  );
};

export default RulesList;
