import React, { useState } from "react";
import Colors from "../assets/Colors";
import car from "../assets/pngs/vehicles/car.png";
import truck from "../assets/pngs/vehicles/truck.png";
import suv from "../assets/pngs/vehicles/van.png";
import defaultVehicles from "../assets/pngs/vehicles/devaultVehicle.png";

import dayjs from "dayjs";

import { useAxios, useModal } from "../store";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import { toast } from "react-toastify";
import LoaderComp from "./Reusable/LoaderComp";
import { formatStatusText } from "../utils/helpers";

function JobOneItem({
  type,
  ongoing,
  completed,
  jobs,
  isCanceled,
  canceled,
  delId,
  stops,
  fee,
  item,
  getAcceptOneJob,
  time,
  index,
  page,
}) {
  const {
    setShowJobsModal,
    setShowStartDeliveryModal,
    setIsSeeMoreModalVisible,
    setIsCompletedSeeMoreModalVisible,
    setItemForJobsModal,
    setItemForSeeMoreModal,
    getDeclineJobHandler,
    setItemForStartDeliveryModal,
    setIsCanceledSeeMoreModalVisible,
  } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const { isdesktop } = useDesktopOrLaptop();

  const { getJobsListHandler } = useAxios();

  const vehicleBgColor = {
    car: Colors.carBg,
    truck: Colors.truckBg,
    suv: Colors.vanBg,
  };

  const styles = {
    headerTextStyles: {
      fontFamily: "Poppins",
      fontSize: "16px",
      fontWeight: "600",
      width: "20%",
      textAlign: "center",
    },
    textStyle: {
      fontFamily: "Poppins",
      fontSize: "14px",
      fontWeight: "500",
      // wordBreak: "break-all",
    },
    card: {
      marginTop: "20px",
      border: `solid ${Colors.borderPrimary}`,
      borderWidth: "2px",
      backgroundColor: Colors.white,
      borderRadius: "8px",
      padding: "10px",
    },
    imageBg: {
      height: "50px",
      width: "55px",
      backgroundColor: vehicleBgColor[type] || vehicleBgColor.car,
      borderRadius: "8px",
    },
    button: {
      borderRadius: "8px",
      textAlign: "center",
      padding: "5px 12px",
      fontFamily: "Poppins",
      fontWeight: "600",
      fontSize: isdesktop ? "14px" : "16px",
      color: Colors.white,
      marginRight: "4px",
      width: isdesktop ? "125px" : "90px",
    },
  };

  const accpetOffer = async (item) => {
    setIsLoading(true);
    const callback = (err, res) => {
      setIsLoading(false);
      if (err) {
        toast.error(err);
        return;
      }
      const params = { page };
      getJobsListHandler(params);
      toast.success(`Offer accepted successfully`);
    };
    const payload = {
      // pickup_address: item.pickupAddress,
      // pickup_name: item.pickup_name,
      // pickup_notes: item.pickup_notes,
      // pickup_phone_number: item.pickup_phone_number,
      // pickup_unit: item.pickup_unit,
      // items_description: item.items_description,
      // external_order_ref: item.external_order_ref,
      // dropoff_unit: item.dropoff_unit,
      // dropoff_address: item.pickup_address,
      // dropoff_name: item.dropoff_name,
      // dropoff_phone_number: item.dropoff_phone_number,
      // tip: item.tip,
      // order_value: item.order_value,
      // items: item.items,
      // pickup_at: item.pickup_at,
      // dropoff_at: item.dropoffAt,
      delivery_id: item.id,
    };

    await getAcceptOneJob(payload, callback);
  };

  const cancelOffer = (id) => {
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      toast.success(`Offer decline successfully`);
    };
    getDeclineJobHandler(id, callback);
  };

  const getVehicleType = (type) => {
    const vehicleType = {
      car,
      truck,
      suv,
    };

    return vehicleType[type] ? vehicleType[type] : defaultVehicles;
  };

  return (
    <div
      // className="flex items-center justify-start w-full"
      className="w-full grid grid-cols-12 gap-1"
      style={styles.card}
    >
      <div className="w-full flex items-center col-start-1 col-span-1">
        <div
          className="flex items-center justify-center"
          style={styles.imageBg}
        >
          <img style={{ width: "60%" }} src={getVehicleType(type)} alt="" />
        </div>
        <div
          className="capitalize"
          style={{ ...styles.textStyle, ...{ marginLeft: "20px" } }}
        >
          {type && type.length ? type : "Any"}
        </div>
      </div>
      <div
        className="col-start-2 col-span-2 flex items-center justify-center text-center w-full break-all"
        style={{
          ...styles.textStyle,
        }}
      >
        {dayjs(time).format("DD MMM YYYY, hh:mm a ")}
      </div>
      <div
        className="col-start-4 col-span-2 flex items-center text-center w-full break-all pr-10"
        style={{
          ...styles.textStyle,
        }}
      >
        {item?.pickup_address}
        {/* {item?.pickupAddress} */}
      </div>
      {/* 
      <div
        className="col-start-6 col-span-1 flex items-center justify-center text-center w-full "
        style={{
          ...styles.textStyle,
        }}
      >
        {stops}
      </div> */}
      <div
        className="col-start-6 col-span-2 flex items-center justify-center text-center w-full"
        style={{
          ...styles.textStyle,
        }}
      >
        {item?.pickup_address}
      </div>
      <div
        className="col-start-8 col-span-1 flex items-center justify-center text-center w-full break-all"
        style={{
          ...styles.textStyle,
        }}
      >
        ${fee / 100}
      </div>
      <div
        className="col-start-9 col-span-2 flex items-center justify-center text-center w-full break-all"
        style={{
          ...styles.textStyle,
        }}
      >
        {item?.signature_requested
          ? "Signature"
          : item?.contactless_requested
            ? "Contactless"
            : "No Preference"}
      </div>
      <div
        className={`flex col-start-11 col-span-2 flex items-center text-center w-full  ${
          jobs ? "justify-end  " : "justify-end"
        }`}
      >
        {ongoing && item.status === "driver_not_assigned" ? (
          <>
            <button
              onClick={() => {
                setShowStartDeliveryModal(true);
                setItemForStartDeliveryModal(item);
              }}
              type="button"
              style={{
                ...styles.button,
                ...{
                  backgroundColor: Colors.bluePrimary,
                },
              }}
            >
              {isdesktop ? "Start Delivery" : "Start"}
            </button>
            {/* <button
              onClick={() => cancelOffer(item.id)}
              type="button"
              style={{
                ...styles.button,
                ...{ backgroundColor: Colors.buttonBg, color: Colors.fontDark },
              }}
            >
              Decline
            </button> */}
          </>
        ) : ongoing && item.status !== "driver_not_assigned" ? (
          <>
            {/* <button
              onClick={() => cancelOffer(item.id)}
              type="button"
              style={{
                ...styles.button,
                ...{ backgroundColor: Colors.buttonBg, color: Colors.fontDark },
              }}
            >
              Decline
            </button> */}
            <div
              style={{
                ...styles.button,
                ...{
                  color:
                    item.status === "completed"
                      ? " bg-green-50"
                      : item.status === "pending" ||
                          item.status === "delivery_created" ||
                          item.status === "driver_assigned" ||
                          item.status === "driver_not_assigned"
                        ? "#FF974A"
                        : item.status === "canceled" ||
                            item.status === "provider_canceled" ||
                            item.status == "customer_canceled" ||
                            item.status === "burq_canceled" ||
                            item.status === "expired" ||
                            item.status === "failed" ||
                            item.status === "disputed"
                          ? "#FC5A5A"
                          : item.status === "delivered"
                            ? "#3DD598"
                            : item.status === "pickup" ||
                                item.status === "enroute_pickup" ||
                                item.status === "arrived_at_pickup" ||
                                item.status === "pickup_complete"
                              ? "#50B5FF"
                              : item.status === "dropoff" ||
                                  item.status === "enroute_dropoff" ||
                                  item.status === "arrived_at_dropoff" ||
                                  item.status === "dropoff_complete"
                                ? "#A461D8"
                                : item.status === "Paid order" ||
                                    item.status === "contacting_provider" ||
                                    item.status === "scheduled" ||
                                    item.status === "returned"
                                  ? "#0062FF"
                                  : item.status === "request"
                                    ? "#92929D"
                                    : "#92929D",
                  fontWeight: "500",
                  fontSize: "14px",
                },
              }}
            >
              {formatStatusText[item.status]}
            </div>
          </>
        ) : jobs ? (
          <>
            <button
              onClick={() => accpetOffer(item)}
              type="button"
              disabled={isLoading}
              style={{
                ...styles.button,
                ...{ backgroundColor: Colors.bluePrimary },
              }}
            >
              {isLoading ? (
                <LoaderComp loading={isLoading} color="#FFFFFF" />
              ) : (
                "Accept"
              )}
            </button>
            {/* <button
              onClick={() => cancelOffer(item.id)}
              type="button"
              style={{
                ...styles.button,
                ...{ backgroundColor: Colors.buttonBg, color: Colors.fontDark },
              }}
            >
              Decline
            </button> */}
          </>
        ) : completed ? (
          <div
            style={{
              ...styles.button,
              ...{
                color:
                  item.status === "completed" || item.status === "delivered"
                    ? Colors.greenDark
                    : item.status === "returned"
                      ? Colors.blueLight
                      : Colors.redDark,
                fontWeight: "500",
                fontSize: "14px",
              },
            }}
          >
            {formatStatusText[item.status]}
          </div>
        ) : canceled ? (
          <div
            style={{
              ...styles.button,
              ...{
                color: Colors.redDark,
                fontWeight: "500",
                fontSize: "14px",
              },
            }}
          >
            {formatStatusText[item.status]}
          </div>
        ) : (
          <div
            style={{
              ...styles.button,
              ...{
                color:
                  item.status === "pickup"
                    ? Colors.yellowDark
                    : item.status === "dropoff"
                      ? Colors.purple
                      : item.status === "pending"
                        ? Colors.bluePrimary
                        : Colors.greenDark,
                fontWeight: "500",
                fontSize: "14px",
              },
            }}
          >
            {item.status}
          </div>
        )}

        <button
          id="seeMoreBtn"
          onClick={() => {
            if (jobs) {
              setShowJobsModal(true);
              setItemForJobsModal(item);
            } else if (ongoing) {
              setIsSeeMoreModalVisible(true);
              setItemForSeeMoreModal(item);
            } else if (completed) {
              setIsCompletedSeeMoreModalVisible(true);
              setIsSeeMoreModalVisible(false);
              setItemForSeeMoreModal(item);
            } else if (canceled) {
              setIsCanceledSeeMoreModalVisible(true);
              setItemForSeeMoreModal(item);
            }
          }}
          style={{
            ...styles.button,
            ...{
              color: Colors.bluePrimary,
              fontWeight: "500",
              fontSize: "14px",
            },
          }}
          type="button"
        >
          see more
        </button>
      </div>
    </div>
  );
}

export default JobOneItem;
