import axios from "axios";
import { getIncidentPresignedUrl } from "../../utils/api/uploadProofs";
import { toast } from "react-toastify";

export const getPresignedUrl = async (merchantId, fileName) => {
  const payload = {
    original_file_name: fileName,
  };

  try {
    const response = await getIncidentPresignedUrl(merchantId, payload);
    return response.data;
  } catch (error) {
    return;
  }
};

export const uploadToS3 = async (file, presignedUrl, setProgress) => {
  try {
    const response = await axios.put(presignedUrl, file, {
      headers: {
        "Content-Type": file.type,
        "x-burq-client": "client-dashboard",
      },
      // onUploadProgress: (progressEvent) => {
      //   setProgress((prevProgress) => ({
      //     ...prevProgress,
      //     [file.name]: Math.round((progressEvent.loaded * 100) / progressEvent.total),
      //   }));
      // },
    });
    return response;
  } catch (error) {
    console.log("upload error", error.response);
    // toast.error("Please try again");
  }
};
