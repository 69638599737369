import { createStore } from "aniuta";
import { useState } from "react";

const useModal = createStore({
  name: "useModal",
  Store: () => {
    const [isIntegrationModalVisible, setIsIntegrationModalVisible] =
      useState(false);
    const [modalItem, setModalItem] = useState(null);

    return {
      isIntegrationModalVisible,
      setIsIntegrationModalVisible,
      modalItem,
      setModalItem,
    };
  },
});

export default useModal;
