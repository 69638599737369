import { useState } from "react";
import { createStore } from "aniuta";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import axios from "axios";

import { apiUrl } from "../runtimeConsts";
import { getPartnerDeliveries } from "../utils/api/delivery";
import { deliveryStatusChange } from "../utils/api/deliveries";

const BASE_URL = apiUrl + "partner";

const useAxios = createStore({
  name: "axiosStore",
  Store: () => {
    const [isSandbox, setIsSandbox] = useState(false);

    const [filterArray, setFilterArray] = useState([]);
    const [showJobsModal, setShowJobsModal] = useState(false);
    const [showStartDeliveryModal, setShowStartDeliveryModal] = useState(false);
    const [isSeeMoreModalVisible, setIsSeeMoreModalVisible] = useState(false);
    const [isCompletedSeeMoreModalVisible, setIsCompletedSeeMoreModalVisible] =
      useState(false);
    const [isCanceledSeeMoreModalVisible, setIsCanceledSeeMoreModalVisible] =
      useState(false);
    const [itemForStartDeliveryModal, setItemForStartDeliveryModal] =
      useState();

    // ! not Data
    const [itemForJobsModal, setItemForJobsModal] = useState();
    const [itemForSeeMoreModal, setItemForSeeMoreModal] = useState();

    //! USER DATA

    const [authInfo, setAuthInfo] = useState();
    const [integrationLoading, setIntegrationLoading] = useState(false);

    const [deliverySearchQuery, setDeliverySearchQuery] = useState("");

    const loginAuth = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/auth/me`);
        setAuthInfo(response.data);
      } catch (err) {
        if (err?.response?.status) {
          if (
            err?.response?.status === 401 ||
            err.response.data?.message === "The user does not exist."
          ) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName);
            });
            setTimeout(() => {
              window.location.href = `${BASE_URL}/auth/login`;
            }, 1000);
          } else if (
            err.response.data?.message ===
            "There is already a account with the provided email."
          ) {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName);
            });
            toast.error(err.response.data.message, {
              position: "top-center",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              closeButton: false,
              // progress: undefined,
            });
            setTimeout(() => {
              window.location.href = `${BASE_URL}/auth/logout`;
            }, 1500);
          } else {
            Object.keys(Cookies.get()).forEach((cookieName) => {
              Cookies.remove(cookieName);
            });
            setTimeout(() => {
              window.location.href = `${BASE_URL}/auth/logout`;
            }, 1000);
          }
        }
        return err;
      }
    };

    const uploadPartnerLogo = async (file, callback) => {
      try {
        let formData = new FormData();
        formData.append("file", file);
        const response = await axios.post(
          `${BASE_URL}/auth/uploadLogo`,
          formData,
          {
            headers: {
              "Content-Type": file?.type,
            },
          },
        );
        callback(null, response.data);
      } catch (err) {
        if (err.response) {
          callback(err.response.data.message, null);
        } else {
          callback(err.message, null);
        }
      }
    };

    const updatePartnerInformation = async (payload, callback) => {
      try {
        const response = await axios.put(`${BASE_URL}/auth/users`, payload);
        setAuthInfo(response.data);
        callback(null, response.data);
      } catch (err) {
        if (err.response) {
          callback(err.response.data.message, null);
        } else {
          callback(err.message, null);
        }
      }
    };

    ///////////////////////////////////Partner Delivery Listing APIs/////////////////////////////////////////////////
    //! GET JOBS LIST
    const [isListLoading, setIsListLoading] = useState(false);
    const [jobsListData, setJobsListData] = useState([]);
    // const [jobsListDataHistory, setJobsListDataHistory] = useState([]);
    const [listTotal, setListTotal] = useState();
    const getJobsListHandler = async (otherParams = {}) => {
      setIsListLoading(true);
      try {
        const params = {
          status: "delivery_created,scheduled",
          testMode: isSandbox,
          page: 0,
          limit: 30,
          ...otherParams,
        };
        const { data } = await getPartnerDeliveries(params);
        setJobsListData(data.deliveries);
        // if (data.items) {
        //   setJobsListDataHistory(data.items);
        // }
        setListTotal(data.count);
        setIsListLoading(false);
      } catch (err) {
        setIsListLoading(false);
        return err;
      }
    };

    //! ONGOING DATA
    const [ongoingData, setOngoingData] = useState();
    const [ongoingTotal, setOngoingTotal] = useState();
    // const [isOngoingDeliveriesLoading, setIsOngoingDeliveriesLoading] = useState(false);
    const getOngoingListHandler = async (otherParams = {}) => {
      try {
        const params = {
          status: `driver_not_assigned,driver_assigned,enroute_pickup,arrived_at_pickup,pickup_complete,enroute_dropoff,arrived_at_dropoff,request,paid,unpaid,processing,pending,dropoff_complete,enroute_to_return`,
          testMode: isSandbox,
          page: 0,
          limit: 30,
          ...otherParams,
        };
        const { data } = await getPartnerDeliveries(params);
        setOngoingData(data.deliveries);
        setOngoingTotal(data.count);
      } catch (err) {
        return err;
      }
    };

    //! COMPLITED LIST DATA
    const [completedListData, setCompletedListData] = useState();
    const [completedListToltal, setCompletedListToltal] = useState();
    //  const [isCompletedDeliveriesLoading, setIsCompletedDeliveriesLoading] = useState(false);

    const getCompletedListHandler = async (otherParams = {}) => {
      try {
        const params = {
          status: `delivered,returned`,
          testMode: isSandbox,
          page: 0,
          limit: 30,
          ...otherParams,
        };
        const { data } = await getPartnerDeliveries(params);
        setCompletedListData(data.deliveries);
        setCompletedListToltal(data.count);
      } catch (err) {
        return err;
      }
    };

    //! CANCELED LIST DATA
    const [canceledListData, setCanceledListData] = useState();
    const [canceledListTotal, setCanceledListTotal] = useState();

    const getCanceledListHandler = async (otherParams = {}) => {
      try {
        setIsListLoading(true);
        const params = {
          status: `provider_canceled,customer_canceled,failed`,
          testMode: isSandbox,
          page: 0,
          limit: 30,
          ...otherParams,
        };
        const { data } = await getPartnerDeliveries(params);
        setCanceledListData(data.deliveries);
        setCanceledListTotal(data.count);
        setIsListLoading(false);
      } catch (err) {
        setIsListLoading(false);
        return err;
      }
    };

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    // ! GET ONE DELIVERY BY ID

    const getDeliveryByIdHandler = async (id) => {
      try {
        const response = await axios.get(`${BASE_URL}/delivery/${id}`);
        setItemForSeeMoreModal(response.data.delivery);
        console.log("GET DELIVERY BY ID", response.data);
      } catch (err) {
        return err;
      }
    };

    // ! ACCEPT DATA
    const getAcceptOneJob = async (payload, callback) => {
      try {
        payload.test_mode = isSandbox;
        const response = await axios.get(
          `${BASE_URL}/acceptDelivery/${payload.delivery_id}`,
        );
        callback(null, response.data);
        return response;
      } catch (err) {
        if (err.response) {
          callback(err.response.data.message, null);
        } else {
          callback(err.message, null);
        }
      }
    };

    // ! DECLINE JOB
    const getDeclineJobHandler = async (id, callback) => {
      try {
        const response = await axios.post(`${BASE_URL}/delivery/${id}/decline`);
        callback(null, response.data);
      } catch (err) {
        if (err.response) {
          callback(err.response.data.message, null);
        } else {
          callback(err.message, null);
        }
      }
    };

    // ! GET USER INFORMATIONS BY ID

    const [userInformationById, setUserInformationById] = useState();
    const getUserUnfoHandler = async (id) => {
      try {
        const response = await axios.get(`${BASE_URL}/?userId=${id}`);
        setUserInformationById(response.data);

        console.log("GET USER INFORMATIONS BY ID", response);
      } catch (err) {
        console.log("GET USER INFORMATIONS BY ID ERROR", err);
        return err;
      }
    };

    // ! GET START DELIVERY

    const [startDeliveryData, setStartDeliveryData] = useState();

    const getStartDeliveryHandler = async (id, params) => {
      try {
        const response = await deliveryStatusChange(id, params);
        // const response = await axios.get(
        //   `${BASE_URL}/statusChange/${id}?status=${
        //     status ? status : ""
        //   }&driverName=${driverName ? driverName : ""}&driverPhone=${
        //     driverPhoneNumber ? driverPhoneNumber : ""
        //   }&vehicleType=${vehicleType ? vehicleType : ""}&pickupEta=${
        //     pickupEta ? pickupEta : ""
        //   }&dropoffEta=${dropoffEta ? dropoffEta : ""}`
        // );
        setStartDeliveryData(response.data);

        console.log("GET START  JOB", response.data);
        return true;
      } catch (err) {
        toast.error(err?.response?.data?.message);
        return false;
      }
    };

    // Integration
    const [integrationList, setIntegrationList] = useState([]);

    const getIntegationList = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/auth/integrations`);
        setIntegrationList(response.data);
      } catch (err) {
        return err;
      }
    };

    const saveIntegation = async (payload, callback) => {
      try {
        payload.test_mode = isSandbox;
        const response = await axios.post(
          `${BASE_URL}/auth/integrations`,
          payload,
        );
        callback(null, response.data);
        getIntegationList();
      } catch (err) {
        if (err.response) {
          callback(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Something went wrong: Please refresh the page",
            null,
          );
        } else {
          callback(err.message, null);
        }
      }
    };
    const updateIntegation = async (payload, callback) => {
      try {
        const response = await axios.put(
          `${BASE_URL}/auth/integrations`,
          payload,
        );
        callback(null, response.data);
        getIntegationList();
      } catch (err) {
        if (err.response) {
          callback(
            err?.response?.data?.message
              ? err?.response?.data?.message
              : "Something went wrong: Please refresh the page",
            null,
          );
        } else {
          callback(err.message, null);
        }
      }
    };

    // Zone
    const [zoneList, setZoneList] = useState([]);

    const getZoneList = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/auth/zones`);
        setZoneList(response.data);
      } catch (err) {
        return err;
      }
    };

    const saveZone = async (payload, callback) => {
      try {
        const response = await axios.post(`${BASE_URL}/auth/zones`, payload);
        callback(null, response.data);
        getZoneList();
      } catch (err) {
        if (err.response) {
          callback(err.response.data.message, null);
        } else {
          callback(err.message, null);
        }
      }
    };
    const updateZone = async (zoneId, payload, callback) => {
      try {
        new Error("asdasd");
        const response = await axios.put(
          `${BASE_URL}/auth/zones/${zoneId}`,
          payload,
        );
        callback(null, response.data);
        getZoneList();
      } catch (err) {
        if (err.response) {
          callback(err.response.data.message, null);
        } else {
          callback(err.message, null);
        }
      }
    };

    return {
      isSandbox,
      setIsSandbox,
      filterArray,
      setFilterArray,
      showJobsModal,
      setShowJobsModal,
      showStartDeliveryModal,
      setShowStartDeliveryModal,
      isSeeMoreModalVisible,
      setIsSeeMoreModalVisible,
      isCompletedSeeMoreModalVisible,
      setIsCompletedSeeMoreModalVisible,
      itemForStartDeliveryModal,
      setItemForStartDeliveryModal,
      // ! GET USER INFORMATION BY ID
      getUserUnfoHandler,
      // ! GET JOBS LIST
      getJobsListHandler,
      jobsListData,
      setIsListLoading,
      setJobsListData,
      isListLoading,
      itemForJobsModal,
      setItemForJobsModal,
      itemForSeeMoreModal,
      setItemForSeeMoreModal,
      listTotal,
      // jobsListDataHistory,
      // setJobsListDataHistory,
      //! ONGOING DATA AND GET REQUEST
      ongoingData,
      setOngoingData,
      getOngoingListHandler,
      ongoingTotal,
      getAcceptOneJob,
      getStartDeliveryHandler,
      startDeliveryData,
      //! COMPLITED LIST DATA
      getCompletedListHandler,
      completedListData,
      setCompletedListData,
      completedListToltal,
      getDeliveryByIdHandler,
      //! CANCELED LIST DATA
      getCanceledListHandler,
      canceledListData,
      canceledListTotal,
      isCanceledSeeMoreModalVisible,
      setIsCanceledSeeMoreModalVisible,
      //! user data
      loginAuth,
      authInfo,
      uploadPartnerLogo,
      updatePartnerInformation,
      // ! DECLINE JOB
      getDeclineJobHandler,

      //! Integration
      integrationList,
      getIntegationList,
      saveIntegation,
      updateIntegation,

      //! Zone
      zoneList,
      getZoneList,
      saveZone,
      updateZone,

      // integration loading
      integrationLoading,
      setIntegrationLoading,

      deliverySearchQuery,
      setDeliverySearchQuery,
    };
  },
});

export default useAxios;
