import { useState } from "react";
import { createStore } from "aniuta";
import { toast } from "react-toastify";
import {
  createConnectAccount,
  getConnectedAccountBalance,
} from "../utils/api/connectAccounts";

const useConnectAccount = createStore({
  name: "connectAccount",
  Store: () => {
    const createConnectAccountHandler = async (payload) => {
      try {
        const response = await createConnectAccount(payload);
        return response.data;
      } catch (error) {
        toast.error(
          error?.response ? error?.response?.data?.message : error?.message
        );
      }
    };

    const [paymentDetails, setPaymentDetails] = useState({});

    const getPaymentDetails = async (params) => {
      try {
        const response = await getConnectedAccountBalance(params);
        setPaymentDetails(response.data);
        return response.data;
      } catch (error) {
        toast.error(
          error?.response ? error?.response?.data?.message : error?.message
        );
      }
    };

    return {
      createConnectAccountHandler,
      paymentDetails,
      getPaymentDetails,
    };
  },
});

export default useConnectAccount;
