import React, { useMemo, useState } from "react";
import Colors from "../../../../assets/Colors";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import CommonToggle from "../../../CommonToggle";
import RatesCards from "./RatesCards";
import { Formik, useFormik, useFormikContext } from "formik";
import InputErrorComp from "../../../Reusable/InputErrorComp";
import { toast } from "react-toastify";

const MainZonePage = ({
  setActive,
  handleChange,
  handleSubmit,
  isSubmitting,
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  touched,
  setFieldError,
  setIsEdit,
}) => {
  const { xsfontsize, smFontSize, mdFontSize } = useDesktopOrLaptop();
  const [isChecked, setIsChecked] = useState(false);

  const toggleHandler = (setFieldValue) => {
    setFieldValue("zone_status", !values.zone_status);
  };

  const styles = useMemo(() => {
    return {
      input: {
        fontFamily: "Poppins",
        fontSize: "14px",
        border: "0px",
        padding: "3px 10px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: "100%",
        fontWeight: "400",
      },
      inputLable: {
        fontFamily: "Poppins",
        fontWeight: "500",
        color: Colors.fontDark,
        fontSize: xsfontsize,
        marginTop: "30px",
        marginBottom: "6px",
        marginLeft: "4px",
      },
      inputForEdit: {
        width: "100%",
        borderWidth: "1px",
        border: `solid ${Colors.borderPrimary}`,
        padding: "6px 4px",
        borderRadius: "10px",
        display: "flex",
      },
    };
  }, []);

  return (
    <>
      <div
        style={{
          borderBottomWidth: "1px",
          borderColor: Colors.bgGrey,
          paddingBottom: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div
            style={{
              fontSize: mdFontSize,
              color: Colors.fontDark,
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Zone Details
          </div>
        </div>
        <div style={{ width: "76%" }}>
          <div
            style={{
              fontSize: mdFontSize,
              color: Colors.fontDark,
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Zone Name
          </div>
          <div
            style={{
              ...styles.inputForEdit,
              ...{
                borderColor:
                  errors.zone_name && touched.zone_name && Colors.redDark,
              },
            }}
          >
            <input
              onChange={handleChange}
              value={values.zone_name}
              name="zone_name"
              placeholder="Enter zone name"
              type="text"
              style={styles.input}
            />
          </div>
          {touched.zone_name && <InputErrorComp error={errors.zone_name} />}

          <div
            style={{
              fontSize: smFontSize,
              color: Colors.fontGreyLight,
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            This is for internal use only and will not be visible to your
            customers.
          </div>
          <div
            style={{
              fontSize: mdFontSize,
              color: Colors.fontDark,
              fontWeight: "500",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Zip Codes
          </div>
          <div
            style={{
              ...styles.inputForEdit,
              ...{
                borderColor:
                  errors.zip_code && touched.zip_code && Colors.redDark,
              },
            }}
          >
            <textarea
              onChange={(e) => {
                setFieldValue("zip_code", e.target.value.replace(/\s+/g, ""));
              }}
              value={values.zip_code}
              name="zip_code"
              rows="3"
              placeholder="Enter number zip codes "
              type="text"
              style={styles.input}
            />
          </div>
          {touched.zip_code && <InputErrorComp error={errors.zip_code} />}
          <div
            style={{
              fontSize: smFontSize,
              color: Colors.fontGreyLight,
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            Enter a comma seperated list of zip codes for this zone (eg.
            *****,***** ).
          </div>
          <div
            style={{
              fontSize: mdFontSize,
              color: Colors.fontDark,
              fontWeight: "500",
              marginBottom: "20px",
              marginTop: "20px",
            }}
          >
            Zone Status
          </div>
          <CommonToggle
            id="zone_status"
            smsHandler={() => {
              toggleHandler(setFieldValue);
            }}
            checked={values.zone_status}
            translateX={15}
            circleStyle={{
              width: "16px",
              height: "16px",
              backgroundColor: "white",
            }}
            style={{
              width: "37px",
              height: "20px",
              padding: "3px",
              backgroundColor: values.zone_status ? Colors.green : "#979797",
            }}
          />
        </div>
      </div>

      <div
        style={{
          // borderBottomWidth: "1px",
          // borderColor: Colors.bgGrey,
          paddingBottom: "24px",
          paddingTop: "24px",
        }}
        className="flex items-start w-full"
      >
        <div style={{ width: "34%", padding: "0px 16px 0px 0px " }}>
          <div
            style={{
              fontSize: mdFontSize,
              color: Colors.fontDark,
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Rates
          </div>
        </div>
        <div style={{ width: "76%" }}>
          <RatesCards
            title={"Distence based rates"}
            desc={
              "Rates based on the distance of pick-up and drop-off location(s)"
            }
            onClick={() => {
              setActive("distance");
            }}
            buttonText={"Add price-based rate"}
          />

          {/* <RatesCards
            title={"Vehicle based rates"}
            desc={"Rates based on the weight of your customer’s order"}
            onClick={() => {}}
            buttonText={"Add price-based rate"}
          /> */}
        </div>
      </div>
    </>
  );
};

export default MainZonePage;
