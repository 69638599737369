import React from "react";
import Colors from "../assets/Colors";
import { SearchSvg } from "../assets/svgs/AllSvgs";
import { debounce } from "lodash";

function SearchComp({ setSearchQuery, setPage, page }) {
  const debouncedSearch = React.useRef(
    debounce(async (query) => {
      setSearchQuery(query);
      if(page>0) setPage(0)
    }, 300)
  ).current;

  return (
    // <div
    //   style={{
    //     borderColor: "#E2E2EA",
    //     borderWidth: "1px",
    //     borderRadius: "10px",
    //     background: Colors.white,
    //     width: `calc((100% - 40px)/3)`,
    //     padding: "12px 18px",
    //   }}
    //   className="flex items-center "
    // >
    //   <SearchSvg />
    <input
      onChange={(e) => debouncedSearch(e.target.value)}
      style={{
        fontFamily: "Poppins",
        fontSize: "16px",
        border: "0px",
        padding: "10px 16px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: `calc((100% - 40px)/3)`,
        backgroundColor: "white",
      }}
      type="search"
      className="bg-white  focus:outline-none "
      placeholder="Search"
    />
    // </div>
  );
}

export default SearchComp;
