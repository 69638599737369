import React, { useEffect, useState, useRef, useCallback } from "react";

import { BurgerSvg, BurgerSvgRotated, DropDown } from "../assets/svgs/AllSvgs";
import logo from "../assets/pngs/dark.png";

import { useClickStore, useAxios } from "../store";
import { Link, useHistory } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Colors from "../assets/Colors";
import Cookies from "js-cookie";
import CommonToggle from "./CommonToggle";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import { apiUrl } from "../runtimeConsts";

function Header(props) {
  const { useOnClickOutside } = useClickStore();
  const { authInfo, isSandbox, setIsSandbox } = useAxios();
  const { smFontSize, mdFontSize, isdesktop } = useDesktopOrLaptop();
  const [logoutVisible, setLogoutVisible] = useState(false);

  let history = useHistory();

  const sandboxHandler = () => {
    if (props.setPage) {
      props.setPage(0);
    }
    setIsSandbox(!isSandbox);
  };

  const clickRef = useRef();

  const handler = useCallback(() => {
    setLogoutVisible(false);
  }, []);
  useOnClickOutside(clickRef, handler);

  //   useEffect(() => {}, [nameOrEmail]);
  useEffect(() => {
    // if (!props.isBurgerMenuVisible) {
    //   props.setIsBurgerMenuVisible(true);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isdesktop]);

  return (
    <div
      style={{
        height: isdesktop ? "56px" : "100px",
        paddingLeft: "4px",
        paddingRight: "20px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: Colors.white,
        border: `solid ${Colors.borderPrimary}`,
        borderWidth: "0 0 1px 1px",
        // width: props.isBurgerMenuVisible
        //   ? `calc(100vw - 200px)`
        //   : `calc(100vw - 76px)`,
      }}
      className="trans"
    >
      <div className="flex flex-row items-center">
        {isdesktop && !props.noBurger && (
          <>
            {!props.isBurgerMenuVisible && (
            <button
              id={props.createBurger ? props.createBurger : "burgerId"}
              className="flex flex-row items-center "
              type="button"
              onClick={() => {
                props.setIsBurgerMenuVisible((e) => !e);
              }}
              style={{
                outline: "none",
                fontFamily: "Poppins",
                padding: "0px 20px 0 10px",
                height: "60px",
              }}
            >
              <BurgerSvgRotated />
            </button>
            )}
          </>
        )}
        {/* <div style={{ marginLeft: isdesktop ? "48px" : "0px" }}>
          <Link
            id="homeId"
            to="/"
            onClick={() => {
              props.setSidepanelactive && props.setSidepanelactive("Home");
            }}
          >
            <div
              className="flex items-center justify-center"
              style={{
                width: isdesktop ? "68px" : "130px",
              }}
            >
              <img style={{ width: "100%" }} src={logo} alt="" />
            </div>
          </Link>
        </div> */}
        <div
          style={{
            marginLeft: "90px",
            display: "flex",
            alignItems: "center",
            justifySelf: "start",
          }}
        >
          <div
            style={{
              fontFamily: "Poppins",
              marginRight: "12px",
              color: isSandbox ? "#0062FF" : "#979797",
              fontSize: mdFontSize,
              fontWeight: "500",
            }}
          >
            Test Environment
          </div>
          <CommonToggle
            id="headerToggle"
            smsHandler={sandboxHandler}
            checked={isSandbox}
            translateX={12}
            circleStyle={{
              width: "11px",
              height: "11px",
              backgroundColor: "white",
            }}
            style={{
              width: "30px",
              height: "16px",
              padding: "3px",
              backgroundColor: isSandbox ? "#0062FF" : "#979797",
            }}
          />
        </div>
      </div>

      <div
        style={{ fontFamily: "Poppins", marginRight: "24px" }}
        className="flex items-center "
      >
        <button
          className="flex items-center"
          onClick={() => {
            setLogoutVisible(!logoutVisible);
          }}
        >
          {/* <div
            style={{
              height: "32px",
              width: "32px",
              borderRadius: "100%",
              backgroundColor: "#FAFAFB",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          ></div> */}
          <div
            id="nameOrEmailId"
            style={{
              fontSize: mdFontSize,
              margin: "0px 8px 0 10px",
              fontFamily: "Poppins",
              fontWeight: "500",
            }}
          >
            {authInfo?.email ? authInfo.email : "Not Available"}
          </div>

          <DropDown />
        </button>

        {logoutVisible && (
          <div
            ref={clickRef}
            className=" absolute shadow-md "
            style={{
              minWidth: "80px",
              paddingBottom: "2px",
              zIndex: 110,
              top: isdesktop ? "50px" : "70px",
              right: "38px",
            }}
          >
            <button
              onClick={() => {
                history.push("/settings");
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: smFontSize,
                backgroundColor: Colors.white,
                padding: "14px",
                fontWeight: "500",
                color: Colors.fontDark,
              }}
              className={`w-full hover`}
            >
              Settings
            </button>
            <button
              onClick={() => {
                Object.keys(Cookies.get()).forEach((cookieName) => {
                  Cookies.remove(cookieName);
                });
                window.location.href = `${apiUrl}partner/auth/logout`;
              }}
              style={{
                fontFamily: "Poppins",
                fontSize: smFontSize,
                backgroundColor: Colors.white,
                padding: "14px",
                fontWeight: "500",
                color: Colors.fontDark,
              }}
              className={`w-full hover`}
            >
              Log Out
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Header;
