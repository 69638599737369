import React from "react";
import { useModal } from "../../../../store";
import { useAxios } from "../../../../store";
import { toast } from "react-toastify";
import CustomModal from "../../../Reusable/CustomModal";
import { PROVIDERS_FOR_INTEGRATION } from "../../utils";
import VromoIntegrationModalBody from "./Vromo";
import CartwheelIntegrationModalBody from "./Cartwheel";
import OnfleetIntegrationModalBody from "./Onfleet";

const IntegrationModal = () => {
  const { modalItem, setModalItem } = useModal();

  const { saveIntegation, setIntegrationLoading } = useAxios();

  const onSave = (payload) => {
    setIntegrationLoading(true);
    const callback = (err, res) => {
      setIntegrationLoading(false);
      if (err) {
        toast.error(err);
        return;
      }
      toast.success(`Integration save successfully`);
      setModalItem(null);
    };

    saveIntegation(payload, callback);
  };

  const renderModalBody = () => {
    switch (modalItem?.provider_name) {
      case PROVIDERS_FOR_INTEGRATION.VROMO:
        return <VromoIntegrationModalBody onSave={onSave} />;
      case PROVIDERS_FOR_INTEGRATION.CARTWHEEL:
        return <CartwheelIntegrationModalBody onSave={onSave} />;
      case PROVIDERS_FOR_INTEGRATION.ONFLEET:
        return <OnfleetIntegrationModalBody onSave={onSave} />;

      default:
        return null;
    }
  };

  return (
    <CustomModal
      isOpen={!!modalItem}
      onClose={() => {
        setModalItem(null);
      }}
      width="36%"
    >
      {renderModalBody()}
    </CustomModal>
  );
};

export default IntegrationModal;
