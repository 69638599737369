import React, { useEffect, useMemo, useState } from "react";
import Colors from "../../../../../../assets/Colors";
import { useDesktopOrLaptop } from "../../../../../../services/useDesktopOrLaptop";
import ExpressionForm from "./ExpressionForm";
import {
  equalityOperatorsList,
  initialExpressionPayload,
  initialLogicalOperatorPayload,
  initialPayloadForRule,
  operationsList,
  ruleExpressionFieldsList,
  ruleResultFieldsList,
} from "../utils";
import ButtonComp from "../../../../../Reusable/ButtonComp";
import ResultForm from "./ResultForm";
import { cloneDeep } from "lodash";
import { Formik } from "formik";
import { zoneRulesSchema } from "../../../../../../validations/schema";

const CreateRule = ({
  rules,
  setFieldValue,
  selectedRuleIndex,
  setSelectedRuleIndex,
  setActiveStep,
}) => {
  const [initialValues, setInitialValues] = useState(initialPayloadForRule);

  useEffect(() => {
    const rule = cloneDeep(rules[selectedRuleIndex]);
    if (rule?.condition && rule?.result) {
      setInitialValues(rule);
    }
  }, [rules, selectedRuleIndex]);

  const { xlfontsize } = useDesktopOrLaptop();
  const styles = useMemo(() => {
    return {
      orBlockContainer: {
        webkitBoxShadow: `0 0 5px ${Colors.fontGreyLight}`,
        boxShadow: `0 0 5px ${Colors.fontGreyLight}`,
        borderRadius: "8px",
        padding: "10px 10px 10px 10px",
      },
      dottedConnectorLine: {
        borderLeft: `1px dotted ${Colors.fontBlack}`,
        borderRight: `1px dotted ${Colors.fontBlack}`,
        height: "20px",
        width: "90px",
        marginLeft: "30px",
      },
      logicalOperatorButton: {
        paddingTop: "6px",
        paddingBottom: "6px",
        backgroundColor: Colors.white,
        color: Colors.fontBlack,
        border: `1px dotted ${Colors.fontBlack}`,
      },
      logicalOperatorButtonSelected: {
        backgroundColor: Colors.bluePrimary,
        color: Colors.white,
        border: "none",
      },
    };
  }, []);

  const logicalOperatorClickHandler = (values, setValues, index, value) => {
    const condition = [...values.condition];
    const logicalOperatorPayload = { ...initialLogicalOperatorPayload, value };
    if (index === condition.length) {
      setValues({
        ...values,
        condition: [
          ...condition,
          logicalOperatorPayload,
          cloneDeep(initialExpressionPayload),
        ],
      });
    } else {
      condition[index] = logicalOperatorPayload;
      setValues({
        ...values,
        condition: [...condition],
      });
    }
  };

  const expressionRemoveHandler = (index, values, setValues) => {
    const condition = [...values.condition];
    if (condition.length < 2) return;
    if (condition[index + 1]) {
      condition.splice(index, 2);
    } else {
      condition.splice(index - 1, 2);
    }
    setValues({ ...values, condition });
  };

  const handleSubmit = (values, errors) => {
    let newRules = cloneDeep(rules);
    if (selectedRuleIndex !== null) {
      newRules[selectedRuleIndex] = values;
    } else {
      newRules.push(values);
    }
    setFieldValue("rule", newRules);
    setSelectedRuleIndex(null);
    setActiveStep("list_rules");
  };

  const handleDelete = () => {
    let newRules = cloneDeep(rules);
    if (selectedRuleIndex !== null) {
      newRules.splice(selectedRuleIndex, 1);
      setFieldValue("rule", newRules);
      setSelectedRuleIndex(null);
      setActiveStep("list_rules");
    }
  };

  const renderExpressionSection = (values, setValues, condition, index) => {
    if (condition.type !== "expression") return null;
    const logicalOperator = values.condition[index + 1];
    return (
      <>
        <ExpressionForm
          ruleExpressionFieldsList={ruleExpressionFieldsList}
          operationsList={operationsList}
          equalityOperatorsList={equalityOperatorsList}
          removeHandler={() =>
            expressionRemoveHandler(index, values, setValues)
          }
          index={index}
        />
        <div style={styles.dottedConnectorLine} />
        <ButtonComp
          primary
          onClick={(e) =>
            logicalOperatorClickHandler(values, setValues, index + 1, "OR")
          }
          title={"+or"}
          extraStyles={
            logicalOperator?.value === "OR"
              ? {
                  ...styles.logicalOperatorButton,
                  marginRight: "20px",
                  ...styles.logicalOperatorButtonSelected,
                }
              : {
                  ...styles.logicalOperatorButton,
                  marginRight: "20px",
                }
          }
        />
        <ButtonComp
          primary
          onClick={(e) =>
            logicalOperatorClickHandler(values, setValues, index + 1, "AND")
          }
          title={"+and"}
          extraStyles={
            logicalOperator?.value === "AND"
              ? {
                  ...styles.logicalOperatorButton,
                  ...styles.logicalOperatorButtonSelected,
                }
              : {
                  ...styles.logicalOperatorButton,
                }
          }
        />
        {index < values.condition.length - 1 && (
          <div style={styles.dottedConnectorLine} />
        )}
      </>
    );
  };

  return (
    <Formik
      validationSchema={zoneRulesSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ values, setValues, handleSubmit, errors }) => (
        <div>
          {selectedRuleIndex !== null && (
            <ButtonComp
              primary
              type="submit"
              onClick={() => handleDelete()}
              title={"Delete rule"}
              extraStyles={{
                float: "right",
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
            />
          )}
          <div
            style={{
              fontWeight: "600",
              fontSize: xlfontsize,
              marginBottom: "15px",
            }}
          >
            If
          </div>
          <div style={styles.orBlockContainer}>
            {values?.condition?.map((condition, index) =>
              renderExpressionSection(values, setValues, condition, index)
            )}
          </div>
          <div
            style={{
              fontWeight: "600",
              fontSize: xlfontsize,
              margin: "15px 0",
            }}
          >
            Then
          </div>
          <div style={styles.orBlockContainer}>
            <ResultForm
              ruleExpressionFieldsList={ruleExpressionFieldsList}
              ruleResultFieldsList={ruleResultFieldsList}
              operationsList={operationsList}
            />
          </div>
          <div className="w-full flex items-center justify-end pt-4">
            <ButtonComp
              secondary
              onClick={(e) => {
                setSelectedRuleIndex(null);
                setActiveStep("list_rules");
              }}
              title={"Cancel"}
              extraStyles={{
                paddingTop: "6px",
                paddingBottom: "6px",
                marginRight: "10px",
              }}
            />
            <ButtonComp
              primary
              type="submit"
              onClick={(e) => handleSubmit(e, errors)}
              title={"Save"}
              extraStyles={{ paddingTop: "6px", paddingBottom: "6px" }}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};

export default CreateRule;
