import React, { useMemo } from "react";
import { CgClose } from "react-icons/cg";
import Colors from "../../../../assets/Colors";
import { useDesktopOrLaptop } from "../../../../services/useDesktopOrLaptop";
import CommonToggle from "../../../CommonToggle";
import ButtonComp from "../../../Reusable/ButtonComp";
import { useModal } from "../../../../store";
import { Formik } from "formik";
import { vromoIntegrationSchema } from "../../../../validations/schema";
import InputErrorComp from "../../../Reusable/InputErrorComp";
import burq from "../../../../assets/pngs/BurqMiniLogo.png";
import vromo from "../../../../assets/pngs/vromo.png";

const VromoIntegrationModalBody = ({ onSave }) => {
  const { smFontSize, lgfontsize, xsfontsize } = useDesktopOrLaptop();
  const { modalItem, setModalItem } = useModal();

  const styles = useMemo(() => {
    return {
      input: {
        fontFamily: "Poppins",
        fontSize: "14px",
        border: "0px",
        padding: "3px 10px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: "100%",
        fontWeight: "400",
      },
      inputLable: {
        fontFamily: "Poppins",
        fontWeight: "500",
        color: Colors.fontDark,
        fontSize: xsfontsize,
        marginTop: "30px",
        marginBottom: "6px",
        marginLeft: "4px",
      },
      inputForEdit: {
        width: "100%",
        borderWidth: "1px",
        border: `solid ${Colors.borderPrimary}`,
        padding: "6px 4px",
        borderRadius: "10px",
        display: "flex",
      },
      imagesCont: {
        height: "90px",
        width: "90px",
        borderRadius: "80px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: Colors.bgGrey,
        border: "4px solid white",
      },
      inputErrorCompStyles: {
        position: "absolute",
      },
    };
  }, []);

  return (
    <div
      style={{ padding: "20px", fontFamily: "Poppins" }}
      className="w-full flex flex-col relative"
    >
      <div className="w-full flex justify-end">
        <button
          type="button"
          onClick={() => {
            setModalItem(null);
          }}
        >
          <CgClose size={22} />
        </button>
      </div>
      <div style={{ padding: "10px 24px" }} className="flex w-full flex-col  ">
        <div
          style={{ marginBottom: "20px" }}
          className="w-full flex justify-center items-center"
        >
          <div style={styles.imagesCont}>
            <img
              style={{
                height: "70px",
                width: "70px",
                borderRadius: "50px",
                overflow: "hidden",
              }}
              alt=""
              src={burq}
            />
          </div>
          <div
            style={{
              ...styles.imagesCont,
              ...{ marginLeft: "-20px", zIndex: 200 },
            }}
          >
            <img
              style={{
                height: "70px",
                width: "70px",
                borderRadius: "50px",
                overflow: "hidden",
              }}
              alt=""
              src={vromo}
            />
          </div>
        </div>
        <div
          draggable
          style={{ fontWeight: "600", fontSize: lgfontsize }}
          className="flex justify-center w-ful "
        >
          Accounting tool integration
        </div>
        <div
          style={{
            fontSize: smFontSize,
            color: Colors.fontGreyLight,
            textAlign: "center",
            marginTop: "24px",
          }}
          className="flex w-ful "
        >
          Login with the credentials of your accounting tool to get a more
          accurates view of your company’s finances{" "}
        </div>
        <Formik
          initialValues={modalItem}
          onSubmit={onSave}
          validationSchema={vromoIntegrationSchema}
          enableReinitialize
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            errors,
            setFieldValue,
            setFieldTouched,
            touched,
            setFieldError,
          }) => (
            <>
              <div style={styles.inputLable}>Enter API</div>
              <div>
                <div
                  style={{
                    ...styles.inputForEdit,
                    ...{
                      borderColor: errors.provider_api_key && Colors.redDark,
                    },
                  }}
                >
                  <input
                    onChange={handleChange}
                    placeholder="Enter API keys"
                    name="provider_api_key"
                    value={values.provider_api_key}
                    type="text"
                    style={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={errors.provider_api_key}
                  styleProps={styles.inputErrorCompStyles}
                />
              </div>

              <div style={styles.inputLable}>Enter Group Id</div>
              <div>
                <div
                  style={{
                    ...styles.inputForEdit,
                    ...{
                      borderColor: errors.group_id && Colors.redDark,
                    },
                  }}
                >
                  <input
                    onChange={handleChange}
                    placeholder="Enter group id"
                    name="group_id"
                    value={values.group_id}
                    type="text"
                    style={styles.input}
                  />
                </div>
                <InputErrorComp
                  error={errors.group_id}
                  styleProps={styles.inputErrorCompStyles}
                />
              </div>

              <div
                style={{ marginTop: "20px" }}
                className="flex w-full justify-between"
              >
                <div
                  style={{
                    fontSize: smFontSize,
                    fontFamily: "400",
                    color: Colors.fontGreyLight,
                  }}
                >
                  Enable integration app
                </div>

                <CommonToggle
                  id="headerToggle"
                  smsHandler={() => {
                    setFieldValue("provider_enabled", !values.provider_enabled);
                  }}
                  checked={values.provider_enabled}
                  translateX={15}
                  circleStyle={{
                    width: "16px",
                    height: "16px",
                    backgroundColor: "white",
                  }}
                  style={{
                    width: "37px",
                    height: "20px",
                    padding: "3px",
                    backgroundColor: values.provider_enabled
                      ? Colors.green
                      : "#979797",
                  }}
                />
              </div>

              <ButtonComp
                title={"Continue"}
                primary
                onClick={handleSubmit}
                extraStyles={{ marginTop: "40px" }}
              />
            </>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default VromoIntegrationModalBody;
