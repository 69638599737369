import { createStore } from "aniuta";
import { useState } from "react";

const useConfiguration = createStore({
  name: "useConfiguration",
  Store: () => {
    const [isEdit, setIsEdit] = useState(false);
    const [active, setActive] = useState("main");

    return { isEdit, setIsEdit, active, setActive };
  },
});

export default useConfiguration;
