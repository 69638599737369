import React from "react";
import { Link } from "react-router-dom";
import Colors from "../assets/Colors";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import SidePanelButton from "./SidePanelButton";
import logo from "../assets/pngs/dark.png";
import logoMini from "../assets/pngs/BurqMiniLogo.png";
import { BurgerSvg } from "../assets/svgs/AllSvgs";

function SideBar({
  setSidepanelactive,
  setIsBurgerMenuVisible,
  sidepanelactive,
  isBurgerMenuVisible,
}) {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <div
      style={{
        backgroundColor: Colors.white,
        // width: isBurgerMenuVisible ? "200px" : "76px",
        width: "200px",
        height: "100%",
        borderWidth: "1px",
        zIndex: 10,
        overflow: "auto",
      }}
      className="trans"
    >
      <div
        style={{
          marginLeft: isdesktop
            ? isBurgerMenuVisible
              ? "22px"
              : "18px"
            : "0px",
          marginTop: isBurgerMenuVisible ? "20px" : "10px",
          marginBottom: "26px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
        className="trans"
      >
        <Link
          id="homeId"
          to="/"
          onClick={() => {
            setSidepanelactive("Home");
          }}
        >
          <div
            className="flex items-center justify-center"
            style={
              isBurgerMenuVisible
                ? {
                    width: isdesktop ? "68px" : "130px",
                  }
                : { width: isdesktop ? "40px" : "130px" }
            }
          >
            <img
              style={{ width: "100%", objectFit: "contain" }}
              src={isBurgerMenuVisible ? logo : logoMini}
              alt=""
            />
          </div>
        </Link>
        {isBurgerMenuVisible && (
          <button
            style={{ marginRight: "10px" }}
            type="button"
            onClick={() => {
              setIsBurgerMenuVisible(false);
            }}
          >
            <BurgerSvg />
          </button>
        )}
      </div>

      <SidePanelButton
        id="integrationsId"
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Home"
      />
      {/* <SidePanelButton
        id="integrationsId"
        // isDesktopOrLaptop={isDesktopOrLaptop}
        setSidepanelactive={setSidepanelactive}
        setBurger={setIsBurgerMenuVisible}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
        active="Settings"
      /> */}
    </div>
  );
}

export default SideBar;
