import React, { useCallback, useRef, useState } from "react";
import Colors from "../assets/Colors";
import { DropDown } from "../assets/svgs/AllSvgs";
import { useAxios, useClickStore } from "../store";
import { BiCheckboxChecked, BiCheckbox } from "react-icons/bi";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

function FilterComp({setPage, page}) {
  const { useOnClickOutside } = useClickStore();
  const { filterArray, setFilterArray } = useAxios();
  const { isdesktop } = useDesktopOrLaptop();

  const buttonStyle = {
    fontSize: "14px",
    fontWeight: "400",
    fontFamily: "Poppins",
    textAlign: "start",
    marginRight: "10px",
    paddingLeft: "16px",
    display: "none",
  };
  const labelStyle = {
    fontSize: "14px",
    fontWeight: "500",
    fontFamily: "Poppins",
    textAlign: "start",
    paddingLeft: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "4px 0px",
  };
  const [isCarsDropdownVisible, setIsCarsDropdownVisible] = useState(false);

  const filterArrayHandler = (item) => {
    let _filterArray = [...filterArray];

    if (filterArray.includes(item)) {
      _filterArray = _filterArray.filter((e) => e !== item);
      setFilterArray(_filterArray);
    } else {
      _filterArray.push(item);
      setFilterArray(_filterArray);
    }
    if(page>0) setPage(0);
  };

  const clicklRef = useRef();
  const handler = useCallback(() => {
    setIsCarsDropdownVisible(false);
  }, []);
  useOnClickOutside(clicklRef, handler);
  return (
    <div>
      <button
        onClick={() => {
          setIsCarsDropdownVisible(true);
        }}
        type="button"
        className="flex items-center"
      >
        <div
          style={{
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: "500",
            color: Colors.fontGreyLight,
          }}
        >
          Choose vehicle type:
        </div>
        <div
          className="capitalize"
          style={{
            fontSize: "16px",
            fontFamily: "Poppins",
            fontWeight: "600",
            marginLeft: "6px",
            alignItems: "center",
            display: "flex",
          }}
        >
          {filterArray.length > 0 ? filterArray[0] : "All"}
          <div style={{ marginTop: "5px", marginLeft: "5px" }}>
            <DropDown />
          </div>
        </div>
      </button>
      {isCarsDropdownVisible && (
        <div
          ref={clicklRef}
          style={{
            position: "absolute",
            height: "140px",
            width: "150px",
            backgroundColor: Colors.white,
            borderRadius: "8px",
            right: 0,
            top: "40px",
            zIndex: 100,
          }}
        >
          <label
            style={{
              ...labelStyle,
              ...{ color: !filterArray.includes("car") && "#979797" },
            }}
            htmlFor="carLabel"
          >
            <input
              checked={filterArray.includes("car")}
              onChange={() => {
                filterArrayHandler("car");
              }}
              id="carLabel"
              type="checkBox"
              name="Delivered"
              style={buttonStyle}
            ></input>
            {filterArray.includes("car") ? (
              <BiCheckboxChecked size={isdesktop ? 22 : 28} color="#0062FF" />
            ) : (
              <BiCheckbox size={isdesktop ? 22 : 28} />
            )}
            Car
          </label>
          <label
            style={{
              ...labelStyle,
              ...{ color: !filterArray.includes("suv") && "#979797" },
            }}
            htmlFor="vanLabel"
          >
            <input
              checked={filterArray.includes("suv")}
              onChange={() => {
                filterArrayHandler("suv");
              }}
              id="vanLabel"
              type="checkBox"
              name="suv"
              style={buttonStyle}
            ></input>
            {filterArray.includes("suv") ? (
              <BiCheckboxChecked size={isdesktop ? 22 : 28} color="#0062FF" />
            ) : (
              <BiCheckbox size={isdesktop ? 22 : 28} />
            )}
            Suv
          </label>
          <label
            style={{
              ...labelStyle,
              ...{ color: !filterArray.includes("truck") && "#979797" },
            }}
            htmlFor="truckLabel"
          >
            <input
              checked={filterArray.includes("truck")}
              onChange={() => {
                filterArrayHandler("truck");
              }}
              id="truckLabel"
              type="checkBox"
              name="Delivered"
              style={buttonStyle}
            ></input>
            {filterArray.includes("truck") ? (
              <BiCheckboxChecked size={isdesktop ? 22 : 28} color="#0062FF" />
            ) : (
              <BiCheckbox size={isdesktop ? 22 : 28} />
            )}
            Truck
          </label>
          <button
            onClick={() => {
              setFilterArray([]);
              setIsCarsDropdownVisible(false);
            }}
            type="button"
            style={{
              ...labelStyle,
              ...{ color: "#979797", marginLeft: "30px" },
            }}
          >
            All
          </button>
        </div>
      )}
    </div>
  );
}

export default FilterComp;
