import React from "react";
import { useAxios } from "../../store";
import ConnectAccountCreationFlow from "./ConnectAccountCreationFlow";
import PaymentDetails from "./PaymentDetails";

const PaymentSettings = () => {
  const { authInfo } = useAxios();
  return (
    <div style={{ padding: 20 }}>
      {!authInfo?.stripe_connect_account?.stripe_onboarding_complete ? (
        <ConnectAccountCreationFlow />
      ) : (
        <PaymentDetails />
      )}
    </div>
  );
};

export default PaymentSettings;
