import React, { useState } from "react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { AiFillBank } from "react-icons/ai";
import { FaClipboardCheck, FaRegClipboard } from "react-icons/fa";
import { useAxios, useConnectAccount } from "../../store";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";
import Colors from "../../assets/Colors";
import { stripeReturnUrl } from "../../runtimeConsts";
import stripeLogo from "../../assets/pngs/stripeLogo.png";

const ConnectAccountCreationFlow = () => {
  const { isdesktop } = useDesktopOrLaptop();
  const { authInfo, loginAuth } = useAxios();
  const { createConnectAccountHandler } = useConnectAccount();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [accountLink, setAccountLink] = useState(null);
  const [isStripeLinkCopied, setIsStripeLinkCopied] = useState(false);

  const accountCreationHandler = async () => {
    const stripeConnectReturnUrl = stripeReturnUrl;
    const res = await createConnectAccountHandler({
      return_url: stripeConnectReturnUrl,
      refresh_url: stripeConnectReturnUrl,
    });
    if (res?.stripeOnboardingComplete) {
      toast.success("Thank you! Your onboarding is complete.");
      loginAuth();
    } else if (res?.accountLink) {
      setAccountLink(res.accountLink);
      // window.location.href = res.accountLink;
    }
  };

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        padding: "0px 100px 70px 100px",
        borderRadius: "12px",
        alignItems: "center",
        flexDirection: "column",
        backgroundColor: Colors.white,
      }}
    >
      <div
        style={{
          fontFamily: "Poppins",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "16px",
          color: Colors.fontDark,
        }}
      >
        <div
          style={{
            marginTop: "2vh",
            height: "90px",
            width: "90px",
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: `linear-gradient(45deg, rgba(255,255,255,1) 21%, rgba(222,239,255,1) 63%, rgba(124,192,255,1) 100%)`,
          }}
        >
          <AiFillBank size={60} color={Colors.blueLight} />
        </div>
        <div
          style={{
            fontWeight: "600",
            fontSize: "20px",
            marginBottom: "16px",
            alignSelf: "center",
          }}
        >
          Create a connect account
        </div>
        <div
          style={{
            fontWeight: "300",
            fontSize: "20px",
          }}
        >
          In order to start getting paid,
        </div>
        <div
          style={{
            fontWeight: "300",
            fontSize: "20px",
          }}
        >
          you'll need to create a stripe connect account.
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            border: `1px solid ${Colors.borderSecondary}`,
            borderRadius: 8,
            width: isdesktop ? "500px" : "100%",
            padding: 20,
            marginTop: "20px",
          }}
        >
          <div
            style={{
              fontWeight: "300",
              fontSize: "14px",
              paddingRight: "20px",
            }}
          >
            <p>
              We use Stripe to make sure you get paid on time and to keep your
              personal bank and details secure. Click{" "}
              <strong>Create Account</strong> to set up your payments on Stripe.
            </p>
          </div>
          <div style={{ width: "50%" }}>
            <img
              style={{
                width: "100%",
                objectFit: "cover",
              }}
              src={stripeLogo}
              alt=""
            />
          </div>
        </div>
      </div>

      {!accountLink ? (
        <button
          onClick={() => {
            setIsButtonDisabled(true);
            accountCreationHandler();
          }}
          id="providePaymentBtnId"
          type="button"
          disabled={isButtonDisabled}
          style={{
            fontSize: isdesktop ? "14px" : "28px",
            fontFamily: "Poppins",
            fontWeight: "600",
            display: "flex",
            padding: "10px 50px",
            backgroundColor: isButtonDisabled
              ? Colors.fontGreyLight
              : Colors.bluePrimary,
            borderRadius: "8px",
            marginTop: "50px",
            color: "white",
            alignItems: "center",
            outline: "none",
          }}
        >
          {authInfo.stripe_connect_account.stripe_connected_id
            ? "Complete onboarding"
            : "Create Account"}
        </button>
      ) : (
        <div style={{ marginTop: "50px" }}>
          <div
            style={{
              padding: "8px 20px",
              border: `1px solid ${Colors.borderSecondary}`,
              fontWeight: "300",
              fontSize: "14px",
              display: "inline-block",
              borderRadius: "8px",
              marginRight: "10px",
            }}
          >
            {accountLink}
          </div>
          <CopyToClipboard
            text={accountLink}
            onCopy={() => {
              toast.info("Copied.");
              setIsStripeLinkCopied(true);
            }}
            style={{ display: "inline-block", cursor: "pointer" }}
          >
            {isStripeLinkCopied ? (
              <FaClipboardCheck color={Colors.fontGreyLight} size={22} />
            ) : (
              <FaRegClipboard color={Colors.fontGreyLight} size={22} />
            )}
          </CopyToClipboard>
        </div>
      )}
    </div>
  );
};

export default ConnectAccountCreationFlow;
