export const formatStatusText = {
  returned: "Returned",
  delivered: "Delivered",
  delivery_created: "Delivery Created",
  driver_assigned: "Driver Assigned",
  driver_not_assigned: "Assigning Driver",
  enroute_pickup: "Enroute to Pick-up",
  arrived_at_pickup: "Arrived at Pick-up",
  pickup_complete: "Pick-up Complete",
  enroute_dropoff: "Enroute to Drop-off",
  arrived_at_dropoff: "Arrived at Drop-off",
  dropoff_complete: "Drop off Completed",
  contacting_provider: "Contacting Provider",
  scheduled: "Scheduled",
  enroute_to_return: "Enroute to Return",
  provider_canceled: "Provider Canceled",
  customer_canceled: "Merchant Canceled",
  burq_canceled: "Burq Canceled",
  expired: "Expired",
  failed: "Failed",
  disputed: "Disputed",
  request: "Not Initiated",
  paid: "Paid",
  unpaid: "Unpaid",
  refunding: "Refunding",
  refunded: "Refunded",
  processing: "Processing",
  pending: "Pending",
  pickup: "Pick-up",
};

export const TABS = {
  JOBS: "jobs",
  ONGOING: "ongoing",
  COMPLETED: "completed",
  CANCELED: "canceled",
};

export const activeTabStatuses = {
  jobs: "delivery_created",
  ongoing:
    "driver_not_assigned,driver_assigned,enroute_pickup,arrived_at_pickup,pickup_complete,enroute_dropoff,arrived_at_dropoff,request,scheduled,paid,unpaid,processing,pending,dropoff_complete,enroute_to_return",
  completed: "delivered",
};
