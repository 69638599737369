import React, { useEffect, useState } from "react";
import Header from "../components/Header";
// import { useHistory } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useAxios } from "../store";
import Colors from "../assets/Colors";
import SideBar from "../components/SideBar";
import HomePage from "./pages/HomePage";
import Modal from "../components/Modal";
import JobsModal from "../components/JobsModal";
import StartDeliveryModal from "../components/StartDeliveryModal";
import SeeMoreModal from "../components/SeeMoreModal";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

function MainScreen() {
  // let history = useHistory();
  const { isdesktop } = useDesktopOrLaptop();

  const {
    showJobsModal,
    setShowJobsModal,
    showStartDeliveryModal,
    setShowStartDeliveryModal,
    isSeeMoreModalVisible,
    setIsSeeMoreModalVisible,
    isCompletedSeeMoreModalVisible,
    setIsCompletedSeeMoreModalVisible,
    isCanceledSeeMoreModalVisible,
    setIsCanceledSeeMoreModalVisible,
    itemForJobsModal,
    loginAuth,
    itemForSeeMoreModal,
    setItemForSeeMoreModal,
    authInfo,
    setItemForJobsModal,
  } = useAxios();
  const [page, setPage] = useState(0);

  const [sidepanelactive, setSidepanelactive] = useState("Home");
  const [tabsActive, setTabsActive] = useState("jobs");

  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(true);
  useEffect(() => {
    loginAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authInfo) return null;
  return (
    <div
      style={{
        backgroundColor: Colors.bgGrey,
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
      className="flex flex-row relative"
    >
      <Modal show={showJobsModal}>
        {/* <JobsModal
          page={page}
          itemForJobsModal={itemForJobsModal}
          show={showJobsModal}
          setShow={setShowJobsModal}
        /> */}
        <SeeMoreModal
          page={page}
          itemForSeeMoreModal={itemForJobsModal}
          setItemForSeeMoreModal={setItemForJobsModal}
          completed={false}
          show={showJobsModal}
          setShow={setShowJobsModal}
          isJobs
        />
      </Modal>
      <Modal show={showStartDeliveryModal}>
        <StartDeliveryModal
          show={showStartDeliveryModal}
          setShow={setShowStartDeliveryModal}
        />
      </Modal>
      <Modal show={isSeeMoreModalVisible}>
        <SeeMoreModal
          page={page}
          itemForSeeMoreModal={itemForSeeMoreModal}
          setItemForSeeMoreModal={setItemForSeeMoreModal}
          completed={false}
          show={isSeeMoreModalVisible}
          setShow={setIsSeeMoreModalVisible}
        />
      </Modal>
      <Modal show={isCompletedSeeMoreModalVisible}>
        <SeeMoreModal
          page={page}
          itemForSeeMoreModal={itemForSeeMoreModal}
          setItemForSeeMoreModal={setItemForSeeMoreModal}
          completed
          show={isCompletedSeeMoreModalVisible}
          setShow={setIsCompletedSeeMoreModalVisible}
        />
      </Modal>
      <Modal show={isCanceledSeeMoreModalVisible}>
        <SeeMoreModal
          page={page}
          itemForSeeMoreModal={itemForSeeMoreModal}
          setItemForSeeMoreModal={setItemForSeeMoreModal}
          canceled
          show={isCanceledSeeMoreModalVisible}
          setShow={setIsCanceledSeeMoreModalVisible}
        />
      </Modal>

      <SideBar
        setIsBurgerMenuVisible={setIsBurgerMenuVisible}
        setSidepanelactive={setSidepanelactive}
        sidepanelactive={sidepanelactive}
        isBurgerMenuVisible={isBurgerMenuVisible}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: `100vh`,
          // height: `calc(100vh - 56px)`,
        }}
      >
        <Header
          page={page}
          setPage={setPage}
          setSidepanelactive={setSidepanelactive}
          setIsBurgerMenuVisible={setIsBurgerMenuVisible}
          isBurgerMenuVisible={isBurgerMenuVisible}
          sidepanelactive={sidepanelactive}
        />
        <div
          style={{
            width: isBurgerMenuVisible
              ? `calc(100vw - 200px)`
              : `calc(100vw - 76px)`,
            overflowY: "scroll",
            height: `calc(100vh - 56px)`,
          }}
          className="trans"
        >
          {sidepanelactive === "Home" && (
            <HomePage
              page={page}
              setPage={setPage}
              tabsActive={tabsActive}
              setTabsActive={setTabsActive}
              isDesktopOrLaptop={isdesktop}
              setBurger={setIsBurgerMenuVisible}
              burger={isBurgerMenuVisible}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default MainScreen;
