const Colors = {
  fontDark: "#3C4257",
  bluePrimary: "#0062FF",
  fontGreyLight: "#92929D",
  bgGrey: "#F2F2F2",
  white: "#ffffff",
  borderPrimary: "#F2F2F7",
  blueLight: "#62B6FF",
  redDark: "#FF3F3F",
  yellowDark: "#FF974A",
  greenDark: "#3DD598",
  green: "#50A63E",
  carBg: "#FDF5EE",
  vanBg: "#EFFBF5",
  truckBg: "#FDFBF1",
  buttonBg: "#FAFAFB",
  purple: "#7D8AD5",
  accentBlue: "#96B5E8",
  sidePanelBgColor: "#F7F9FB",
  bottomLines: "#ffffff",
  bgDarkSecondary: "#ffffff",
  borderSecondary: "#E2E2EA",
  bgListHeader: "#FAFAFB",
  asapContainer: "#EFFBF5",
  createFirstColors: "#D2E9FF",
  createFirstC: "#f0f8ff",
  detailsBg: "#F7FAFC",
  fontBlack: "#000000",
  whiteBlue: "#EFF4F8",
  whitestBlue: "#F7FAFC",
  driverBoxesBg: "#ffffff",
  bgDots: "#ECEEF0",
  bgWorkflow: "#FBFBFB",
  bgSettings: "#F5F5F5",
  textColor:"#CDCDCD",
  inputFieldBorder: "#E5E7EB"
};

export default Colors;
