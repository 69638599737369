import dayjs from "dayjs";
import React, { useState } from "react";
// import { CgClose } from "react-icons/cg";
import Colors from "../assets/Colors";

function DriverInfoForModal({
  completed,
  item,
  getStartDeliveryHandler,
  getDeliveryByIdHandler,
}) {
  const [isEditDriverInfoVisible, setIsEditDriverInfoVisible] = useState(false);
  const [driverName, setDriverName] = useState("");
  const [driverPhone, setDriverPhone] = useState();
  const [vehicleType, setVehicleType] = useState("");
  const [pickupTimeETA, setPickupTimeETA] = useState("");
  const [pickupDateEta, setPickupDateEta] = useState("");
  const [dropoffTimeETA, setDropoffTimeETA] = useState("");
  const [dropoffDateEta, setDropoffDateEta] = useState("");
  return (
    <div>
      <div
        style={{
          border: `solid ${Colors.borderPrimary}`,
          borderWidth: "0px 0px 1px 0px",
          paddingBottom: "20px",
        }}
        className="flex justify-between items-center"
      >
        <div>
          <div
            style={{
              ...styles.categoryText,
              ...{ marginBottom: "10px", color: Colors.fontGreyLight },
            }}
          >
            Name
          </div>
          <div
            style={{
              ...styles.categoryText,
              ...{ color: Colors.fontDark },
            }}
          >
            {item?.courier_name ? item.courier_name : "Not Available"}
          </div>
        </div>
        <div>
          <div
            style={{
              ...styles.categoryText,
              ...{ marginBottom: "10px", color: Colors.fontGreyLight },
            }}
          >
            Phone Number
          </div>
          <div
            style={{
              ...styles.categoryText,
              ...{ color: Colors.fontDark },
            }}
          >
            {item?.courier_phone_number
              ? "+" + item.courier_phone_number
              : "Not Available"}
          </div>
        </div>
        <div>
          {!completed && (
            <button
              onClick={() => {
                setIsEditDriverInfoVisible(!isEditDriverInfoVisible);
              }}
              type="button"
              style={{
                ...styles.categoryText,
                ...{ color: Colors.bluePrimary },
              }}
            >
              Edit
            </button>
          )}
        </div>
      </div>
      {isEditDriverInfoVisible && (
        <div
          className="kata flex flex-col items-center"
          style={{
            width: "100%",
            overflowY: "scroll",
            justifyContent: "flex-start",
            margin: "0px 0px 100px 0px",
          }}
        >
          <div style={{ width: "76%" }}>
            <div style={styles.inputLable}>Name</div>
            <div style={styles.inputForEdit}>
              <input
                onChange={(phone) => {
                  setDriverName(phone.target.value);
                }}
                placeholder="Type here"
                type="text"
                style={styles.input}
              />
            </div>

            <div style={styles.inputLable}>Phone Number</div>
            <div style={styles.inputForEdit}>
              <input
                onChange={(phone) => {
                  setDriverPhone(phone.target.value);
                }}
                placeholder="Type here"
                type="text"
                style={styles.input}
              />
            </div>
            <div style={styles.inputLable}>Vehicle</div>
            <div style={styles.inputForEdit}>
              <input
                onChange={(phone) => {
                  setVehicleType(phone.target.value);
                }}
                placeholder="Type here"
                type="text"
                style={styles.input}
              />
            </div>
            <div style={styles.inputLable}>Pickup ETA</div>
            <div style={styles.inputForEdit}>
              <input
                onChange={(pickupEta) => {
                  setPickupDateEta(pickupEta.target.value);
                }}
                value={pickupDateEta}
                placeholder="Type here"
                type="date"
                style={styles.input}
              />
              <input
                onChange={(pickupEta) => {
                  setPickupTimeETA(pickupEta.target.value);
                }}
                value={pickupTimeETA}
                placeholder="Type here"
                type="time"
                style={styles.input}
              />
            </div>
            <div style={styles.inputLable}>Dropoff ETA</div>
            <div style={styles.inputForEdit}>
              <input
                onChange={(dropoff) => {
                  setDropoffDateEta(dropoff.target.value);
                  console.log("dropoff DATE ETA", dropoff.target.value);
                }}
                value={dropoffDateEta}
                placeholder="Type here"
                type="date"
                style={styles.input}
              />
              <input
                onChange={(dropoff) => {
                  setDropoffTimeETA(dropoff.target.value);
                  console.log("dropoff Time ETA", dropoff.target.value);
                }}
                value={dropoffTimeETA}
                placeholder="Type here"
                type="time"
                style={styles.input}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              width: "76%",
              marginTop: "20px",
            }}
          >
            <button
              onClick={async () => {
                const dropoffEta =
                  dropoffDateEta &&
                  dropoffTimeETA &&
                  dayjs(dropoffDateEta + dropoffTimeETA).format();
                const pickupEta =
                  pickupDateEta &&
                  pickupTimeETA &&
                  dayjs(pickupDateEta + pickupTimeETA).format();

                const params = {
                  status: item.status,
                  driverName: driverName ?? "",
                  driverPhone: driverPhone ?? "",
                  vehicleType: vehicleType ?? "",
                  pickupEta: pickupEta ?? "",
                  dropoffEta: dropoffEta ?? "",
                };
                await getStartDeliveryHandler(item.id, params);
                getDeliveryByIdHandler(item.id);
                setIsEditDriverInfoVisible(false);
              }}
              style={{
                ...styles.button,
                ...{ backgroundColor: Colors.bluePrimary, marginTop: "10px" },
              }}
              type="button"
            >
              Change Driver
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
const styles = {
  categoryText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
  },

  button: {
    borderRadius: "8px",
    textAlign: "center",
    padding: "7px 20px",
    fontFamily: "Poppins",
    fontWeight: "600",
    fontSize: "16px",
    color: Colors.white,
  },
  input: {
    fontFamily: "Poppins",
    fontSize: "14px",
    border: "0px",
    padding: "3px 10px",
    borderRadius: "8px",
    outlineWidth: "0px",
    width: "100%",
    fontWeight: "500",
  },
  inputLable: {
    fontFamily: "Poppins",
    fontWeight: "500",
    color: Colors.fontGreyLight,
    fontSize: "16px",
    marginTop: "30px",
    marginBottom: "10px",
  },
  inputForEdit: {
    width: "100%",
    borderWidth: "1px",
    border: `solid ${Colors.borderPrimary}`,
    padding: "6px 4px",
    borderRadius: "10px",
    display: "flex",
  },
};

export default DriverInfoForModal;
