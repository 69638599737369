import React from "react";
import { DashboardSvg, Settings } from "../assets/svgs/AllSvgs";
// import { CgArrowsExchange } from "react-icons/cg";
// import { useHistory } from "react-router-dom";
// import { useAxios } from "../store";
import Colors from "../assets/Colors";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";

function SidePanelButton({
  setSidepanelactive,
  setBurger,
  sidepanelactive,
  active,
  createPage,
  id,
  isBurgerMenuVisible,
}) {
  // let history = useHistory();
  const { mdFontSize, isdesktop } = useDesktopOrLaptop();

  const isActive = sidepanelactive === active;

  return (
    <button
      id={id}
      onClick={() => {
        setSidepanelactive(active);

        if (!isdesktop) {
          setBurger(false);
        }
      }}
      style={{
        borderColor: isActive ? Colors.bluePrimary : Colors.white,
        borderLeftWidth: "4px",
        marginBottom: "10px",
      }}
      className={` flex w-full items-center justify-start hover:border-blue-600  mb-5 focus:outline-none`}
    >
      <div
        style={{
          paddingBottom: "10px",
          paddingTop: "10px",
          marginLeft: "14px",
        }}
        className="w-full flex items-center "
      >
        {active === "Home" ? (
          <DashboardSvg
            isDesktopOrLaptop={isdesktop}
            sidepanelactive={sidepanelactive}
          />
        ) : (
          active === "Settings" && (
            <Settings
              isDesktopOrLaptop={isdesktop}
              sidepanelactive={sidepanelactive}
            />
          )
        )}
        {isBurgerMenuVisible && (
          <div
            style={{
              fontSize: mdFontSize,
              color:
                sidepanelactive === active
                  ? Colors.bluePrimary
                  : Colors.fontDark,
              fontFamily: "Poppins",
              fontWeight: "600",
              marginLeft: "18px",
            }}
            className="trans capitalize"
          >
            {active}
          </div>
        )}
      </div>
    </button>
  );
}

export default SidePanelButton;
