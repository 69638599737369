import React, { useEffect } from "react";
import FilterComp from "./FilterComp";
import JobOneItem from "./JobOneItem";
import SearchComp from "./SearchComp";
import { useAxios } from "../store";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import LoaderComp from "./Reusable/LoaderComp";
import { Loading } from "../assets/svgs/AllSvgs";

function JobsTabPage({ jobs, ongoing, completed, canceled, page }) {
  const {
    jobsListData,
    ongoingData,
    getAcceptOneJob,
    completedListData,
    isListLoading,
    canceledListData,
    setJobsListData,
    // jobsListDataHistory,
    filterArray,
    setFilterArray,
    isSandbox,
  } = useAxios();

  // useEffect(() => {
  //   if (!filterArray.length) {
  //     setJobsListData(jobsListDataHistory);
  //   } else {
  //     const jobItems = jobsListDataHistory.filter((item) =>
  //       filterArray.includes(item.vehicle)
  //     );
  //     setJobsListData(jobItems);
  //   }
  // }, [filterArray]);

  // useEffect(() => {
  //   setFilterArray([]);
  // }, [isSandbox, jobs, ongoing, completed]);

  // console.log("12312312312", jobsListData);

  return (
    <div
      style={{
        marginTop: "20px",
        height: "100%",
      }}
    >
      <div
        className="w-full grid grid-cols-12 gap-1 py-4 pl-4 mt-5"
        style={{
          marginTop: "20px",
        }}
      >
        <div className="col-start-1 col-span-1" style={styles.headerTextStyles}>
          Vehicle Type
        </div>
        <div
          className="col-start-2 col-span-2"
          style={{ ...styles.headerTextStyles }}
        >
          Time
        </div>
        <div
          className="col-start-4 col-span-2 pr-10"
          style={styles.headerTextStyles}
        >
          Pickup Address
        </div>
        {/* <div
          className="col-start-6 col-span-1"
          style={{ ...styles.headerTextStyles }}
        >
          Stops
        </div> */}
        <div
          className="col-start-6 col-span-2"
          style={{ ...styles.headerTextStyles }}
        >
          Dropoff Address
        </div>
        <div
          className="col-start-8 col-span-1"
          style={{ ...styles.headerTextStyles }}
        >
          Price
        </div>
        <div
          className="col-start-9 col-span-2"
          style={{ ...styles.headerTextStyles }}
        >
          Delivery Preference
        </div>
        <div
          className="col-start-11 col-span-2"
          style={{ ...styles.headerTextStyles }}
        >
          {jobs ? "Action" : ongoing ? "Action/Status" : completed && "Status"}
        </div>
      </div>

      <LoaderComp loading={isListLoading} size={14} />

      {jobs && isListLoading == false
        ? jobsListData?.map((item, index) => {
            return (
              <JobOneItem
                id={`jobs${index}Id`}
                time={item.created_at}
                key={index}
                ongoing={ongoing}
                completed={completed}
                jobs={jobs}
                type={item.vehicle}
                isCanceled
                delId={item.id}
                stops={1}
                fee={item.fee}
                item={item}
                getAcceptOneJob={getAcceptOneJob}
                index={index}
                page={page}
              />
            );
          })
        : ongoing
          ? ongoingData?.map((item, index) => {
              return (
                <JobOneItem
                  id={`ongoing${index}Id`}
                  time={item.created_at}
                  key={index}
                  ongoing={ongoing}
                  completed={completed}
                  jobs={jobs}
                  type={item.vehicle}
                  isCanceled
                  delId={item.id}
                  stops={1}
                  fee={item.fee}
                  item={item}
                />
              );
            })
          : completed
            ? completedListData?.map((item, index) => {
                return (
                  <JobOneItem
                    id={`completed${index}Id`}
                    time={item.created_at}
                    key={index}
                    ongoing={ongoing}
                    completed={completed}
                    jobs={jobs}
                    type={item.vehicle}
                    isCanceled
                    delId={item.id}
                    stops={1}
                    fee={item.fee}
                    item={item}
                  />
                );
              })
            : canceled && isListLoading === false
              ? canceledListData?.map((item, index) => {
                  return (
                    <JobOneItem
                      id={`canceled${index}Id`}
                      time={item.created_at}
                      key={index}
                      canceled={canceled}
                      type={item.vehicle}
                      delId={item.id}
                      stops={1}
                      fee={item.fee}
                      item={item}
                    />
                  );
                })
              : null}
    </div>
  );
}
const styles = {
  headerTextStyles: {
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
  },
};

export default JobsTabPage;
