import { Fragment, useState } from "react";
import styles from "./styles.module.css";
import { HiCheck } from "react-icons/hi";
import OutsideClickHandler from "react-outside-click-handler";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";

const IANATIMEZONES = [
  { front: "UTC", back: "UTC" },
  { front: "American Samoa", back: "Pacific/Pago_Pago" },
  { front: "Hawaii", back: "Pacific/Honolulu" },
  { front: "Alaska", back: "America/Juneau" },
  { front: "Pacific Time (US & Canada)", back: "America/Los_Angeles" },
  { front: "Tijuana", back: "America/Tijuana" },
  { front: "Mountain Time (US & Canada)", back: "America/Denver" },
  { front: "Arizona", back: "America/Phoenix" },
  { front: "Central Time (US & Canada)", back: "America/Chicago" },
  { front: "Eastern Time (US & Canada)", back: "America/New_York" },
  { front: "Indiana (East)", back: "America/Indiana/Indianapolis" },
  { front: "Atlantic Time (Canada)", back: "America/Halifax" },
  { front: "Puerto Rico", back: "America/Puerto_Rico" },
  { front: "Venezuela Time", back: "America/Caracas" },
  { front: "Newfoundland Standard Time", back: "America/St_Johns" },
  { front: "Argentine Time", back: "America/Buenos_Aires" },
  { front: "Fernando de Noronha Time", back: "America/Noronha" },
  { front: "Alaska Standard Time", back: "America/Anchorage" },
];

// const IANATIMEZONES = {
// utc: "UTC",
// utcPlus1: "UTC+1",
// utcPlus2: "UTC+2",
// utcPlus3: "UTC+3",
// utcPlus4: "UTC+4",
// utcMinus1: "UTC-1",
// utcMinus2: "UTC-2",
// utcMinus3: "UTC-3",
// utcMinus4: "UTC-4",
// Pacific_Pago_Pago: "American Samoa",
// Pacific_Honolulu: "Hawaii",
// America_Juneau: "Alaska",
// America_Los_Angeles: "Pacific Time (US & Canada)",
// America_Tijuana: "Alaska",
// { front: "American Samoa", back: "Pacific/Pago_Pago" },
// { front: "Hawaii", back: "Pacific/Honolulu" },
// { front: "Alaska", back: "America/Juneau" },
// { front: "Pacific Time (US & Canada)", back: "America/Los_Angeles" },
// { front: "Tijuana", back: "America/Tijuana" },
// { front: "Mountain Time (US & Canada)", back: "America/Denver" },
// { front: "Arizona", back: "America/Phoenix" },
// { front: "Central Time (US & Canada)", back: "America/Chicago" },
// { front: "Eastern Time (US & Canada)", back: "America/New_York" },
// { front: "Indiana (East)", back: "America/Indiana/Indianapolis" },
// { front: "Atlantic Time (Canada)", back: "America/Halifax" },
// { front: "Puerto Rico", back: "America/Puerto_Rico" },
// };

const people = [
  { name: "Wade Cooper" },
  { name: "Arlene Mccoy" },
  { name: "Devon Webb" },
  { name: "Tom Cook" },
  { name: "Tanya Fox" },
  { name: "Hellen Schmidt" },
];

export default function TimeZones({ timeZone, setFieldValue }) {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const findTimezone = (searchString) => {
    return IANATIMEZONES.find((timezone) =>
      timezone.back.includes(searchString)
    );
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsDropdownVisible(false);
      }}
    >
      <div className="relative">
        <div className={styles.businessHrsText}>Business Hours</div>
        <div
          onClick={() => {
            setIsDropdownVisible((prev) => !prev);
          }}
          className={styles.timeZoneInput}
        >
          <div className="pl-2">
            <div className={styles.timeZoneTitle}>Time Zone</div>
            <div className={styles.timezoneValue}>
              {findTimezone(timeZone)?.front ?? "UTC"}
            </div>
          </div>
          <div>
            {isDropdownVisible ? (
              <MdOutlineKeyboardArrowUp color="#c6c6c6" size={28} />
            ) : (
              <MdOutlineKeyboardArrowDown color="#c6c6c6" size={28} />
            )}
          </div>

          {isDropdownVisible && (
            <div className={styles.dropdownCont}>
              {IANATIMEZONES.map((time) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setFieldValue("timezone", time.back);
                      setIsDropdownVisible(false);
                    }}
                    key={time.back}
                    className={styles.oneTimeZoneCont}
                  >
                    <div>{time.front}</div>
                    <div>{time.back === timeZone && <HiCheck size={20} />}</div>
                  </div>
                );
              })}
              {/* {Object.keys(IANATIMEZONES).map((time) => {
                return (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setFieldValue("timezone", time);
                      setisDropdownVisible(false);
                    }}
                    key={time}
                    className={styles.oneTimeZoneCont}
                  >
                    <div>{IANATIMEZONES[time]}</div>
                    <div>{time === timeZone && <HiCheck size={20} />}</div>
                  </div>
                );
              })} */}
            </div>
          )}
        </div>
      </div>
    </OutsideClickHandler>
  );
}
