import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

const TimeInput = ({
  label,
  onChange,
  extraContainerClasses,
  type,
  placeholder,
  name,
  value,
  onClear,
}) => {
  const [isActive, setIsActive] = useState(false);

  const changeHandlerInside = (e) => {
    if (e.target.value !== "") {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    onChange(e);
  };

  return (
    <div
      className={classNames(styles.container, extraContainerClasses)}
      id="float-label"
    >
      <input
        color="#2f1818"
        name={name}
        onChange={(e) => {
          changeHandlerInside(e);
        }}
        value={value}
        className={classNames(styles.input, {
          [styles.activeInput]: isActive || value,
        })}
        placeholder={placeholder}
        type={type || "text"}
      />

      <label
        className={classNames(styles.label, {
          [styles.active]: isActive || value,
        })}
        htmlFor="time"
      >
        {label}
      </label>

      {onClear && value && (
        <div
          onClick={onClear}
          className={classNames(styles.clearButton, {
            [styles.active]: true,
          })}
          htmlFor="time"
        >
          clear
        </div>
      )}
    </div>
  );
};

export default TimeInput;
