import React, { useMemo } from "react";
import Colors from "../../assets/Colors";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

export const InputComp = ({ borderStyles, leftIcon, rightIcon, ...props }) => {
  const { xlfontsize, xsfontsize, smFontSize, isdesktop } =
    useDesktopOrLaptop();
  const styles = useMemo(() => {
    return {
      input: {
        fontFamily: "Poppins",
        fontSize: "14px",
        border: "0px",
        background: "white",
        padding: "3px 10px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: "100%",
        fontWeight: "400",
      },
      inputRightIcon: {
        fontFamily: "Poppins",
        fontSize: "14px",
        outlineWidth: "0px",
        fontWeight: "400",
        background: Colors.detailsBg,
        color: Colors.textColor,
        textAlign: "center",
        minWidth: "45px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRight: "0px",
        borderRadius: "10px 10px 0px 0px",
      },
      inputLeftIcon: {
        fontFamily: "Poppins",
        fontSize: "14px",
        outlineWidth: "0px",
        fontWeight: "400",
        background: Colors.detailsBg,
        color: Colors.textColor,
        textAlign: "center",
        minWidth: "30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderLeft: "0px",
        borderRadius: "10px 0px 0px 10px",
      },
      inputForEdit: {
        width: "100%",
        borderWidth: "1px",
        border: `solid ${Colors.borderPrimary}`,
        borderRadius: "10px",
        display: "flex",
        justifyContent: rightIcon ? "space-between" : undefined,
      },
    };
  }, []);

  return (
    <div style={{ ...styles.inputForEdit, ...borderStyles }}>
      {leftIcon && (
        <div style={styles.inputLeftIcon}>
          <p>{leftIcon}</p>
        </div>
      )}

      <div
        style={{
          margin: "6px 4px",
          width: "90%",
        }}
      >
        <input {...props} style={styles.input} />
      </div>
      {rightIcon && (
        <div style={styles.inputRightIcon}>
          <p>{rightIcon}</p>
        </div>
      )}
    </div>
  );
};
