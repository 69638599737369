import React, { useState } from "react";
import { CgClose } from "react-icons/cg";
import Colors from "../assets/Colors";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import { useAxios, useModal } from "../store";
import { toast } from "react-toastify";
import LoaderComp from "./Reusable/LoaderComp";
import TooltipComp from "./shared/reusable/TooltipComp";
import moment from "moment";
import { mapUndeliverableActions } from "./SeeMoreModal";
import SingleAddressForModal from "./SingleAddressForModal";

function JobsModal({ show, setShow, itemForJobsModal, page }) {
  const { getAcceptOneJob, getDeclineJobHandler } = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const { isdesktop } = useDesktopOrLaptop();
  const { getJobsListHandler } = useAxios();

  const styles = {
    container: {
      width: isdesktop ? "50vw" : "75vw",
      height: "70vh",
      position: "relative",
      overflowY: "scroll",
      padding: "40px 0px 100px 0px",
    },
    circle: {
      position: "absolute",
      height: "14px",
      width: "14px",
      borderRadius: "100%",
      top: "0px",
      left: "44px",
    },
    pickupDropoff: {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: Colors.fontGreyLight,
      fontWeight: "500",
      marginBottom: "10px",
      marginTop: "0px",
    },
    addresses: {
      fontFamily: "Poppins",
      fontSize: "16px",
      color: Colors.fontDark,
      fontWeight: "500",
    },
    button: {
      borderRadius: "8px",
      textAlign: "center",
      padding: "7px 20px",
      fontFamily: "Poppins",
      fontWeight: "600",
      fontSize: "16px",
      color: Colors.white,
      width: "90px",
    },
    singleContainer: {
      position: "relative",
      height: "100px",
      overflow: "hidden",
      padding: "0px 0px 0px 90px",
    },
    lineBetweenCircles: {
      width: "1px",
      height: "100%",
      backgroundColor: Colors.fontDark,
      position: "absolute",
      top: "0px",
      left: "51px",
    },
  };

  const accpetOffer = (item) => {
    setIsLoading(true);
    const callback = (err, res) => {
      setIsLoading(false);
      if (err) {
        toast.error(err);
        return;
      }
      const params = { page };
      getJobsListHandler(params);
      toast.success(`Offer accepted successfully`);
      setShow(false);
    };
    const payload = {
      // pickup_address: item.pickupAddress,
      // pickup_name: item.pickup_name,
      // pickup_notes: item.pickup_notes,
      // pickup_phone_number: item.pickup_phone_number,
      // pickup_unit: item.pickup_unit,
      // items_description: item.items_description,
      // external_order_ref: item.external_order_ref,
      // dropoff_unit: item.dropoff_unit,
      // dropoff_address: item.pickup_address,
      // dropoff_name: item.dropoff_name,
      // dropoff_phone_number: item.dropoff_phone_number,
      // tip: item.tip,
      // order_value: item.order_value,
      // items: item.items,
      // pickup_at: item.pickup_at,
      // dropoff_at: item.dropoffAt,
      delivery_id: item.id,
    };
    getAcceptOneJob(payload, callback);
  };

  const cancelOffer = (id) => {
    const callback = (err, res) => {
      if (err) {
        toast.error(err);
        return;
      }
      setShow(false);
      toast.success(`Offer decline successfully`);
      const params = { page };
      getJobsListHandler(params);
    };
    getDeclineJobHandler(id, callback);
  };

  return (
    <div className=" scrollbar" style={styles.container}>
      <button
        type="button"
        onClick={() => {
          setShow(false);
        }}
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          zIndex: 200,
        }}
      >
        <CgClose size={22} />
      </button>
      {/* <div style={styles.singleContainer}>
        <div style={styles.lineBetweenCircles}></div>
        <div
          style={{
            ...styles.circle,
            ...{ backgroundColor: Colors.yellowDark },
          }}
        ></div>
        <div style={styles.pickupDropoff}>Pickup:</div>
        <div style={styles.addresses}>{itemForJobsModal?.pickupAddress}</div>
      </div>
      <div style={styles.singleContainer}>
        <div
          style={{
            ...styles.circle,
            ...{ backgroundColor: Colors.greenDark },
          }}
        ></div>

        <div style={styles.pickupDropoff}>Dropoff</div>
        <div style={styles.addresses}>{itemForJobsModal?.pickup_address}</div>
      </div> */}

      {itemForJobsModal && (
        <SingleAddressForModal
          index={0}
          styles={styles}
          address={itemForJobsModal?.pickup_address}
          bussinessNmae="Burq"
          name={itemForJobsModal?.pickup_name}
          note={itemForJobsModal?.pickup_notes}
          phoneNumber={itemForJobsModal?.pickup_phone_number}
          // smFontSize={smFontSize}
          // mdFontSize={mdFontSize}
        />
      )}

      {itemForJobsModal && (
        <SingleAddressForModal
          length={1}
          index={1}
          styles={styles}
          address={itemForJobsModal?.pickup_address}
          bussinessNmae="Burq"
          name={itemForJobsModal?.dropoff_name}
          note={itemForJobsModal?.dropoff_notes}
          phoneNumber={itemForJobsModal?.dropoff_phone_number}
        />
      )}

      {/* <div className="flex items-center w-1/2 bg-red-200 ml-0">
        <button id="myButton3213">Hover me</button>
        <TooltipComp targetId="myButton3213" showTooltip={true} position={"top"} >
          This is a tooltip!
        </TooltipComp>
      </div> */}

      <div
        style={{
          marginTop: "10px",
          fontSize: "18px",
          fontWeight: "500",
          padding: "0px 0px 0px 90px",
        }}
      >
        {itemForJobsModal?.contactless_requested
          ? "Proof of delivery requested"
          : itemForJobsModal?.signature_requested && "Signature requested"}
      </div>
      <div
        style={{ padding: "0px 50px", marginTop: "20px" }}
        className="flex w-full items-start justify-start mt-7 overflow-x-auto "
      >
        <div style={{ marginRight: "20px", width: "50%" }}>
          <div
            style={{
              color: Colors.fontGreyLight,
            }}
            className="font-semibold mt-2"
          >
            {/* Contains alcohol:{" "} */}
            <span style={{ color: Colors.fontDark }}>
              {itemForJobsModal?.contains_alcohol
                ? "Contains Alcohol"
                : "Doesn't contain Alcohol"}
            </span>
          </div>
          <div>
            <div
              style={{
                color: Colors.fontGreyLight,
              }}
              className="font-semibold mt-2"
            >
              Delivery Fee:{" "}
              <span style={{ color: Colors.fontDark }}>
                ${(itemForJobsModal?.fee / 100).toFixed(2) || " Not Available"}
              </span>
            </div>
            <div
              style={{
                color: Colors.fontGreyLight,
              }}
              className="font-semibold mt-2"
            >
              Delivery Tip:{" "}
              <span style={{ color: Colors.fontDark }}>
                ${(itemForJobsModal?.tip / 100).toFixed(2) || " Not Available"}
              </span>
            </div>

            <div
              style={{
                color: Colors.fontGreyLight,
              }}
              className="font-semibold mt-2"
            >
              Scheduled Time{" "}
              {itemForJobsModal?.dropoff_at
                ? "Dropoff"
                : itemForJobsModal?.pickup_at
                ? "Pickup"
                : ""}
              :{" "}
              <span style={{ color: Colors.fontDark }}>
                {itemForJobsModal?.pickup_at &&
                  (moment(itemForJobsModal.pickup_at).format(
                    "D MMM YYYY, hh:mmA"
                  ) === "Invalid date"
                    ? ""
                    : moment(itemForJobsModal.pickup_at).format(
                        "D MMM YYYY, hh:mmA"
                      ))}
                {itemForJobsModal?.dropoff_at &&
                  (moment(itemForJobsModal.dropoff_at).format(
                    "D MMM YYYY, hh:mmA"
                  ) === "Invalid date"
                    ? ""
                    : moment(itemForJobsModal.dropoff_at).format(
                        "D MMM YYYY, hh:mmA"
                      ))}
              </span>
            </div>

            <div
              style={{
                color: Colors.fontGreyLight,
              }}
              className="font-semibold mt-2"
            >
              External Order Id:{" "}
              <span style={{ color: Colors.fontDark }}>
                {itemForJobsModal?.external_order_ref || " Not Available"}
              </span>
            </div>
            <div
              style={{
                color: Colors.fontGreyLight,
              }}
              className="font-semibold mt-2"
            >
              Provider Id:{" "}
              <span style={{ color: Colors.fontDark }}>
                {itemForJobsModal?.short_id || " Not Available"}
              </span>
            </div>
            {itemForJobsModal?.reroute_child_delivery_id && (
              <div
                style={{
                  color: Colors.fontGreyLight,
                }}
                className="font-semibold mt-2"
              >
                Delivery rerouted. New ID:
                <span style={{ color: Colors.fontDark, marginLeft: "5px" }}>
                  {itemForJobsModal?.reroute_child_delivery_id}
                </span>
              </div>
            )}
            {itemForJobsModal?.reroute_parent_delivery_id && (
              <div
                style={{
                  color: Colors.fontGreyLight,
                }}
                className="font-semibold mt-2"
              >
                This is rerouted Delivery from Delivery ID:
                <span style={{ color: Colors.fontDark, marginLeft: "5px" }}>
                  {itemForJobsModal?.reroute_parent_delivery_id}
                </span>
              </div>
            )}
          </div>
        </div>
        <div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
              Order Value:
            </div>
            <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
              {itemForJobsModal?.order_value == 0
                ? "$ 0"
                : itemForJobsModal?.order_value
                ? "$" + itemForJobsModal?.order_value / 100
                : " Not Available"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
              Driver Vehicle:
            </div>
            <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
              {itemForJobsModal?.vehicle ? itemForJobsModal.vehicle : "-"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
              Vehicle Preference:
            </div>
            <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
              {itemForJobsModal?.vehicle_type
                ? itemForJobsModal.vehicle_type
                : "-"}
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
              Delivery type:
            </div>
            <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
              {itemForJobsModal?.pickup_at ? "Scheduled" : "ASAP"}
            </div>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
              Proof:
            </div>
            <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
              {itemForJobsModal?.signature_requested
                ? "Signature"
                : itemForJobsModal?.contactless_requested
                ? "Contactless"
                : "No Preference"}
            </div>
          </div>

          {itemForJobsModal?.cancellation_reason && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "start",
                alignItems: "start",
                // height: "100%",
              }}
            >
              <div
                style={{
                  fontFamily: "Poppins",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "18px",
                  color: Colors.fontDark,
                  width: "100%",
                  backgroundColor: Colors.whiteBlue,
                  height: "31px",
                  alignItems: "center",
                  display: "flex",
                  borderRadius: "5px 5px 0px 0px",
                  paddingLeft: "12px",
                }}
              >
                Cancellation Reason
              </div>
              <div
                className="kata"
                style={{
                  width: "100%",
                  backgroundColor: Colors.whitestBlue,
                  padding: isdesktop ? "30px" : "50px",
                  maxHeight: "530px",
                  overflowY: "scroll",
                }}
              >
                {itemForJobsModal.cancellation_reason || "-"}
              </div>
            </div>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <div style={{ color: Colors.fontDark, fontWeight: "bold" }}>
              Undeliverable Action:
            </div>
            <div style={{ marginLeft: "10px", color: Colors.fontDark }}>
              {itemForJobsModal?.undeliverable_action_requested
                ? mapUndeliverableActions[
                    itemForJobsModal.undeliverable_action_requested
                  ]
                : "No Preference"}
            </div>
          </div>
        </div>
      </div>
      {/* {itemForJobsModal?.dropoffs?.map((item, index) => {
        return (
          <div key={index} style={styles.singleContainer}>
            {index + 1 !== itemForJobsModal?.dropoffs.length && (
              <div style={styles.lineBetweenCircles}></div>
            )}

            <div
              style={{
                ...styles.circle,
                ...{ backgroundColor: Colors.greenDark },
              }}
            ></div>

            <div style={styles.pickupDropoff}>Stop {index + 1}</div>
            <div style={styles.addresses}>{item.dropoff_address}</div>
          </div>
        );
      })} */}

      <div
        style={{
          width: "100%",
          display: "flex",
          position: "fixed",
          padding: "30px",
          bottom: 0,
          left: 0,
          justifyContent: "flex-end",
          alignItems: "center",
          backgroundColor: "white",
        }}
      >
        <button
          id="acceptJobId"
          type="button"
          onClick={async () => accpetOffer(itemForJobsModal)}
          style={{
            ...styles.button,
            ...{ backgroundColor: Colors.bluePrimary, marginRight: "10px" },
          }}
        >
          {isLoading ? (
            <LoaderComp loading={isLoading} color="#FFFFF" />
          ) : (
            "Accept"
          )}
        </button>
        <button
          type="button"
          onClick={async () => {
            cancelOffer(itemForJobsModal.id);
          }}
          style={{
            ...styles.button,
            ...{
              backgroundColor: Colors.buttonBg,
              color: Colors.fontDark,
              marginLeft: "10px",
            },
          }}
        >
          Decline
        </button>
      </div>
    </div>
  );
}

export default JobsModal;
