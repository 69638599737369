import Colors from "../../assets/Colors";
import job from "../../assets/pngs/Work.png";
import ongoing from "../../assets/pngs/ongoing.png";
import completed from "../../assets/pngs/completed.png";
import canceled from "../../assets/pngs/canceled.png";

export const backgroundColorMap = {
  jobs: Colors.blueLight,
  ongoing: Colors.yellowDark,
  completed: Colors.greenDark,
  canceled: Colors.redDark,
};

export const imageSrcMap = {
  jobs: job,
  ongoing: ongoing,
  completed: completed,
  canceled: canceled,
};
