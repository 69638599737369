import React, { useEffect } from "react";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";

import IntegrationsCard from "./IntegrationsCard";
import { useModal } from "../../../store";

import { useAxios } from "../../../store";
import { PROVIDERS_FOR_INTEGRATION } from "../utils";
import IntegrationModal from "./IntegrationModal";

const Integrations = () => {
  const { xlfontsize } = useDesktopOrLaptop();
  const { getIntegationList } = useAxios();
  const { modalItem } = useModal();

  useEffect(() => {
    getIntegationList();
  }, []);

  return (
    <div style={{ padding: "20px 16px 0px 16px " }} className="w-full">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            fontSize: xlfontsize,
            paddingLeft: "14px",
          }}
        >
          Integrations
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginTop: "10px",
        }}
      >
        {Object.values(PROVIDERS_FOR_INTEGRATION).map((provider, index) => (
          <IntegrationsCard key={index} providerName={provider} />
        ))}
        {modalItem && <IntegrationModal />}
      </div>
    </div>
  );
};

export default Integrations;
