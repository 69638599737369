import React, { useEffect, useState } from "react";
import { CgClose } from "react-icons/cg";
import Colors from "../assets/Colors";
import { useAxios } from "../store";
import dayjs from "dayjs";
import { useDesktopOrLaptop } from "../services/useDesktopOrLaptop";
import SelectComp from "./Reusable/SelectComp";
import CheckBoxComp from "./Reusable/CheckBoxComp";

const initDriver = {
  driverName: "",
  driverPhone: "",
  vehicleType: "",
  pickupDateEta: "",
  pickupTimeETA: "",
  dropoffDateEta: "",
  dropoffTimeETA: "",
};

const optionsList = [
  {
    driverName: "Aqib Ijaz",
    value: "aqib",
    label: "Aqib Ijaz",
    driverPhone: "080133333",
    vehicleType: "Van",
  },
  {
    driverName: "Zahid",
    value: "Zahid",
    label: "Zahid",
    driverPhone: "0801555533",
    vehicleType: "Van",
  },
  {
    driverName: "Lenvani",
    value: "Lenvani",
    label: "Lenvani",
    driverPhone: "080133666633",
    vehicleType: "Van",
  },
  {
    driverName: "Edward",
    value: "Edward",
    label: "Edward",
    driverPhone: "0801336666",
    vehicleType: "Van",
  },
];

function StartDeliveryModal({ show, setShow }) {
  const { getStartDeliveryHandler, itemForStartDeliveryModal } = useAxios();
  const { smFontSize, mdFontSize, isdesktop } = useDesktopOrLaptop();
  const styles = {
    title: {
      fontFamily: "Poppins",
      fontSize: mdFontSize,
      color: Colors.fontDark,
      fontWeight: "500",
    },
    button: {
      borderRadius: "8px",
      textAlign: "center",
      padding: "7px 20px",
      fontFamily: "Poppins",
      fontWeight: "600",
      fontSize: mdFontSize,
      color: Colors.white,
    },
    input: {
      fontFamily: "Poppins",
      fontSize: smFontSize,
      border: "0px",
      padding: "3px 10px",
      borderRadius: "8px",
      outlineWidth: "0px",
      width: "100%",
      fontWeight: "500",
    },
    inputLable: {
      fontFamily: "Poppins",
      fontWeight: "500",
      color: Colors.fontGreyLight,
      fontSize: mdFontSize,
      marginTop: "30px",
      marginBottom: "10px",
    },
    inputForEdit: {
      width: "100%",
      borderWidth: "1px",
      border: `solid ${Colors.borderPrimary}`,
      padding: "6px 4px",
      borderRadius: "10px",
      display: "flex",
    },
    bottomContainer: {
      width: "100%",
      display: "flex",
      position: "fixed",
      padding: "30px",
      bottom: 0,
      left: 0,
      justifyContent: "flex-end",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "8px",
    },
  };

  const [driverData, setDriverData] = useState(initDriver);
  const [saveDriver, setSaveDriver] = useState(false);
  const [clear, setClear] = useState(false);

  const onInputChange = (e) =>
    setDriverData({ ...driverData, [e.target.name]: e.target.value });
  const onSelect = (payload) => setDriverData(payload ? payload : initDriver);
  const onChecked = (payload) => setSaveDriver(payload.value);

  useEffect(() => {
    if (!show) {
      setDriverData(initDriver);
    }
  }, [show]);

  const onStartDelivery = async () => {
    // console.log("driverData =>", driverData);
    // console.log("saveDriver =>", saveDriver);
    // console.log(
    //   "driverData?.dropoffDateEta + driverData?.dropoffTimeETA",
    //   driverData?.dropoffDateEta + driverData?.dropoffTimeETA
    // );
    // return;
    const dropoffEta =
      driverData?.dropoffDateEta &&
      driverData?.dropoffTimeETA &&
      dayjs(driverData?.dropoffDateEta + driverData?.dropoffTimeETA).format();
    const pickupEta =
      driverData?.pickupDateEta &&
      driverData?.pickupTimeETA &&
      dayjs(driverData?.pickupDateEta + driverData?.pickupTimeETA).format();

    const params = {
      status: "driver_assigned",
      driverName: driverData?.driverName ?? "",
      driverPhone: driverData?.driverPhone ?? "",
      vehicleType: driverData?.vehicleType ?? "",
      pickupEta: pickupEta ?? "",
      dropoffEta: dropoffEta ?? "",
    };

    const response = await getStartDeliveryHandler(
      itemForStartDeliveryModal?.id,
      params
    );
    if (response) {
      setShow(false);
    }
  };

  return (
    <div
      // className="kata"
      style={{
        width: isdesktop ? "30vw" : "65vw",
        height: "80vh",
        position: "relative",
        padding: "30px",
        overflowY: "scroll",
        paddingBottom: "200px",

        // maxHeight: "40%",
      }}
    >
      <button
        type="button"
        onClick={() => {
          setShow(false);
        }}
        style={{
          position: "fixed",
          top: "20px",
          right: "20px",
          zIndex: 200,
          backgroundColor: "white",
        }}
      >
        <CgClose size={22} />
      </button>
      <div style={styles.title}>Driver Information</div>
      {/* <div style={styles.inputLable}>Choose Driver from existing list</div>
      <SelectComp
        optionsList={optionsList}
        onSelect={onSelect}
        resetSelection={!show}
      /> */}

      <div style={styles.inputLable}>Name</div>
      <div style={styles.inputForEdit}>
        <input
          onChange={onInputChange}
          name="driverName"
          value={driverData.driverName}
          placeholder="Type here"
          type="text"
          style={styles.input}
        />
      </div>

      <div style={styles.inputLable}>Phone Number</div>
      <div style={styles.inputForEdit}>
        <input
          onChange={onInputChange}
          name="driverPhone"
          value={driverData.driverPhone}
          placeholder="Type here"
          type="text"
          style={styles.input}
        />
      </div>
      <div style={styles.inputLable}>Vehicle</div>
      <div style={styles.inputForEdit}>
        <input
          onChange={onInputChange}
          name="vehicleType"
          value={driverData.vehicleType}
          placeholder="Type here"
          type="text"
          style={styles.input}
        />
      </div>
      {/* <div style={styles.inputLable}>Pickup ETA</div>
      <div style={styles.inputForEdit}>
        <input
          onChange={onInputChange}
          name="pickupDateEta"
          value={driverData.pickupDateEta}
          placeholder="Type here"
          type="date"
          style={styles.input}
        />
        <input
          onChange={onInputChange}
          placeholder="Type here"
          name="pickupTimeETA"
          value={driverData.pickupTimeETA}
          type="time"
          style={styles.input}
        />
      </div>
      <div style={styles.inputLable}>Dropoff ETA</div>
      <div style={styles.inputForEdit}>
        <input
          onChange={onInputChange}
          name="dropoffDateEta"
          value={driverData.dropoffDateEta}
          placeholder="Type here"
          type="date"
          style={styles.input}
        />
        <input
          onChange={onInputChange}
          name="dropoffTimeETA"
          value={driverData.dropoffTimeETA}
          placeholder="Type here"
          type="time"
          style={styles.input}
        />
      </div> */}
      {/* <CheckBoxComp
        name="saveDrive"
        margin="10px 0px"
        label="Save driver information"
        onChecked={onChecked}
      /> */}
      <div style={styles.bottomContainer}>
        <button
          onClick={onStartDelivery}
          type="button"
          style={{
            ...styles.button,
            ...{ backgroundColor: Colors.bluePrimary, marginRight: "10px" },
          }}
        >
          Start Delivery
        </button>
        <button
          type="button"
          onClick={() => {
            setShow(false);
          }}
          style={{
            ...styles.button,
            ...{
              backgroundColor: Colors.buttonBg,
              color: Colors.fontDark,
              marginLeft: "10px",
            },
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}

export default StartDeliveryModal;
