import React from "react";

import styles from "./styles.module.css";
import ImagesUpload from "./ImagesUpload";

const UploadProofs = ({
  images,
  setImages,
  errorMessage,
  setErrorMessage,
  title,
  helperText,
  inputId,
  max,
}) => {
  return (
    <div>
      <h1 className={styles.uploadTitle}>
        {title} <span className={styles.maxText}>({helperText})</span>{" "}
        {errorMessage && (
          <span className={styles.errorMessage}>{errorMessage}</span>
        )}
      </h1>
      <ImagesUpload
        max={max}
        inputId={inputId}
        setErrorMessage={setErrorMessage}
        errorMessage={errorMessage}
        images={images}
        setImages={setImages}
      />
    </div>
  );
};

export default UploadProofs;
