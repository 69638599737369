import axios from "axios";
import { putUpdateDelivery } from "../../utils/api/deliveries";
import { toast } from "react-toastify";

export const handleUpload = async (
  images,
  getPresignedUrl,
  merchantId,
  uploadToS3
) => {
  // setUploading(true);
  const uploadPromises = [];
  const s3_file_names = [];

  for (const image of images) {
    const fileName = image.name;
    const presignedUrlResource = await getPresignedUrl(merchantId, fileName);

    s3_file_names.push(presignedUrlResource?.s3_file_name);

    const uploadPromise = uploadToS3(image, presignedUrlResource.presigned_url)
      .then((data) => {
        console.log(`${fileName} uploaded successfully.`);
      })
      .catch((error) => {
        console.error(`Error uploading ${fileName}:`, error);
        return;
      });

    uploadPromises.push(uploadPromise);
  }

  await Promise.all(uploadPromises);
  return s3_file_names;
  // setUploading(false);
  // setProgress({});
  // setImages([]);
};

export const updateDeliveryApiHandler = async (id, payload) => {
  try {
    const data = await putUpdateDelivery(id, payload);
    return data;
    toast.success("Delivery updated successfully");
  } catch (error) {
    // toast.success("Delivery updated successfully");
    // toast.error("Update failed");
    toast.error(error?.response?.data?.message);
  }
};
