import { apiUrl } from "../../runtimeConsts";

const deliveries = {
  PARTNER_DELIVERIES: `${apiUrl}partner/partner_deliveries`,
  DELIVERY_STATUSCHANGE: (id) => `${apiUrl}partner/statusChange/${id}`,
};

const connectAccounts = {
  CREATE_CONNECT_ACCOUNT: `${apiUrl}partner/stripe/create_stripe_account`,
  CONNECT_ACCOUNT_BALANCE: `${apiUrl}partner/stripe/connected_account_balance`,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ...deliveries,
  ...connectAccounts,
};
