import React, { useMemo } from "react";
import Colors from "../../assets/Colors";

const InputErrorComp = ({ error, styleProps }) => {
  const styles = useMemo(() => {
    return {
      errorMessage: {
        fontFamily: "Poppins",
        fontSize: "11px",
        fontWeight: "400",
        color: Colors.redDark,
        ...styleProps
      },
    };
  }, [styleProps]);
  return error ? <span style={styles.errorMessage}>{error}</span> : "";
};

export default InputErrorComp;
