import React from "react";
import Colors from "../../assets/Colors";
import { useDesktopOrLaptop } from "../../services/useDesktopOrLaptop";

function ButtonComp({ title, onClick, primary, extraStyles, secondary, ...props }) {
  const { mdFontSize } = useDesktopOrLaptop();

  return (
    <button
      onClick={onClick}
      className="trans"
      type="button"
      style={{
        ...{
          backgroundColor: primary
            ? Colors.bluePrimary
            : secondary
            ? Colors.fontGreyLight
            : Colors.white,
          fontFamily: "Poppins",
          fontSize: mdFontSize,
          fontWeight: "500",
          color: "#FAFAFB",
          textAlign: "center",
          borderRadius: "8px",
          padding: "10px 22px",
        },
        ...extraStyles,
      }}
      {...props}
    >
      {title}
    </button>
  );
}

export default ButtonComp;
