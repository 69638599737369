import * as Yup from "yup";

const phoneRegex =
  /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;

// const zipCodeRegex = /^[0-9]{5}(?:-[0-9]{4})?(,[0-9]{5}(?:-[0-9]{4})?)*?$/;
const zipCodeRegex = /^[0-9]{5}?(,[0-9]{5}?)*?$/;
const business_name_regex = /^\S+$/;

const businessSchema = Yup.object().shape({
  logo: Yup.string(),
  business_name: Yup.string()
    .min(3, "Business Name must be at least 3 characters")
    .max(50, "Business Name must be at most 50 characters")
    // .matches(business_name_regex, "Enter business name without spaces")
    .required("Business Name is required"),
  business_address: Yup.string()
    .min(3, "Business Address must be at least 3 characters")
    .required("Business Address is required"),
  contact_number: Yup.string()
    .test("maxLenght", "Please enter valid phone number", (val) => {
      if (val?.length === 17) {
        return true;
      }
    })
    // .matches(phoneRegex, "regex err")
    .required("Phone Number is required"),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Enter correct url"
    )
    .required("Please enter website"),
  industries: Yup.array().optional(),
  support_ondemand: Yup.boolean(),
  support_scheduled: Yup.boolean(),
  notice_window: Yup.number().when("support_scheduled", (support_scheduled) => {
    if (support_scheduled) {
      return Yup.number().required("Time is required");
    } else {
      return Yup.string().notRequired();
    }
  }),
  alert_window: Yup.number().required("Alert window is required"),

  pickup_eta_wait_time: Yup.number().notRequired(),

  dropoff_eta_wait_time: Yup.number().when(
    "pickup_eta_wait_time",
    (pickup_eta_wait_time) => {
      if (pickup_eta_wait_time > 0) {
        return Yup.number()
          .required("Time is required")
          .min(
            pickup_eta_wait_time,
            "Dropoff ETA must greater then or equal to Pickup ETA"
          );
      } else {
        return Yup.string().notRequired();
      }
    }
  ),
  working_hours_enabled: Yup.boolean().notRequired(),
  operation_times: Yup.array().when("working_hours_enabled", {
    is: true, // Only apply the following rules if working_hours_enabled is true
    then: Yup.array()
      .of(
        Yup.object().shape({
          week_day: Yup.string()
            .required("Day of the week is required")
            .notOneOf([null, ""], "Day of the week cannot be empty"),
          start_time: Yup.string()
            .required("Start time is required")
            .notOneOf([null, ""], "Start time cannot be empty"),
          end_time: Yup.string()
            .required("End time is required")
            .notOneOf([null, ""], "End time cannot be empty"),
        })
      )
      .required("Working hours are required when working hours are enabled")
      .test(
        "empty-check",
        "One or more fields in working hours are empty, undefined, or null",
        (operation_times) => {
          if (!operation_times) return true; // Pass validation if operation_times is not defined

          return operation_times.every((work_hour) => {
            return Object.keys(work_hour).every((key) => {
              const value = work_hour[key];
              return value !== undefined && value !== null && value !== "";
            });
          });
        }
      ),
    otherwise: Yup.array().notRequired(), // If working_hours_enabled is false, operation_times is not required
  }),
  timezone: Yup.string().required("Please provide the timezone"),
  notification_email: Yup.string().email("Email must be valid").optional(),
  // notification_email: Yup.string().email("Email must be valid").required("Please provide the notification Email"),
});

const zoneSchema = Yup.object().shape(
  {
    zone_name: Yup.string()
      .min(3, "Zone Name must be at least 3 characters")
      .max(20, "Zone Name must be at most 20 characters")
      .required("Zone Name is required"),
    zip_code: Yup.string()
      .matches(zipCodeRegex, "Enter correct Zip Code!")
      .required("Zip Code is required"),
    rate_name: Yup.string().label("Rate name").min(3).max(20).required(),

    rate_description: Yup.string().label("Rate description").min(3),
    limit_min_distance: Yup.number().label("Limit min distance"),
    limit_max_distance: Yup.number().label("Limit max distance"),
    price_base_delivery: Yup.number()
      .min(0, "Price base delivery must be 0 or greater")
      .label("Price base delivery")
      .when("price_base_mile", {
        is: (v) => !!v,
        then: Yup.number()
          .required("Price base delivery is required")
          .min(0, "Price base delivery must be 0 or greater"),
      }),
    price_base_mile: Yup.number()
      .min(0, "Price base mile must be 0 or greater")
      .label("Price base mile")
      .when("price_base_delivery", {
        is: (v) => !!v,
        then: Yup.number()
          .required("Price base mile is required")
          .min(0, "Price base mile must be 0 or greater"),
      }),
    price_per_mile: Yup.number().label("Price per mile").required(),
  },
  [["price_base_delivery", "price_base_mile"]]
);

const cartweelIntegrationSchema = Yup.object().shape({
  // password: Yup.string().required("Password is required"),
  // username: Yup.string()
  //   .min(3, "Username must be at least 3 characters")
  //   .max(20, "Username must be at most 20 characters")
  //   .required("Username is required"),
  password: Yup.string().optional(),
  username: Yup.string()
    .min(3, "Username must be at least 3 characters")
    .max(20, "Username must be at most 20 characters")
    .optional(),
  api_url: Yup.string().url("Field must be a valid url").optional(),
  provider_api_key: Yup.string().required("API Key is required"),
});

const vromoIntegrationSchema = Yup.object().shape({
  provider_api_key: Yup.string().required("API Key is required"),
  group_id: Yup.string().optional(),
});

export const zoneRulesSchema = Yup.object().shape({
  condition: Yup.array().of(
    Yup.object().shape({
      type: Yup.mixed().oneOf(["expression", "logical_operator"]),
      value: Yup.mixed().when("type", {
        is: (v) => v === "logical_operator",
        then: Yup.mixed()
          .oneOf(["OR", "AND"])
          .required(
            "logical operation is required. Please check the missing logical operation."
          ),
        otherwise: Yup.object().shape({
          left_side_value: Yup.object().shape(
            {
              variable: Yup.string().nullable().required("Field is required"),
              operation: Yup.string().when("field_value", {
                is: (v) => !!v,
                then: Yup.string()
                  .nullable()
                  .required(
                    "If field value is set, operation is also required"
                  ),
                otherwise: Yup.string().nullable().optional(),
              }),
              field_value: Yup.number().when("operation", {
                is: (v) => !!v,
                then: Yup.number()
                  .nullable()
                  .required(
                    "If operation is set, field value is also required"
                  ),
                otherwise: Yup.number().nullable().optional(),
              }),
            },
            [["operation", "field_value"]]
          ),
          right_side_value: Yup.object().shape(
            {
              variable: Yup.string().when("operation", {
                is: (v) => !!v,
                then: Yup.string()
                  .nullable()
                  .required("If operation is set, variable is also required."),
                otherwise: Yup.string().nullable().optional(),
              }), // optional - name of variable if used in case of calculations to set final value.
              operation: Yup.string().when("variable", {
                is: (v) => !!v,
                then: Yup.string()
                  .nullable()
                  .required("If variable is set, operation is also required"),
                otherwise: Yup.string().nullable().optional(),
              }), //enum// optional - multiplication/division/subtraction/addition. If provided will perform this operation with variable and value.
              field_value: Yup.number()
                .nullable()
                .required("Value is required."),
            },
            [["variable", "operation"]]
          ),
          equality_operator: Yup.string()
            .nullable()
            .required("Operator is required."),
        }),
      }),
    })
  ),
  result: Yup.object().shape({
    field: Yup.string().nullable().required("Field is required."),
    value: Yup.object().shape(
      {
        variable: Yup.string().when("operation", {
          is: (v) => !!v,
          then: Yup.string()
            .nullable()
            .required("If operation is set, variable is also required."),
          otherwise: Yup.string().nullable().optional(),
        }), // optional - name of variable if used in case of calculations to set final value.
        operation: Yup.string().when("variable", {
          is: (v) => !!v,
          then: Yup.string()
            .nullable()
            .required("If variable is set, operation is also required"),
          otherwise: Yup.string().nullable().optional(),
        }), //enum// optional - multiplication/division/subtraction/addition. If provided will perform this operation with variable and value.
        field_value: Yup.number().nullable().required("Value is required."),
      },
      [["operation", "variable"]]
    ),
  }),
});

export {
  businessSchema,
  zoneSchema,
  cartweelIntegrationSchema,
  vromoIntegrationSchema,
};
