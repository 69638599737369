import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "aniuta";
// import { Elements } from "@stripe/react-stripe-js";
import { ToastContainer } from "react-toastify";
import "./index.css";
import MainScreen from "./screens/MainScreen";
import SettingsScreen from "./screens/SettingsScreen";
import { useDesktopOrLaptop } from "./services/useDesktopOrLaptop";
import 'react-toastify/dist/ReactToastify.css';

require("dotenv").config();

// const stripePromise = loadStripe(
//   "pk_live_51I8W8bEKLZBzhMmoSWlp2rW8SW8Br33FVvrXCN5yZg5kvIoDK8SJYDEYxJ7Nca7m8i1hirLVvx7wm8dlGS9qgNef00syOZvJTG"
//   // "pk_test_51I8W8bEKLZBzhMmosjMVqXVZZNpB6zHWGsyAZTZB3vLDzC8Naw1zcCBY6zCx3IWnMtKhFa1g5IzJ4rYR1vyVyrkQ00TYIQuFxB"
// );

const MainApp = () => {
  const { isdesktop } = useDesktopOrLaptop();
  return (
    <div>
      <ToastContainer
        style={{
          fontSize: isdesktop ? "14px" : "22px",
          width: isdesktop ? 400 : 600,
          textAlign: "center",
        }}
      />
      <Router basename={process.env.PUBLIC_URL}>
        <Switch>
          <Route exact path="/">
            <MainScreen />
          </Route>
          <Route exact path="/settings">
            <SettingsScreen />
          </Route>
        </Switch>
      </Router>
    </div>
  );
};

function App() {
  return (
    <div>
      <Provider>
        <MainApp />
      </Provider>
    </div>
  );
}

export default App;
