import React, { useEffect, useMemo, useState } from "react";
import Colors from "../../../assets/Colors";
import { SearchSvg } from "../../../assets/svgs/AllSvgs";
import { useDesktopOrLaptop } from "../../../services/useDesktopOrLaptop";
import { useConfiguration } from "../../../store";
import ButtonComp from "../../Reusable/ButtonComp";
import AddEditZone from "./AddZone/AddEditZone";
import { useAxios } from "../../../store";

const Configurations = () => {
  const { xlfontsize, smFontSize, lgfontsize } = useDesktopOrLaptop();
  const [activePage, setActivePage] = useState("main");
  const { isEdit, setIsEdit, active, setActive } = useConfiguration();
  const [search, setSearch] = useState("");
  const [searchedZoneList, setSearchedZoneList] = useState([]);
  const [selectedZone, setSelectedZone] = useState();
  const { zoneList, getZoneList } = useAxios();

  useEffect(() => {
    getZoneList();
  }, []);

  useEffect(() => {
    setSearchedZoneList(zoneList);
    setActive("main");
  }, [zoneList, activePage]);

  const styles = useMemo(() => {
    return {
      input: {
        fontFamily: "Poppins",
        fontSize: "14px",
        border: "0px",
        padding: "3px 10px",
        borderRadius: "8px",
        outlineWidth: "0px",
        width: "100%",
        fontWeight: "400",
      },
      inputForEdit: {
        width: "100%",
        borderWidth: "1px",
        border: `solid ${Colors.borderPrimary}`,
        padding: "6px 12px",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
      },
      zoneButtons: {
        padding: "4px 12px",
        cursor: "pointer",
        marginTop: "10px",
        borderRadius: "6px",
      },
    };
  }, []);

  const onSearch = (e) => {
    e.preventDefault();

    if (!search.trim()) {
      setSearchedZoneList(zoneList);
    }

    const filteredZone = zoneList.filter((zone) =>
      zone.zone_name.toLocaleLowerCase().startsWith(search.toLocaleLowerCase())
    );
    setSearchedZoneList(filteredZone);
  };

  const onZoneSelection = (zone) => {
    setActivePage("addZone");
    setIsEdit(true);
    setSelectedZone(zone);
    setSearch("");
  };

  const renderBreadCrums = () => {
    return (
      <div className="flex items-center">
        <ButtonComp
          title={"Delivery configuration"}
          extraStyles={{
            backgroundColor: "transparent",
            color:
              activePage === "addZone" ? Colors.fontGreyLight : Colors.fontDark,
            fontWeight: "600",
            fontSize: xlfontsize,
            marginBottom: "20px",
            padding: "0px",
          }}
          onClick={() => {
            setActivePage("main");
          }}
        />
        {activePage === "addZone" && (
          <>
            <div
              style={{
                fontSize: xlfontsize,
                color: Colors.fontGreyLight,
              }}
              className="mx-1.5 mb-4 "
            >
              /
            </div>
            <ButtonComp
              title={isEdit ? "Edit Zone" : "Add Zone"}
              extraStyles={{
                backgroundColor: "transparent",
                color:
                  active !== "main" ? Colors.fontGreyLight : Colors.fontDark,
                fontWeight: "600",
                fontSize: xlfontsize,
                marginBottom: "20px",
                padding: "0px",
              }}
              onClick={() => setActive("main")}
            />
            {(active === "distance" ||
              active === "list_rules" ||
              active === "create_rule") && (
              <>
                <div
                  style={{
                    fontSize: xlfontsize,
                    color: Colors.fontGreyLight,
                  }}
                  className="mx-1.5 mb-4 "
                >
                  /
                </div>
                <ButtonComp
                  title={"Zone Distance"}
                  extraStyles={{
                    backgroundColor: "transparent",
                    color:
                      active !== "distance"
                        ? Colors.fontGreyLight
                        : Colors.fontDark,
                    fontWeight: "600",
                    fontSize: xlfontsize,
                    marginBottom: "20px",
                    padding: "0px",
                  }}
                  onClick={() => setActive("distance")}
                />{" "}
              </>
            )}
            {(active === "list_rules" || active === "create_rule") && (
              <>
                <div
                  style={{
                    fontSize: xlfontsize,
                    color: Colors.fontGreyLight,
                  }}
                  className="mx-1.5 mb-4 "
                >
                  /
                </div>
                <ButtonComp
                  title={"Custom pricing rules"}
                  extraStyles={{
                    backgroundColor: "transparent",
                    color:
                      active !== "list_rules"
                        ? Colors.fontGreyLight
                        : Colors.fontDark,
                    fontWeight: "600",
                    fontSize: xlfontsize,
                    marginBottom: "20px",
                    padding: "0px",
                  }}
                  onClick={() => setActive("list_rules")}
                />{" "}
              </>
            )}
            {active === "create_rule" && (
              <>
                <div
                  style={{
                    fontSize: xlfontsize,
                    color: Colors.fontGreyLight,
                  }}
                  className="mx-1.5 mb-4 "
                >
                  /
                </div>
                <ButtonComp
                  title={"Create or update rule"}
                  extraStyles={{
                    backgroundColor: "transparent",
                    color:
                      active !== "create_rule"
                        ? Colors.fontGreyLight
                        : Colors.fontDark,
                    fontWeight: "600",
                    fontSize: xlfontsize,
                    marginBottom: "20px",
                    padding: "0px",
                  }}
                  onClick={() => setActive("create_rule")}
                />{" "}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  return (
    <div style={{ padding: "20px 16px 0px 16px" }} className="w-full">
      {renderBreadCrums()}
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: Colors.white,
          borderRadius: "14px",
          padding: "30px 24px",
        }}
      >
        {activePage === "main" ? (
          <>
            <div
              style={{
                fontWeight: "500",
                fontSize: lgfontsize,
                marginBottom: "20px",
              }}
            >
              Zone and Rates
            </div>
            <div className="flex items-center justify-end">
              <ButtonComp
                title={"Add Zone"}
                primary
                onClick={() => {
                  setActivePage("addZone");
                  setIsEdit();
                  setSelectedZone();
                }}
              />
            </div>
            <div
              style={{
                border: `1px solid ${Colors.borderPrimary}`,
                borderRadius: "8px",
                padding: "8px 12px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <form onSubmit={onSearch}>
                <div
                  style={{
                    ...styles.inputForEdit,
                  }}
                >
                  <SearchSvg onClick={onSearch} />
                  <input
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    type="text"
                    style={styles.input}
                  />
                </div>
              </form>
              {searchedZoneList.map((zone, i) => (
                <div
                  key={i}
                  className={`hover:bg-gray-100`}
                  onClick={() => onZoneSelection(zone)}
                  style={styles.zoneButtons}
                >
                  {zone.zone_name}
                </div>
              ))}
            </div>
          </>
        ) : (
          activePage === "addZone" && (
            <AddEditZone setActivePage={setActivePage} zone={selectedZone} />
          )
        )}
      </div>
    </div>
  );
};

export default Configurations;
