export const PARTNER_INDUSTRY_OPTIONS = [
  { label: "Food", value: "food" },
  { label: "Catering", value: "catering" },
  { label: "Grocery", value: "grocery" },
  { label: "Flowers", value: "flowers" },
  { label: "Laundry / Dry Cleaning", value: "laundry_dry_cleaning" },
  { label: "Pharmacy", value: "pharmacy" },
  { label: "Ecommerce / Parcel", value: "ecommerce_parcel" },
  { label: "Auto Parts", value: "auto_parts" },
  { label: "Vape / Tobacco", value: "vape_tobacco" },
  { label: "Alcohol", value: "alcohol" },
  { label: "Cannabis / CBD", value: "cannabis_cbd" },
];

export const PROVIDERS_FOR_INTEGRATION = {
  VROMO: "vromo",
  CARTWHEEL: "cartwheel",
  CAPTAIN_AI: "captain_ai",
  ONFLEET: "onfleet",
};

export const formatedProviderName = {
  vromo: "Vromo",
  cartwheel: "Cartwheel",
  captain_ai: "Captain AI",
  onfleet: "Onfleet",
};

export const PROVIDER_INTEGRATION_INITIAL_PAYLOADS = {
  vromo: {
    provider_name: PROVIDERS_FOR_INTEGRATION.VROMO,
    provider_api_key: "",
    group_id: "",
    provider_enabled: false,
  },
  cartwheel: {
    provider_name: PROVIDERS_FOR_INTEGRATION.CARTWHEEL,
    username: "",
    password: "",
    api_url: "",
    provider_enabled: false,
  },
  onfleet: {
    provider_name: PROVIDERS_FOR_INTEGRATION.ONFLEET,
    provider_api_key: "",
    provider_enabled: false,
    onfleet_origanization_id: undefined,
    // test_mode: true,
  },
};

export const getIntegrationInitialPayload = (name) => {
  return PROVIDER_INTEGRATION_INITIAL_PAYLOADS[name];
};
