import React, { useEffect, useState } from "react";
import Colors from "../assets/Colors";
import Header from "../components/Header";
import PaymentSettings from "../components/PaymentSettings";
import BusinessInfo from "../components/SettingsPage/BusinessInfo/BusinessInfo";
import Configurations from "../components/SettingsPage/Configurations/Configurations";
import Integrations from "../components/SettingsPage/Integrations/Integrations";
import SettingsSidebar from "../components/SettingsPage/SettingsSidebar";
import { useAxios } from "../store";

const SettingsScreen = () => {
  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState(true);
  const [sidepanelactive, setSidepanelactive] = useState("Business Info");
  const { loginAuth, authInfo } = useAxios();
  const tab = new URLSearchParams(window.location.search).get("tab");

  useEffect(() => {
    loginAuth();
  }, []);

  useEffect(() => {
    if (tab === "payment_settings") {
      setSidepanelactive("Payment Settings");
    }
  }, [tab]);

  const renderContent = () => {
    switch (sidepanelactive) {
      case "Business Info":
        return <BusinessInfo />;
      case "Integrations":
        return <Integrations />;
      case "Delivery configuration":
        return <Configurations />;
      case "Payment Settings":
        return <PaymentSettings />;
      default:
        break;
    }
  };

  if (!authInfo) return null;
  return (
    <div
      style={{
        backgroundColor: Colors.bgGrey,
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
      }}
      className="flex flex-row relative"
    >
      <SettingsSidebar
        setSidepanelactive={setSidepanelactive}
        sidepanelactive={sidepanelactive}
        setIsBurgerMenuVisible={setIsBurgerMenuVisible}
        isBurgerMenuVisible={isBurgerMenuVisible}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: `100vh`,
          // height: `calc(100vh - 56px)`,
        }}
      >
        <Header
          setIsBurgerMenuVisible={setIsBurgerMenuVisible}
          isBurgerMenuVisible={isBurgerMenuVisible}
        />

        <div
          style={{
            width: isBurgerMenuVisible
              ? `calc(100vw - 260px)`
              : `calc(100vw - 76px)`,
            overflowY: "scroll",
            height: `calc(100vh - 56px)`,
            paddingBottom: "20px",
          }}
          className="trans"
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default SettingsScreen;
