import React from "react";
import Colors from "../assets/Colors";

function SingleItemForModal({ name, quantity, size }) {
  return (
    <div
      style={{
        border: `solid ${Colors.borderPrimary}`,
        borderWidth: "0px 0px 1px 0px",
        paddingBottom: "20px",
        marginBottom: "20px",
      }}
      className="flex justify-between items-center"
    >
      <div style={{ width: "33.33%" }}>
        <div
          style={{
            ...Styles.categoryText,
            ...{ marginBottom: "10px", color: Colors.fontGreyLight },
          }}
        >
          Item
        </div>
        <div
          style={{
            ...Styles.categoryText,
            ...{ color: Colors.fontDark },
          }}
        >
          {name}
        </div>
      </div>
      <div
        className="flex flex-col justify-center items-center"
        style={{ width: "33.33%" }}
      >
        <div>
          <div
            style={{
              ...Styles.categoryText,
              ...{ marginBottom: "10px", color: Colors.fontGreyLight },
            }}
          >
            Quantity
          </div>
          <div
            style={{
              ...Styles.categoryText,
              ...{ color: Colors.fontDark },
            }}
          >
            {quantity}
          </div>
        </div>
      </div>
      <div
        className="flex flex-col justify-center items-end"
        style={{
          width: "33.33%",
        }}
      >
        <div style={{ width: "70px" }}>
          <div
            style={{
              ...Styles.categoryText,
              ...{ marginBottom: "10px", color: Colors.fontGreyLight },
            }}
          >
            Size
          </div>
          <div
            style={{
              ...Styles.categoryText,
              ...{ color: Colors.fontDark },
            }}
          >
            {size}
          </div>
        </div>
      </div>
    </div>
  );
}
const Styles = {
  categoryText: {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: "500",
  },
};

export default SingleItemForModal;
