import axios from "axios";
import { apiUrl } from "../../runtimeConsts";
import { generateQueryParams } from "./utils";

export const getIncidentPresignedUrl = (id, params) => {
  const urlWithQueryParams = generateQueryParams(
    `${apiUrl}partner/partner_deliveries/${id}/presigned_url`,
    params,
    { headers: { "x-burq-client": "partner-dashboard" } }
  );
  console.log("urlWithQueryParams", urlWithQueryParams);
  return axios.get(urlWithQueryParams);
};
