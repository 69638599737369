import React from "react";

function CommonToggle({
  smsHandler,
  checked,
  style,
  circleStyle,
  translateX,
  id,
  isDisabled=false
}) {
  return (
    <button
      id={id}
      style={{
        ...style,
        ...{
          display: "flex",
          alignItems: "center",
          outline: "none",
          borderRadius: "40px",
        },
      }}
      type="button"
      onClick={() => {
        smsHandler();
      }}
      disabled={isDisabled}
    >
      <div
        style={{
          ...circleStyle,
          transform: checked ? `translateX(${translateX}px)` : "",
          borderRadius: "100%",
        }}
        className={` transition-transform `}
      ></div>
    </button>
  );
}

export default CommonToggle;
