import * as React from "react";
import Colors from "../Colors";

function DashboardSvg(props) {
  const {isDesktopOrLaptop, sidepanelactive, ...rest} = props; 
  return (
    <svg
      width={isDesktopOrLaptop ? 20 : 28}
      height={isDesktopOrLaptop ? 17 : 25}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M15 2H6a1 1 0 010-2h9c2.777 0 5 2.381 5 5.286v6.428C20 14.62 17.777 17 15 17H9a1 1 0 110-2h6c1.64 0 3-1.456 3-3.286V5.286C18 3.456 16.64 2 15 2z"
        fill={
          sidepanelactive === "Home"
            ? Colors.bluePrimary
            : Colors.fontDark
        }
      />
      <path
        d="M1 7h14a1 1 0 100-2H1a1 1 0 000 2zM15 12H4a1 1 0 110-2h11a1 1 0 110 2z"
        fill={
          sidepanelactive === "Home"
            ? Colors.bluePrimary
            : Colors.fontDark
        }
      />
    </svg>
  );
}
function Settings(props) {
  const {isDesktopOrLaptop, sidepanelactive, ...rest} = props; 
  return (
    <svg
      width={isDesktopOrLaptop ? 20 : 28}
      height={isDesktopOrLaptop ? 22 : 30}
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 14.632c2.022 0 3.66-1.626 3.66-3.632 0-2.006-1.638-3.632-3.66-3.632-2.022 0-3.66 1.626-3.66 3.632 0 2.006 1.638 3.632 3.66 3.632zm0-2c-.908 0-1.645-.73-1.645-1.632 0-.901.737-1.632 1.645-1.632s1.645.73 1.645 1.632c0 .901-.737 1.632-1.645 1.632z"
        fill={
          sidepanelactive === "settings"
            ? Colors.bluePrimary
            : Colors.fontDark
        }
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.094 2.34A2.964 2.964 0 0110 0c1.404 0 2.616.976 2.906 2.34l.056.265a8.974 8.974 0 012.888 1.652l.257-.084A2.98 2.98 0 0119.602 5.5a2.93 2.93 0 01-.589 3.666l-.202.18a8.817 8.817 0 01-.002 3.307l.204.18a2.93 2.93 0 01.59 3.667 2.98 2.98 0 01-3.496 1.327l-.258-.084a9.007 9.007 0 01-2.887 1.654l-.056.264A2.964 2.964 0 0110 22a2.964 2.964 0 01-2.906-2.34l-.056-.265a8.973 8.973 0 01-2.888-1.652l-.257.084A2.98 2.98 0 01.398 16.5a2.93 2.93 0 01.589-3.666l.202-.18a8.817 8.817 0 01.001-3.307l-.203-.18A2.93 2.93 0 01.397 5.5a2.98 2.98 0 013.496-1.327l.258.084a9.008 9.008 0 012.887-1.654l.056-.264zM10 2c-.45 0-.84.313-.933.751l-.178.837c-.078.367-.355.66-.72.759A6.993 6.993 0 005.108 6.1a1.014 1.014 0 01-1.022.24l-.82-.266a.957.957 0 00-1.122.426.94.94 0 00.189 1.177l.644.573c.28.25.397.636.301.998a6.834 6.834 0 00-.001 3.504.995.995 0 01-.302 1l-.642.57a.94.94 0 00-.19 1.178.957.957 0 001.123.426l.82-.266c.359-.117.754-.024 1.023.24a6.954 6.954 0 003.06 1.75c.364.1.642.393.72.76l.178.839A.952.952 0 0010 20c.45 0 .84-.313.933-.751l.178-.837c.077-.367.355-.66.72-.759a6.995 6.995 0 003.061-1.753 1.014 1.014 0 011.022-.24l.82.266a.957.957 0 001.122-.426.94.94 0 00-.189-1.177l-.644-.573a.995.995 0 01-.301-.998 6.833 6.833 0 00.001-3.504.995.995 0 01.302-1l.642-.57a.94.94 0 00.19-1.178.957.957 0 00-1.123-.426l-.82.266c-.359.116-.754.024-1.023-.24a6.954 6.954 0 00-3.06-1.75 1.003 1.003 0 01-.72-.76l-.178-.839A.952.952 0 0010 2z"
        fill={
          sidepanelactive === "settings"
            ? Colors.bluePrimary
            : Colors.fontDark
        }
      />
    </svg>
  );
}
function DropDown(props) {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.293 5.333a.5.5 0 01.39.813L8.39 11.512a.5.5 0 01-.78 0L3.317 6.146a.5.5 0 01.39-.813h8.586z"
        fill={Colors.fontGreyLight}
      />
    </svg>
  );
}
function SearchSvg(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.683 2.683a9.16 9.16 0 1012.954 12.954A9.16 9.16 0 002.683 2.683zm1.414 1.414a7.16 7.16 0 1110.126 10.126A7.16 7.16 0 014.097 4.097z"
        fill={Colors.fontGreyLight}
      />
      <path
        d="M20.094 18.282l-4.13-4.129c-.942-.943-2.356.471-1.413 1.414l4.13 4.13c.942.943 2.356-.472 1.413-1.415z"
        fill={Colors.fontGreyLight}
      />
    </svg>
  );
}
const BurgerSvg = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 12H3"
      stroke="#0062FF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 4h-9"
      stroke="#808191"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.301}
      d="M22 20h-9"
      stroke="#808191"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m7 7-5 5 5 5"
      stroke="#0062FF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const BurgerSvgRotated = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 12h19"
      stroke="#0062FF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 20h9"
      stroke="#808191"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      opacity={0.301}
      d="M2 4h9"
      stroke="#808191"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m17 17 5-5-5-5"
      stroke="#0062FF"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function CheckSvg(props) {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 32c8.837 0 16-7.163 16-16S24.837 0 16 0 0 7.163 0 16s7.163 16 16 16z"
        fill={props.color || "#3DD598"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.802 14.648a.667.667 0 00-.938.947l3.884 3.845c.274.272.72.255.973-.037l5.45-6.3a.667.667 0 00-1.008-.873l-4.984 5.761-3.377-3.343z"
        fill="#fff"
      />
    </svg>
  );
}

function Loading(props) {
  return (
    <svg
      height={512}
      viewBox="0 0 497 497"
      width={512}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={98} cy={376} fill={props.color} r={53} />
      <circle cx={439} cy={336} fill={props.color} r={46} />
      <circle cx={397} cy={112} fill={props.color} r={38} />
      <ellipse
        cx={56.245}
        cy={244.754}
        fill={props.color}
        rx={56.245}
        ry={54.874}
      />
      <ellipse
        cx={217.821}
        cy={447.175}
        fill={props.color}
        rx={51.132}
        ry={49.825}
      />
      <ellipse
        cx={349.229}
        cy={427.873}
        fill={props.color}
        rx={48.575}
        ry={47.297}
      />
      <ellipse
        cx={117.092}
        cy={114.794}
        fill={props.color}
        rx={58.801}
        ry={57.397}
      />
      <ellipse
        cx={453.538}
        cy={216.477}
        fill={props.color}
        rx={43.462}
        ry={42.656}
      />
      <circle cx={263} cy={62} fill={props.color} r={62} />
    </svg>
  );
}

export {
  DashboardSvg,
  Settings,
  DropDown,
  SearchSvg,
  BurgerSvg,
  BurgerSvgRotated,
  CheckSvg,
  Loading,
};
