import React, { useState } from "react";
import CreateRule from "./CreateRule";
import RulesList from "./RulesList";

const CustomPricingRule = ({
  values,
  setFieldValue,
  active,
  setActive,
}) => {
  const [selectedRuleIndex, setSelectedRuleIndex] = useState(null);

  const renderStep = () => {
    switch (active) {
      case "create_rule":
        return (
          <CreateRule
            rules={values.rule}
            setFieldValue={setFieldValue}
            selectedRuleIndex={selectedRuleIndex}
            setSelectedRuleIndex={setSelectedRuleIndex}
            setActiveStep={setActive}
          />
        );
      case "list_rules":
        return (
          <RulesList
            rules={values.rule}
            setFieldValue={setFieldValue}
            setActiveStep={setActive}
            setSelectedRuleIndex={setSelectedRuleIndex}
          />
        );
      default:
        return null;
    }
  };
  return <div style={{ marginBottom: "20px" }}>{renderStep()}</div>;
};

export default CustomPricingRule;
